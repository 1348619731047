/** @format */

@font-face {
  font-family: "ProximaNova";
  src: url("../fonts/proximanova/proximanova-regular-webfont.eot");
  src: url("../fonts/proximanova/proximanova-regular-webfont.woff")
      format("woff"),
    url("../fonts/proximanova/proximanova-regular-webfont.ttf")
      format("truetype"),
    url("../fonts/proximanova/proximanova-regular-webfont.svg#proximanovaregular")
      format("svg");
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced ../fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */
}

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("../fonts/proximanova/proximanova-bold-webfont.eot");
  src: url("../fonts/proximanova/proximanova-bold-webfont.woff") format("woff"),
    url("../fonts/proximanova/proximanova-bold-webfont.ttf") format("truetype"),
    url("../fonts/proximanova/proximanova-bold-webfont.svg#proximanovabold")
      format("svg");
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced ../fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */
}
@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("../fonts/proximanova/proximanova-semibold-webfont.eot");
  src: url("../fonts/proximanova/proximanova-semibold-webfont.woff")
      format("woff"),
    url("../fonts/proximanova/proximanova-semibold-webfont.ttf")
      format("truetype"),
    url("../fonts/proximanova/proximanova-semibold-webfont.svg#proximanovasemibold")
      format("svg");
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced ../fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */
}

a:focus {
  outline: none !important;
}

.hhwt-navbar-fluid {
  background-color: #00cccb;
  padding: 9px 0px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
}
.hhwt-navbar-fluid .hhwt-navbar-container {
  padding-right: 0px;
  padding-left: 0px;
}
.hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar {
  display: flex;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li {
  margin-left: 15px;
  margin-right: 15px;
  min-height: 72px;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search {
  max-width: 180px;
  margin-top: 14px;
  height: 40px;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  span,
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  button {
  background-color: #009fa2;
  border: none !important;
  color: #d1f0ef;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  input {
  background-color: #009fa2;
  border: none;
  color: #fff;
  padding: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-family: "ProximaNova";
  font-size: 15px;
  height: 40px;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  input:focus {
  box-shadow: none;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  input::-webkit-input-placeholder {
  color: #d1f0ef;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  input::-moz-placeholder {
  color: #d1f0ef;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  .input-close {
  display: block;
  position: absolute;
  z-index: 999;
  right: 15px;
  top: 9px;
  cursor: pointer;
  opacity: 0;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search.search-bg
  span {
  background-color: #fff !important;
  color: #405050;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search.search-bg
  input {
  color: #405050;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search.search-bg
  input:focus {
  background-color: #fff !important;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search.search-bg
  input::-webkit-input-placeholder {
  color: #fff;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search.search-bg
  input::-moz-placeholder {
  color: #fff;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search.search-bg
  .input-close {
  opacity: 1;
}
.hhwt-navbar-fluid .hhwt-navbar-container .collapse,
.hhwt-navbar-fluid .hhwt-navbar-container .collapsing {
  padding: 0px;
  width: auto;
}
.hhwt-navbar-fluid .hhwt-navbar-container .navbar-brand {
  padding: 15px 15px 15px 0px;
  outline: none;
  margin-top: 5px;
}

.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px 6px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:avtive > .dropdown-menu,
.dropdown-submenu:hover > .dropdown-menu {
  position: relative;
}
.dropdown-submenu:avtive > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.hhwtmainhassubmenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  content: "\f107";
  font-family: "FontAwesome";
  left: -7px;
  position: relative;
}
.hhwtmainhassubmenu.active > a:after {
  font-family: "FontAwesome";
  content: "\f106";
}

.open > .dropdown-menu {
  display: block;
  border-radius: 5px;
  min-width: 350px;
  margin-top: 10px;
}

.hhwt-nav-menu .dropdown-menu > li > a {
  padding: 3px 0px !important;
}

ul.hhwt-nav-menu > li ul.dropdown-menu.menucontainer,
ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer {
  width: 100%;
  border-radius: 8px;
  min-width: 270px;
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  -khtml-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  padding-top: 10px;
}
ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer {
  padding-bottom: 10px;
}
ul.hhwt-nav-menu > li ul.dropdown-menu > li > ul,
ul.hhwt-nav-menu-right > li ul.dropdown-menu > li > ul {
  width: 100%;
}
ul.hhwt-nav-menu > li ul.dropdown-menu > li > ul li,
ul.hhwt-nav-menu-right > li ul.dropdown-menu > li > ul li {
  width: 50%;
  float: left;
}
ul.hhwt-nav-menu
  > li
  ul.dropdown-menu
  > li
  > ul.dropdown-menu:active
  ul.dropdown-menu,
ul.hhwt-nav-menu
  > li
  ul.dropdown-menu
  > li
  > ul.dropdown-menu:hover
  ul.dropdown-menu,
ul.hhwt-nav-menu-right
  > li
  ul.dropdown-menu
  > li
  > ul.dropdown-menu:active
  ul.dropdown-menu,
ul.hhwt-nav-menu-right
  > li
  ul.dropdown-menu
  > li
  > ul.dropdown-menu:hover
  ul.dropdown-menu {
  display: none;
}
ul.hhwt-nav-menu > li ul.submenucontainer.dropdown-menu,
ul.hhwt-nav-menu-right > li ul.submenucontainer.dropdown-menu {
  position: relative;
  margin: 15px 0px;
  border-radius: 0px;
  padding: 0px;
}
ul.hhwt-nav-menu > li .hhwtmainsubmenu.active,
ul.hhwt-nav-menu-right > li .hhwtmainsubmenu.active {
  overflow: hidden;
  width: 100%;
  display: inline-block;
  border-top: 1px solid #d8d8d8;
  padding-top: 14px;
  padding-bottom: 10px;
  margin-top: 12px;
  color: #00ccc0;
}
ul.hhwt-nav-menu > li .hhwtmainsubmenu.active.menu_hk {
  color: #666666;
  margin-top: 0px;
  border-bottom: 1px solid #d8d8d8;
  border-top: 0px;
  vertical-align: middle;
  padding-top: 4px;
}
ul.hhwt-nav-menu > li .hhwtmainsubmenu.active.menu_hk img {
  margin-right: 10px;
}
ul.hhwt-nav-menu > li .hhwtmainsubmenu.active a,
ul.hhwt-nav-menu-right > li .hhwtmainsubmenu.active a {
  color: #00ccc0;
}
ul.hhwt-nav-menu > li .hhwtmainsubmenu.active.menu_hk a {
  color: #666666;
  vertical-align: middle;
}
ul.hhwt-nav-menu .hhwtmainsubmenu,
ul.hhwt-nav-menu-right .hhwtmainsubmenu {
  /* margin: 0px -15px; */
  padding: 0px;
  overflow: hidden;
}
ul.hhwt-nav-menu .hhwtmainsubmenu:not(:last-child),
ul.hhwt-nav-menu-right .hhwtmainsubmenu:not(:last-child) {
  border-bottom: 0px solid #ebebeb;
}
ul.hhwt-nav-menu .hhwtmainsubmenu > a,
ul.hhwt-nav-menu-right .hhwtmainsubmenu > a {
  color: #00cccc;
  line-height: 20px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px 12px 20px !important;
}
ul.hhwt-nav-menu .hhwtmainsubmenu > a:hover,
ul.hhwt-nav-menu-right .hhwtmainsubmenu > a:hover,
ul.hhwt-nav-menu > li .hhwtmainsubmenu.active.menu_hk a:hover {
  background: #f2f2f2 !important;
  font-weight: bold;
  border-radius: 0px !important;
}

ul.hhwt-nav-menu .dropdown-submenu:active,
ul.hhwt-nav-menu .dropdown-submenu:hover,
ul.hhwt-nav-menu-right .dropdown-submenu:active,
ul.hhwt-nav-menu-right .dropdown-submenu:hover {
  /* background: transparent; */
}
ul.hhwt-nav-menu .dropdown-menu > li > a,
ul.hhwt-nav-menu-right .dropdown-menu > li > a {
  outline: none;
}
ul.hhwt-nav-menu .dropdown-menu > li > a:focus,
ul.hhwt-nav-menu .dropdown-menu > li > a:hover,
ul.hhwt-nav-menu-right .dropdown-menu > li > a:focus,
ul.hhwt-nav-menu-right .dropdown-menu > li > a:hover {
  background-color: transparent;
}

.hhwtmainmenu {
  color: #fff;
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 6px;
  padding-bottom: 10px;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  letter-spacing: 0.7px;
}
.hhwtmainmenu a {
  color: #fff;
  outline: none;
}
.hhwtmainmenu.open .dropdown-menu > .active > a {
  background-color: transparent;
  color: #00ccc0;
}
.hhwtmainmenu.open .dropdown-menu > .active > a:hover,
.hhwtmainmenu.open .dropdown-menu > .active > a:focus {
  background-color: transparent;
  color: #00cccc;
}

.nav > .hhwtmainmenu.open a {
  background-color: transparent;
  border-color: none;
}
.nav > .hhwtmainmenu.open a:focus {
  background-color: transparent;
  border-color: none;
}
.nav > .hhwtmainmenu.open:focus,
.nav > .hhwtmainmenu.open:hover {
  background-color: transparent;
  border-color: none;
}
.nav > li.hhwtmainmenu > a:hover,
.nav > li.hhwtmainmenu > a:focus {
  background-color: transparent;
  border-color: none;
}

.hhwtmainsubmenu.active .submenucontainer {
  border: none;
  box-shadow: none;
}
.hhwtmainsubmenu .submenucontainer li a {
  color: #00cccb !important;
  padding: 5px 0px;
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  letter-spacing: 0.7px;
}

.hhwtsecondsubmenu > a:after {
  display: none;
}

.submenucontainer {
  border: none;
  box-shadow: none;
}

.innermenucontainer {
  display: none !important;
}

.searchmenu .search-span.btn {
  border-radius: 0px 20px 20px 0px;
  background-color: #00cccb;
  color: #fff;
  display: inline-block;
  font-size: 15px;
  padding: 11px 10px 10px 10px;
  margin-right: 0px;
  border: 0px;
}
.searchmenu input {
  border-radius: 0px;
  background-color: #fff;
  color: #405050;
  max-width: 214px;
  min-height: 40px;
  box-shadow: none;
  font-size: 14px;
  border: 1px solid #ccc;
  height: 42px;
  border-left: none;
  border-right: none;
}
.searchmenu .form-control:focus {
  box-shadow: none;
  border-color: #ccc;
}

li.searchmenu {
  padding: 16px 0px 10px;
}
li.searchmenu .dropdown-search > span {
  border-radius: 20px 0px 0px 20px;
  background-color: #fff;
  color: #788c8c;
  padding-right: 5px;
}
li.searchmenu .dropdown-search .input-group-addon {
  border-right: none;
}

.hhwt-nav-menu-right {
  position: absolute;
  top: 0;
  right: 0px;
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 0px;
  font-family: "ProximaNova";
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  min-width: 150px;
}
.hhwt-nav-menu-right .hhwtmainmenu {
  padding-bottom: 0px;
  padding-top: 6px;
}
.hhwt-nav-menu-right .user-name.hhwtmainmenu {
  padding-top: 4px;
}
.hhwt-nav-menu-right .hhwtmainmenu-notification span {
  padding: 3px 5px;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
}
.hhwt-nav-menu-right .hhwtmainmenu-notification span.fa-circle {
  color: #f2007b;
  font-size: 9px;
  position: absolute;
  right: 7px;
  top: 0px;
}
.hhwt-nav-menu-right .hhwtmainmenu-notification:hover span {
  color: #098481;
}
.hhwt-nav-menu-right .hhwtmainmenu.hhwt-nav-menu-bag {
  cursor: pointer;
}
.hhwt-nav-menu-right .hhwtmainmenu.hhwt-nav-menu-bag .fa-shopping-cart {
  font-size: 20px;
  padding: 0px 5px;
}
.hhwt-nav-menu-right .hhwtmainmenu.hhwt-logged-nav-menu-bag {
  margin-top: 6px;
}
.hhwt-nav-menu-right img {
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 0;
  width: 41px;
  height: 41px;
}
.hhwt-nav-menu-right .caret {
  margin-top: 16px;
  margin-left: 6px;
}
.hhwt-nav-menu-right li a {
  color: #fff;
  padding-top: 3px;
  outline: none;
  padding: 3px 7px;
}

.hhwt-nav-menu-right li a .fa-shopping-cart {
  font-size: 20px;
  margin-right: 7px;
}
.hhwt-nav-menu-right li.user-name > a {
  padding-top: 0px !important;
  height: auto;
  display: inline-block;
}
.hhwt-nav-menu-right li.user-name > a:hover {
  background: none !important;
}
.hhwt-nav-menu-right .hhwt-nav-menu-li img {
  border-radius: 50%;
  width: 50px;
}
.hhwt-nav-menu-right .hhwt-nav-menu-message img {
  width: 20px;
  height: 20px;
  border-radius: 0;
  margin: 0;
  cursor: pointer;
}

ul.hhwt-nav-menu > li ul.dropdown-menu > li > ul li:nth-child(7) {
  width: 100%;
  float: left;
}

ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer {
  min-width: 220px;
  background-color: #fff;
  margin-top: -1px;
}

.hhwt-menu-icons {
  background-repeat: no-repeat;
  padding: 1px 0px 3px 30px;
}

.hhwtmainsubmenu .hhwt-menu-icons {
  background-position: left center;
  padding: 6px 0px 3px 30px;
}

ul li.hhwtmainmenu > a:hover {
  background: rgba(255, 255, 255, 0.2) !important;
  border-radius: 8px;
}

.user-message-icon {
  background-image: url("../images/menu-icons/message.png");
}

.travel-guide-icon {
  background-image: url("../images/menu-icons/guide.png");
}

.travel-guide-icon_wht {
  background-image: url("../images/ic-ic_explore.svg");
}

.itinerary-icon_wht {
  background-image: url("../images/ic_itinerary.svg");
}

.triplan-guide-icon {
  background-image: url("../images/menu-icons/planner.png");
}

.book-menu-icon {
  background-image: url("../images/menu-icons/book.png");
}

.forum-menu-icon {
  background-image: url("../images/menu-icons/forum_icon.png");
}

.hk-menu-icon {
  background-image: url("../images/menu-icons/hong_kong_icon.png");
}

.travel-guide-icon:hover,
.hhwtmainmenu:hover .travel-guide-icon {
  background-image: url("../images/menu-icons/guide_hover.png");
}

.triplan-guide-icon:hover,
.hhwtmainmenu:hover .triplan-guide-icon {
  background-image: url("../images/menu-icons/planner_hover.png");
}

.book-menu-icon:hover,
.hhwtmainmenu:hover .book-menu-icon {
  background-image: url("../images/menu-icons/book_hover.png");
}

.forum-menu-icon:hover,
.hhwtmainmenu:hover .forum-menu-icon {
  background-image: url("../images/menu-icons/forum_hover_icon.png");
}

.hk-menu-icon:hover,
.hhwtmainmenu:hover .hk-menu-icon {
  background-image: url("../images/menu-icons/hong_kong_hover_icon.png");
}

.food-guide-icon {
  background-image: url("../images/menu-icons/food.png");
}

.food-guide-icon_wht {
  background-image: url("../images/ic_food.svg");
}

.dest-menu-icon {
  background-image: url("../images/menu-icons/destinations.png");
}

.triptype-menu-icon {
  background-image: url("../images/menu-icons/trip_types.png");
}

.iternity-menu-icon {
  background-image: url("../images/menu-icons/itinerary.png");
}

.acomdtn-menu-icon {
  background-image: url("../images/menu-icons/accomentation.png");
}

.others-menu-icon {
  background-image: url("../images/menu-icons/others.png");
}

@media only screen and (max-width: 991px) {
  .hhwt-navbar-fluid .hhwt-navbar-container .navbar-header .navbar-brand {
    padding-left: 0px;
    padding-right: 0px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li {
    margin-left: 8px;
    margin-right: 8px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li
    a {
    font-size: 13px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu-right
    .hhwt-nav-menu-right-li
    a {
    font-size: 13px;
  }
}
@media only screen and (min-width: 991px) {
  .hhwt-navbar-fluid {
    min-height: 72px;
    max-height: 90px;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar .collapse-search {
    display: none;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li.dropdown:hover
    > .dropdown-menu {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .hhwt-mob-menu .navbar-toggle {
    display: block;
    margin-right: -5px;
  }
  .hhwt-mob-menu .navbar-toggle span {
    background: #fff;
  }

  .hhwt-navbar-fluid .hhwt-navbar-container {
    padding: 0px;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar {
    display: block;
    margin-bottom: 0px;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar .hhwt-nav-menu {
    display: flex;
    width: 100%;
    margin: 0px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li {
    text-align: center;
    margin: auto;
    min-height: auto;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li
    p {
    display: none;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li
    a {
    padding-bottom: 8px;
    padding-top: 5px;
    margin-top: 0px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li
    .hhwt-menu-dropdown {
    position: absolute;
    background-color: #fff;
    margin-top: 2px;
    min-width: 300px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li
    .hhwt-menu-dropdown
    li.hhwt-dropdown-li
    .dropdown-search
    input {
    max-width: 165px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li.active {
    display: block;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar .hhwt-mob-menu {
    padding: 7px 16px 0px 16px;
    text-align: center;
    margin-bottom: 0px;
    display: inline-block;
    width: 100%;
    float: left;
    margin-bottom: 20px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-mob-menu
    .hhwt-mob-search {
    float: right;
    margin-top: 7px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-mob-menu
    .hhwt-mob-logo {
    float: left;
    max-width: 76px;
    margin-top: 7px;
  }
  .hhwt-mob-logo img {
    width: 100%;
  }

  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-mob-menu
    .hhwt-mob-col-button {
    margin-right: 0px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-mob-menu
    .hhwt-mob-col-button
    .icon-bar {
    background-color: #fff;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar .navbar-collapse {
    border-top: none;
    box-shadow: none;
    overflow-y: unset;
    padding: 0px;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .collapse,
  .hhwt-navbar-fluid .hhwt-navbar-container .collapsing {
    width: 100%;
  }

  .hhwtmainmenu {
    margin: auto;
  }

  .nav > li.hhwtmainmenu > a {
    padding: 0px;
    display: inline-flex;
  }
  .nav > li.hhwtmainmenu > a .caret {
    margin-top: 8px;
  }
  .nav > li.hhwtmainmenu > a span.user-name {
    display: none;
  }

  ul.hhwt-nav-menu > li > ul.dropdown-menu.menucontainer {
    position: absolute;
    background: #fff;
  }
  ul.hhwt-nav-menu > li:nth-child(2) > ul.dropdown-menu.menucontainer {
    transform: translate(-20%, 0%);
    margin-top: 0px;
  }
  ul.hhwt-nav-menu > li ul.submenucontainer.dropdown-menu {
    margin-top: 10px !important;
  }
  ul.hhwt-nav-menu > li ul.dropdown-menu > li > ul li .dropdown-menu {
    position: absolute;
  }

  .collapse-search p {
    padding: 0px 15px;
    width: 100%;
    height: 34px;
  }
  .collapse-search p span {
    background-color: #009fa2;
    border: none;
    color: #d1f0ef;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .collapse-search p input {
    background-color: #009fa2;
    border: none;
    color: #fff;
    padding: 0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    font-family: "ProximaNova";
    font-size: 15px;
    height: 40px;
  }
  .collapse-search p input:focus {
    box-shadow: none;
  }
  .collapse-search p input::-webkit-input-placeholder {
    color: #d1f0ef;
  }
  .collapse-search p input::-moz-placeholder {
    color: #d1f0ef;
  }
  .collapse-search p .input-close {
    display: none;
    position: absolute;
    z-index: 99;
    right: 15px;
    top: 9px;
  }
  .collapse-search p.search-bg span {
    background-color: #fff;
    color: #405050;
  }
  .collapse-search p.search-bg input {
    color: #405050;
  }
  .collapse-search p.search-bg input:focus {
    background-color: #fff;
  }
  .collapse-search p.search-bg input::-webkit-input-placeholder {
    color: #fff;
  }
  .collapse-search p.search-bg input::-moz-placeholder {
    color: #fff;
  }
  .collapse-search p.search-bg .input-close {
    display: flex;
    cursor: pointer;
  }

  .hhwtmainmenu {
    font-size: 18px !important;
  }

  .hhwt-nav-menu-right {
    padding: 0px;
    position: relative;
  }
  .hhwt-nav-menu-right li.hhwtmainmenu > a {
    width: 100%;
  }
  .hhwt-nav-menu-right li.hhwtmainmenu > a .caret {
    margin-top: 0;
    right: 15px;
    top: 25px;
    position: absolute;
  }
  .hhwt-nav-menu-right img {
    margin-top: -11px;
  }
  .hhwt-nav-menu-right .hhwt-nav-menu-message img {
    margin: 0 10px 0px 0;
  }
  .hhwt-nav-menu-right .hhwtmainmenu-notification span.fa-circle {
    left: 25px;
    top: 11px;
    right: auto;
  }

  ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer {
    position: relative;
    right: 0px;
    left: auto;
    margin-top: 2px;
    background: transparent;
    box-shadow: none;
    float: none;
  }
  ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer li a {
    padding: 10px !important;
    text-align: left;
    color: #fff;
  }

  ul.hhwt-nav-menu-right .hhwtmainsubmenu {
    border-bottom: none;
    padding: 0px;
  }
  ul.hhwt-nav-menu-right .hhwtmainsubmenu:not(:last-child) {
    border-bottom: none;
  }
}
.hhwt-footer-fluid {
  background-color: #e6f2f2;
  padding-bottom: 35px;
  padding-top: 35px;
}
.hhwt-footer-fluid .hhwt-footer-container {
  padding: 0px;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-categories {
  padding: 0px;
}
.hhwt-footer-fluid
  .hhwt-footer-container
  .hhwt-footer-categories
  .hhwt-footer-grid {
  padding: 0px 15px 0px 0px;
}
.hhwt-footer-fluid
  .hhwt-footer-container
  .hhwt-footer-categories
  .hhwt-footer-grid
  .titles {
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  margin-top: 0px;
  color: #333;
  margin-bottom: 20px;
}
.hhwt-footer-fluid
  .hhwt-footer-container
  .hhwt-footer-categories
  .hhwt-footer-grid
  ul {
  list-style-type: none;
  padding-left: 0;
}
.hhwt-footer-fluid
  .hhwt-footer-container
  .hhwt-footer-categories
  .hhwt-footer-grid
  ul
  li {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.hhwt-footer-fluid
  .hhwt-footer-container
  .hhwt-footer-categories
  .hhwt-footer-grid
  ul
  li
  a {
  color: #00cccc;
  font-size: 14px;
  outline: none;
  line-height: 24px;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow {
  padding: 0px;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow h4 {
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  margin-top: 0px;
  color: #333;
  margin-bottom: 5px;
  margin-bottom: 20px;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a {
  padding-top: 0px;
  color: #fff;
  background-color: #00cccb;
  font-size: 19px;
  border-radius: 50%;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 7px;
  float: left;
  border: 1px solid #00cccb;
  transition: all ease 0.3s;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a:hover {
  background-color: #fff;
  border: 1px solid #d8d8d8;
  color: #00cccc;
}
.hhwt-footer-follow .col-lg-4 {
  width: 28.333%;
}
/* .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a .social_fb {
  padding: 11px 15px 10px 15px;
  color: #fff;
  background-color: #00cccb;
  font-size: 19px;
  border-radius: 50%;
  margin-left: -12px;
} */
/* .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a .social {
  padding: 11px 12px 10px 12px;
  color: #fff;
  background-color: #00cccb;
  font-size: 19px;
  border-radius: 50%;
  margin-left: -12px;
} */
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-download {
  padding: 0px;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-download h4 {
  font-family: "ProximaNova-Bold";
  font-size: 20px;
  margin-top: 0px;
  color: #333;
  margin-bottom: 20px;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-download a img {
  margin-top: 0px;
  width: auto;
}
.hhwt-footer-download-btn {
  float: left;
  width: 48%;
}
.hhwt-footer-fluid
  .hhwt-footer-container
  .hhwt-footer-download
  .hhwt-footer-download-btn {
  padding-right: 6px;
}
.hhwt-footer-fluid
  .hhwt-footer-container
  .hhwt-footer-download
  .hhwt-footer-download-btn
  a {
  outline: none;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-logo {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-copyright {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  text-align: center;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-copyright p {
  margin-bottom: 0px;
  color: #333;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-agree {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  line-height: 13px;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-agree a {
  color: #00cccc;
  text-decoration: underline;
  font-size: 12px;
  outline: none;
}
.hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-agree span {
  font-size: 9px;
  color: #333;
}

.hhwt-nav-right-container {
  padding: 0px 15px;
}

/* ============ new styles ========*/
.hhwt-nav-menu-li.srch {
  width: 446px !important;
}
.search.input-group {
  background: #fff;
  border-radius: 8px;
  max-width: 100% !important;
  height: 56px !important;
  margin-top: 7px !important;
}
.input-group-addon.trg-submit {
  background: none !important;
  padding: 6px 14px !important;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  input {
  background: none !important;
  font-size: 18px !important;
  color: #999999 !important;
  font-family: "Open Sans", sans-serif !important;
  height: 56px !important;
  padding: 0px 10px 0 0;
  box-shadow: none !important;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  input::placeholder {
  color: #999999 !important;
}
.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  .input-close {
  font-size: 16px;
  top: 16px;
}
.hhwtmainmenu {
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px;
  font-weight: 600;
}
.navbar-right {
  margin-right: 0px !important;
}
.hhwt-nav-menu-right {
  min-width: inherit;
}
.hhwt-nav-menu-right .hhwtmainmenu {
  float: right;
}
.carousel-control {
  text-shadow: none !important;
  color: #656565;
}
.carousel-control:focus,
.carousel-control:hover {
  color: #00cccb !important;
}
.fa-angle-right::before {
  margin-left: 2px;
}
.fa-angle-left::before {
  margin-right: 2px;
}

#featured-carousel .featured-fluid .featured-title a.category,
.modern-big header .category a {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  padding: 8px 15px;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}
#featured-carousel .featured-fluid .featured-title a.category:hover,
.modern-big header .category a:hover {
  background: rgba(0, 0, 0, 0.5);
}
.carousel-indicators {
  bottom: 30px;
}
.carousel-indicators li {
  background: #fff;
  margin: 0 4px;
  opacity: 0.5;
  vertical-align: middle;
  width: 8px;
  height: 8px;
}
.carousel-indicators li.active {
  opacity: 1;
  margin: 0 4px;
}
.sliderArws .carousel-control.left {
  left: 11px;
}
.sliderArws .carousel-control.right {
  right: 6px;
}
.carousel-control.left span,
.carousel-control.right span {
  -moz-box-shadow: 0px 2px 11px -3px rgba(0, 0, 0, 0.5);
  -khtml-box-shadow: 0px 2px 11px -3px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 2px 11px -3px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 11px -3px rgba(0, 0, 0, 0.5);
}
.carousel {
  overflow: hidden;
}

.authrDet {
  display: inline-block;
  width: 100%;
  height: 30px;
  overflow: hidden;
  margin-top: 5px;
}
.authrDet a {
  vertical-align: middle;
  font-size: 12px;
  line-height: 16px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  text-transform: capitalize;
}
.authrDet a:hover {
  text-decoration: none;
  color: #00cccb;
}
.authrDet a img {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  min-height: inherit !important;
  max-height: inherit !important;
  margin-right: 5px;
}
.content-title.mrgnbtm {
  margin-bottom: 0px;
}
.readTxt {
  width: 100%;
  display: none;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  float: left;
}
.pro_bucket_list .readTxt {
  display: block;
}
#featured-carousel .carousel-inner > .item {
  margin-top: 0px;
}
.nav > li > a {
  padding: 19px 12px !important;
  margin-left: 5px;
}

.hhwt_lrg_srch {
  position: fixed;
  top: 0px;
  left: 0px;
  background: #fff;
  height: 90px;
  width: 100%;
  z-index: 1000;
  padding: 9px 0;
  display: none;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
}

.hhwt_lrg_srch .trg-submit {
  border: 0px;
}
.hhwt_lrg_srch .form-control {
  border: 0px;
  width: 80%;
  box-shadow: none;
  font-size: 18px;
  line-height: 22px;
  color: #333;
  padding: 30px 10px;
  font-family: "Open Sans", sans-serif;
}
.hhwt_lrg_srch .form-control:focus {
  border: 0px;
  box-shadow: 0px;
  outline: none;
}
.hhwt_lrg_srch .input-close {
  font-size: 24px;
  line-height: 30px;
  color: #999999;
  margin-top: 15px;
  font-family: "ProximaNova";
  float: right;
  cursor: pointer;
}
.hhwt_lrg_srch .input-close:hover {
  color: #605f5f;
}
.srch_results {
  float: left;
  background: #fff;
  padding: 10px 0;
  width: 100%;
  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
  margin-top: 14px;
  border-radius: 0 0 8px 8px;
}
.srchRow {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.srchRow a:focus {
  outline: none;
}
.srchImg {
  width: 48px;
  height: 48px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  border-radius: 8px;
  vertical-align: middle;
}
.srchTitle {
  display: inline-block;
  margin-left: 20px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #333333;
  vertical-align: middle;
  font-weight: 600;
}
.srchTitle:hover {
  color: #00cccc;
}

.srch_all_results {
  border-top: 1px solid #dddddd;
  float: left;
  width: 100%;
  padding: 20px 0 10px;
  margin-top: 10px;
}
.srch_all_results a {
  color: #00cccc;
  font-size: 16px;
  line-height: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  transition: all ease 0.3s;
}
.srch_all_results a:hover {
  background: #00cccc;
  color: #fff;
  border-radius: 6px;
  padding: 10px 14px;
  text-decoration: none;
}
.srch_all_results .fa.fa-angle-right {
  font-size: 20px;
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 5px;
}

.hhwt-single-post .article-content p.article-tag {
  color: #666;
}
.hhwt-single-post .article-content p.article-tag a {
  color: #333;
  outline: none;
  background: rgba(153, 153, 153, 0.2);
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 22px;
  margin-right: 10px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 8px;
}
.hhwt-single-post .article-content p.article-tag a:first-child {
  margin-left: 10px;
}
.hhwt-single-post .article-content p.article-tag a:hover {
  color: #333;
  background: #ccf5f5;
}
.hhwt-related-wrapper.home_page {
  background: none;
}
.catgorySect {
  margin-bottom: 30px;
  margin-top: 20px;
}
.overlayBg {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  width: 100%;
  height: 60%;
  left: 0px;
  bottom: 0px;
}
.dest_overlay {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 8px;
  transition: all ease 0.3s;
}

.stickyMenu .hhwt-btns .hhwt-save-button,
.stickyMenu .hhwt-btns .fb-btn,
.stickyMenu .hhwt-btns .tw-btn,
.stickyMenu .hhwt-btns .mail_icon,
.stickyMenu .hhwt-btns .link_icon {
  box-shadow: none !important;
}
.stickyMenu .hhwt-btns .hhwt-save-button {
  border: 1px solid #d8d8d8 !important;
  width: 90px;
  height: 40px;
  text-align: center;
}
.stickyMenu .hhwt-btns .fb-btn,
.stickyMenu .hhwt-btns .tw-btn,
.stickyMenu .hhwt-btns .link_icon {
  width: 40px !important;
  height: 40px !important;
  font-size: 22px !important;
  line-height: 26px !important;
}
.stickyMenu .hhwt-btns .mail_icon {
  width: 40px !important;
  height: 40px !important;
  font-size: 20px !important;
  line-height: 26px !important;
}
.stickyMenu .hhwt-btns .link_icon {
  background-image: url("../images/ic_link.svg") !important;
  background-position: 7px center !important ;
}
.stickyMenu .hhwt-btns .link_icon:hover {
  background-image: url("../images/ic_link_hover.svg") !important;
}
.stickyMenu .hhwt-btns {
  width: 27% !important;
}
.save_buck_art_img {
  background-size: cover;
  width: 96px;
  height: 96px;
  border-radius: 8px;
  background-position: center center;
  padding: 0px !important;
  display: inline-block;
  vertical-align: middle;
}
.save-bucket-modal-content
  .save-modal-body
  .save-bucket-article
  .save_buck_art_title
  p
  a {
  background: rgba(153, 153, 153, 0.2);
  color: #333333 !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  border-radius: 8px;
  padding: 4px 9px;
  display: inline-block;
}
.articles-all-page .hhwt-result-title {
  margin-top: 50px;
  margin-bottom: 40px;
  display: inline-block;
}
.articles-all-page .hhwt-result-title h3 {
  font-family: "ProximaNova-Bold";
  font-size: 33px;
  color: #333;
  line-height: 40px;
  text-transform: capitalize;
}
.articles-all-page .hhwt-result-title span {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.catBreadCrumb {
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 22px;
}
.catBreadCrumb a {
  text-transform: capitalize;
  color: #00cccc;
  font-weight: bold;
}
.hhwt-child-cat-link .hhwt-nav-pills.dc_tabs {
  float: left;
}
.whiteBg.dc_tabs {
  margin-bottom: 30px;
}
.hhwt-child-cat-link .hhwt-nav-pills li {
  border-bottom: 0px solid #fff;
  transition: all ease 0.3s;
}
.mb_rght_div {
  float: right;
}
.mb_rght_div .hhwt-user-menu {
  float: right;
  padding-right: 0px;
}
.mb_rght_div .hhwt-user-menu .navbar-nav {
  margin: 0px;
}
.mb_rght_div .hhwt-user-menu .nav > li > a {
  padding: 0px !important;
  font-size: 16px !important;
  vertical-align: inherit;
}
.navbar-collapse.hhwt-mob-menu {
  padding: 11px;
  margin-bottom: 0px !important;
}
.hhwt-mob-menu .nav > li > a {
  padding: 0px !important;
  margin-left: 0px !important;
  vertical-align: middle;
}
.hhwt-mob-menu .hhwtmainmenu {
  font-size: 13px !important;
  line-height: 24px;
  position: inherit;
}
.hhwt-mob-menu .hhwtmainmenu .hhwt_menu_icons {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
}
.hhwt-mob-menu .hhwtmainmenu .hhwt_menu_icons img {
  width: 100%;
}
.hhwt-mob-menu .hhwtmainmenu .visible-xs {
  display: inline-block !important;
}
/* .hhwt-mob-menu .nav > li > a:focus, .hhwt-mob-menu .nav > li > a:hover{ background: none !important;} */
.hhwt-mob-menu ul.hhwt-nav-menu > li ul.dropdown-menu.menucontainer {
  border-radius: 0 0 8px 8px;
  width: 100.5% !important;
  left: -1px;
  top: 109%;
  transform: inherit;
}
.hhwt-mob-menu ul.hhwt-nav-menu .hhwtmainsubmenu > a {
  padding: 8px 24px 8px 24px !important;
  font-size: 14px !important;
}
.hhwt-mob-menu ul.hhwt-nav-menu > li .hhwtmainsubmenu.active a {
  padding-top: 0px !important;
  padding-bottom: 2px !important;
}
.hhwt-mob-menu ul.hhwt-nav-menu > li .hhwtmainsubmenu.active.menu_hk {
  padding-bottom: 11px;
}
.catgorySect .content-title h4 {
  margin-bottom: 20px;
}
.modern-big .overlayBg {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  bottom: inherit;
  top: 0px;
  border-radius: 8px 8px 0 0;
}
.mobile_arw.fa.fa-chevron-down {
  display: none;
}
.hhwt-mob-menu .hhwtmainmenu.open .caret {
  border-top: 0px;
  border-bottom: 4px dashed;
}
#footer {
  float: left;
  width: 100%;
  position: relative;
}
.loaderCnt.artLoad {
  height: auto;
  min-height: 600px;
  border-bottom: 0px;
}
.loaderCnt.artLoad img {
  width: 100%;
  float: left;
}

.modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal.fade .modal-dialog {
  transform: translate(0, -100%);
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-content {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.hhwt-signup-content .btn-social-fb {
  text-align: left;
  color: white !important;
  background-color: #3b5998;
  border: none !important;
}
.hhwt-signup-content .btn-social-fb:hover {
  background-color: #4a66a0;
}
.hhwt-signup-content .btn-social-g {
  text-align: left;
  color: white !important;
  background-color: #4285f4;
  border: none !important;
}
.hhwt-signup-content .btn-social-g:hover {
  background-color: #5591f5;
}
.hhwt-signup-content #hhwt-form-remove-article .tickImg {
  margin: 24px 0;
}
.hhwt-signup-content #hhwt-form-remove-article .text-confirm {

}

.social-icon-box {
  width: 24px;
  height: 24px;
  background-color: white;
  padding: 0px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}
.social-icon-box.no_bg {
  background: none;
}
.social-icon-box.no_bg img {
  margin-top: -2px;
}
.social-icon-box img {
  width: auto;
}
.btn-social-fb .social-icon-box img {
  margin-left: 2px;
}
.hhwt-form-registration,
.hhwt-form-login,
.hhwt-forget-password {
  width: 336px;
  margin: 0 auto;
}

.hhwt-signup-content .form-group.padd_inc {
  padding: 5px 0px;
}

.hhwt-form-login .divider {
  width: 96%;
  height: 1px;
  border: 1px solid rgb(216, 216, 216);
  display: inline-block;
  margin: 0 2% 3px;
}
.hhwt-form-login .divider-text {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #d8d8d8;
  position: absolute;
  top: 3px;
  left: calc(50% - 12px);
  background: #fff;
  padding: 2px 5px;
}
.fb-icon {
  background-image: url("../images/menu-icons/facebook.png");
  background-position: 10px bottom;
}
.g-icon {
  background-image: url("../images/menu-icons/search.png");
}
.email-icon {
  background-image: url("../images/menu-icons/email.png");
}
.hhwt-signup-content .btn-email {
  text-align: left;
  color: white !important;
  background-color: #00cccc;
  border: none !important;
}
.hhwt-signup-content .btn-email:hover {
  background-color: #24d3d3;
}
.loginLink {
  text-align: center;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  font-family: "Open Sans", sans-serif;
  color: #333;
  margin-bottom: 0px;
}
.loginLink.mrgnTop {
  margin-top: 24px;
}

#forget-password {
  font-weight: bold;
}

.loginLink p, .loginLink a,
.hhwt-signup-content .hhwt-terms-condition a {
  color: #00cccc;
  font-weight: bold;
}
.loginLink p:hover, .loginLink a:hover,
.hhwt-signup-content .hhwt-terms-condition a:hover,
#forget-password:hover {
  text-decoration: underline;
}
#forget-password {
  margin-top: 0px;
  padding-top: 0px;
}

#hhwt-modal-signup label.d-block {
  display: block;
  margin: 0;
  padding-bottom: 0;
  color: #8c8c8c;
  line-height: 18px;
  margin-bottom: 10px;
}
.hhwt_signup_logo {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.hhwt_signup_logo img {
  max-width: 97px;
}

.hhwt-signup-after-form .hhwt-signup-content h3 {
  font-size: 36px;
  line-height: 43px;
}

.hhwt-push-notify-form {
  width: 390px;
  margin: 0 auto;
}
.allow_push_notify {
  color: #757575;
  font-family: "ProximaNova";
  font-size: 15px;
  line-height: 20px;
  display: inline-block;
  margin-top: 8px;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.allow_push_notify label {
  float: left;
  width: 100%;
  position: relative;
  padding-left: 8%;
}

.allow_push_notify a {
  color: #00cccc;
  font-family: "ProximaNova-Bold";
  position: relative;
  z-index: 10;
}
.allow_push_notify:hover a {
  text-decoration: underline !important;
}

.allow_push_notify input[type="checkbox"]:not(old) {
  opacity: 0;
  width: 100%;
  height: 28px;
  padding: 0;
  margin: 0;
  left: 0px;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}
.allow_push_notify label::after {
  content: "";
  position: absolute;
  /*cover the default input */
  top: 0;
  left: 0px;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: rotate(0);
  pointer-events: none;
  text-align: center;
  color: #fff; /* easier to see in demo */
  border: 1px solid #666666;
  /* content: "\2713"; */
  font-size: 14px;
  line-height: 18px;
  background: #fff;
}
.allow_push_notify input[checked=""] + label:after,
.allow_push_notify input[checked="checked"] + label:after {
  background: #fff url("../images/checkbox_on.svg") -4px -4px no-repeat;
  border: 1px solid #00cccc;
}
.allow_push_notify input[type="checkbox"]:hover + label:after {
  border: 1px solid #00cccc;
}

#latest-posts .simple-small header .authrDet {
  margin-top: 40px;
}
.loginLink.mrgntp {
  margin-top: 12px;
}

.hhwt-signup-dialog .modal-body.hhwt_create_trip {
  margin-top: 0px;
}

.hhwt-signup-content .hhwt-create-trip-form .hhwt-popup-title,
.hhwt-signup-content .hhwt-past-trip-form .hhwt-popup-title {
  font-size: 27px;
  color: #333333;
  line-height: 24px;
  text-align: left;
}
.hhwt-form-newtrip .alert {
  padding-top: 10px;
}
.fieldLabel {
  color: #333333;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.hhwt-signup-content .hhwt-create-trip-form .form-group .form-control,
.hhwt-signup-content .hhwt-past-trip-form .form-group .form-control {
  border: 1px solid #666666;
  position: relative;
}
select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../images/Icon-ic_dropdown.svg") 97% center no-repeat;
}

.hhwt-signup-content select.form-control:invalid {
  color: #999;
}
/* select.form-control:after{ background-image: url('../images/Icon-ic_dropdown.svg'); position: absolute; width: 24px; height: 24px; right: 10px; top: 10px;} */
.no_pad_lft {
  padding-left: 0px;
}
.no_pad_rgt {
  padding-right: 0px;
}

.hhwt-signup-content .form-group.dont_know_dates label {
  display: initial;
  margin-left: 28px;
  color: #757575;
  font-family: "ProximaNova";
  font-size: 16px;
  line-height: 19px;
}
.hhwt-signup-content .hhwt-create-trip-form .form-group,
.hhwt-signup-content .hhwt-past-trip-form .form-group {
  margin-bottom: 18px;
}

.hhwt-signup-content .hhwt-submit-btn.next_btn {
  float: right;
  width: 80px;
  height: 48px;
}
.hhwt-signup-content .form-group.cities_list a {
  border: 1px solid #666666;
  border-radius: 8px;
  background-color: #ffffff;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  padding: 4px 10px;
  margin-right: 6px;
  float: left;
  margin-bottom: 3px;
}
.hhwt-signup-content .form-group.cities_list a:hover {
  border: 1px solid #00cccc;
  color: #00cccc;
  text-decoration: none;
}
.no_mrgn_btm {
  margin-bottom: 0px !important;
}
.hhwt-signup-content .form-group.cities_list a.actv_cls {
  background: #00cccc;
  color: #fff;
  border: 1px solid #00cccc;
}
.hhwt-create-trip-form,
.hhwt-past-trip-form {
  margin-bottom: -24px;
  float: left;
  width: 100%;
}
.hhwt-past-trip-form .hhwt-form-newtrip .alert {
  padding: 0px;
}

.srchCitiesList {
  position: absolute;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  top: 80px;
  left: 0px;
  padding: 13px 17px 0px 17px;
  z-index: 100;
  display: none;
  max-height: 220px;
  overflow: auto;
}
.srchCitiesList a {
  color: rgba(0, 0, 0, 0.87);
  font-family: "ProximaNova";
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}
.srchCitiesList a:hover {
  text-decoration: none;
  color: #00cccc;
}

#month_year_range {
  margin-bottom: 22px;
}

.dont_know_dates input[type="checkbox"]:not(old) {
  opacity: 0;
  width: 100%;
  height: 28px;
  padding: 0;
  margin: 0;
  left: 0px;
  position: absolute;
  z-index: 10;
  cursor: pointer;
}
.dont_know_dates label::after {
  content: "";
  position: absolute;
  /*cover the default input */
  top: 0;
  left: 0px;
  width: 18px;
  height: 18px;
  transform: rotate(0);
  pointer-events: none;
  text-align: center;
  color: #fff; /* easier to see in demo */
  border: 1px solid #666666;
  /* content: "\2713"; */
  font-size: 14px;
  line-height: 18px;
  border-radius: 3px;
  background: #fff;
}
.dont_know_dates #dont_know_dates[checked="checked"] + label:after {
  background: #fff url("../images/checkbox_on.svg") -4px -4px no-repeat;
  border: 1px solid #00cccc;
}
.dont_know_dates #dont_know_dates:hover + label:after {
  border: 1px solid #00cccc;
}

.dont_know_dates #edit_dont_know_dates[checked="checked"] + label:after {
  background: #fff url("../images/checkbox_on.svg") -4px -4px no-repeat;
  border: 1px solid #00cccc;
}
.dont_know_dates #edit_dont_know_dates:hover + label:after {
  border: 1px solid #00cccc;
}

.scroll-container {
  width: 100%;
  float: left;
}
.cities_scroll {
  height: 100%;
  align-items: center;
  width: 1350px;
  justify-content: space-between;
}
.wht_gradiant_gb {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1px;
  height: 32px;
  width: 48px;
  background: linear-gradient(
    270deg,
    #ffffff 19.62%,
    rgba(255, 255, 255, 0) 100%
  );
}

.hhwt-signup-content .form-group .form-control.estdt_wdth {
  width: 82px;
  float: left;
}
.days {
  float: left;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 10px;
  margin-top: 12px;
}

#estimated_date_field {
  margin-bottom: 35px;
}

.hhwt-signup-dialog .hhwt-trip-after-form .modal-body,
.hhwt-signup-dialog .hhwt-past-trip-after-form .modal-body {
  margin-top: -21px;
}

.hhwt-signup-content .hhwt-past-trip-after-form h3 {
  width: 76%;
  margin: 25px auto auto;
}
.hhwt-signup-content .hhwt-trip-after-form h3,
.hhwt-signup-content .hhwt-past-trip-after-form h3 {
  font-size: 36px;
  line-height: 43px;
  margin-top: 25px;
}
.hhwt-signup-content .hhwt-trip-after-form p,
.hhwt-signup-content .hhwt-past-trip-after-form p {
  margin-top: 0px;
  margin-bottom: 40px;
}
.hhwt-signup-content .hhwt-submit-btn.no_bg {
  background: none;
  border: 1px solid #00cccc;
  color: #00cccc;
}
.hhwt-signup-content .hhwt-submit-btn.no_bg:hover {
  background-color: #f5f5f5;
}
.hhwt-trip-after-form,
.hhwt-past-trip-after-form {
  margin-bottom: -32px;
}
.hhwt-signup-content .alert {
  float: left;
  width: 100%;
}

.hhwt-signup-content .hhwt-edit-prof-form .alert {
  display: none;
  margin-top: 10px;
  margin-bottom: 0px;
}

.profile_page {
  margin-top: 30px;
}
.profile_page .hhwt-author-details {
  width: 270px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;
}

.prfile_edit_sect {
  float: right;
  width: 100%;
}
.prf_set_icon {
  float: right;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  margin-left: 16px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
  padding-top: 7px;
  transition: all ease 0.3s;
}
.prf_set_icon:hover {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
}

.prf_set_icon.setting_icon {
  display: none;
  background: #ffffff url("../images/ic_thumb_up_black-ic_settings.png") center
    center no-repeat;
}
.prf_set_icon.setting_icon:hover {
  display: none;
  background-image: url("../images/ic_ic_settings_hover.png");
}

.prf_set_icon.edit_icon {
  background: #ffffff url("../images/ic_thumb_up_black-ic_edit.png") center
    center no-repeat;
}
.prf_set_icon.edit_icon:hover {
  background-image: url("../images/ic_ic_edit_hover.png");
}

.prf_set_icon.delete_icon {
  background: #ffffff url("../images/Icon-ic_close_outlined.svg") center center
    no-repeat;
}
.prf_set_icon.delete_icon:hover {
  background-image: url("../images/close_blue.png");
}

.profile_page .hhwt-author-avatar {
  display: inline-block;
  width: 100%;
  margin-top: 16px;
}
.profile_page .hhwt-author-avatar h3 {
  font-size: 20px !important;
  color: #333 !important;
  line-height: 24px;
  margin-top: 10px !important;
  text-transform: capitalize;
}
.author_slug {
  color: #00cccc;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  line-height: 23px;
}

.profile_page .hhwt-author-bio {
  margin-top: 4px;
  color: #666666;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.past_trip_btn {
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-top: 24px;
}

.countsTrip {
  float: left;
  width: 100%;
  margin-top: 24px;
}
.countsTtl {
  color: #999999;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  padding: 0 3px;
}
.countsTtl span {
  color: #666666;
  font-family: "ProximaNova-Bold";
  font-size: 24px;
  line-height: 29px;
  text-align: center;
}

.profile_page .hhwt-author-posts {
  float: right;
  border: 0px !important;
  padding: 0 !important;
  padding-left: 15px !important;
  padding-bottom: 30px !important;
}
.postTabCnt {
  float: left;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.postTabCnt ul {
  display: inline-block;
  text-align: left;
  width: 100%;
  margin-top: 14px;
}
.postTabCnt ul li {
  display: inline-block;
  color: #333333;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  padding: 0 32px;
  padding-bottom: 10px;
  margin: 0 30px 0 0;
  text-align: center;
  border-bottom: 4px solid #fff;
}
.postTabCnt ul li:hover,
.postTabCnt ul li.actv_cls {
  border-bottom: 4px solid #00cccc;
}
.postTabCnt ul li a {
  color: #333333;
  padding-left: 36px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.postTabCnt ul li a:hover {
  text-decoration: none;
  color: #00cccc;
}

.postTabCnt ul li.for_you_tab a {
  background: url("../images/for_you_icon.png") left center no-repeat;
}
.postTabCnt ul li.for_you_tab a:hover,
.postTabCnt ul li.for_you_tab.actv_cls a,
.postTabCnt ul li.for_you_tab:hover a {
  background-image: url("../images/for_you_icon_hover.png");
  color: #00cccc;
}

.postTabCnt ul li.bucket_list_tab a {
  background: url("../images/bucket_list_icon.png") left center no-repeat;
}
.postTabCnt ul li.bucket_list_tab a:hover,
.postTabCnt ul li.bucket_list_tab.actv_cls a,
.postTabCnt ul li.bucket_list_tab:hover a {
  background-image: url("../images/bucket_list_icon_hover.png");
  color: #00cccc;
}
.postTabCnt ul li.bucket_list_tab a:focus {
  text-decoration: none;
}
.postTabCnt ul li.trips_tab a {
  background: url("../images/trips_icon.png") left center no-repeat;
}
.postTabCnt ul li.trips_tab a:hover,
.postTabCnt ul li.trips_tab.actv_cls a,
.postTabCnt ul li.trips_tab:hover a {
  background-image: url("../images/trips_icon_hover.png");
  color: #00cccc;
}
.postTabCnt ul li.trips_tab a:focus {
  text-decoration: none;
}

.createTripCnt {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 24px;
}
.trip_tree_img {
  display: inline-block;
  margin-top: 112px;
}

.next_trip_text {
  display: inline-block;
  width: 100%;
  color: #333333;
  font-family: "ProximaNova-Bold";
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-top: 32px;
}

.get_trip_txt {
  display: inline-block;
  width: 100%;
  color: #999999;
  font-family: "Open Sans";
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
}
.addTrip_Btn {
  border-radius: 8px;
  background-color: #00cccc;
  display: inline-block;
  margin-top: 32px;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 11px 23px 13px 23px;
  transition: all ease 0.3s;
}
.addTrip_Btn:hover {
  text-decoration: none;
  color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #24d3d3;
}

.pro_no_rec .next_trip_text {
  margin-top: 150px;
}
.pro_no_rec .get_trip_txt {
  margin-top: 10px;
}
.pro_no_rec .addTrip_Btn {
  margin-top: 20px;
}

.pro_travelled {
  float: left;
  text-align: center;
  width: 100%;
  margin-top: 24px;
  position: relative;
}
.pro_travelled h3 {
  color: #999999;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
}
.pro_travel_img {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.pro_travel_img img {
  max-width: 32px;
  margin: 0 4px 4px 4px;
}

.profile_page {
  padding-bottom: 40px;
}
.tripsList {
  float: left;
  width: 100%;
  text-align: left;
}
.tripsList .content-title h4 {
  margin-top: 0px;
  text-transform: capitalize;
}

.food_tag {
  float: left;
  width: 100%;
  margin-top: 10px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.profile_page .hhwt-author-container .simple-small header {
  width: 100%;
  min-height: 215px;
  height: 225px;
}

.hhwt-author-container .authrDet {
  margin-top: 10px;
}
.profile_page .hhwt-author-container .readTxt.no_rgn {
  margin-top: 0px;
}

.hhwt-author-container .simple-small h3.plannerTtl {
  max-height: 44px;
  min-height: 44px;
}

.tripGrid {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  padding: 0px;
  border-radius: 8px;
  overflow: hidden;
  transition: all ease 0.3s;
  margin-right: 3.5%;
  width: 48%;
  margin-bottom: 30px;
  position: relative;
  top: 0px;
}
.tripGrid:nth-child(odd) {
  margin-right: 0px;
  float: right;
}
.tripImag {
  float: left;
  width: 100%;
  height: 228px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 16px;
}

.prf_star_rat {
  color: #333333;
  font-family: "ProximaNova";
  font-size: 18px;
  line-height: 24px;
  float: left;
  width: 100%;
  margin-top: 10px;
}

.prf_star_rating {
  display: inline-block !important;
  margin-left: 5px;
  width: 91px;
  background: url("../images/star_rating_grey.png") center 3px repeat-x;
  height: 21px;
  position: relative;
  vertical-align: top;
}
.prf_star_rating span {
  position: absolute;
  background: url("../images/star_rating_yellow.png") repeat-x;
  height: 21px;
  left: 0px;
  top: 3px;
}

.tripBtm {
  bottom: 0px;
  position: absolute;
  left: 0px;
  width: 100%;
}
.nextTripCls {
  float: left;
  width: 100%;
  background-color: #fff;
  padding: 14px 16px;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
}
.nextTripCls b {
  float: right;
}
.tripGrid .prf_set_icon {
  position: relative;
}
.tripImag .overlayBg {
  height: 100%;
}
.tripName {
  margin-bottom: 16px;
  margin-left: 16px;
  width: 45%;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  font-weight: bold;
  line-height: 28px;
  float: left;
}
.tripName small {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal;
}
.tripDates {
  margin-bottom: 16px;
  margin-right: 16px;
  width: 45%;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  float: right;
  text-align: right;
  margin-top: 7px;
}

.tripsList.mrgnTop {
  margin-top: 15px;
}
.past_trip_grid .tripImag {
  height: 182px;
}
.past_trip_grid .tripName {
  font-size: 20px;
  line-height: 27px;
  width: 60%;
}
.past_trip_grid .tripDates {
  font-size: 15px;
  line-height: 20px;
  width: 30%;
}
.tripDates span {
  font-weight: 400;
}

.hhwt-signup-content .hhwt-edit-pasttrip .alert,
.hhwt-signup-content .hhwt-edit-trip .alert {
  margin-bottom: 0px;
}

.menu-opener,
.menu-opener:hover,
.menu-opener.active,
.menu-opener-inner,
.menu-opener-inner::before,
.menu-opener-inner::after,
.hhwt-author-details,
.hhwt-author-details.active {
  -webkit-transition: 250ms all;
  transition: 250ms all;
}

.menu-opener {
  cursor: pointer;
  height: auto;
  position: absolute;
  top: 7px;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: auto;
  display: none;
}

.menu-opener-inner {
  background: #333;
  height: 0.5rem;
  margin-left: 0.75rem;
  margin-top: 1.75rem;
  width: 2.5rem;
}
.menu-opener-inner::before,
.menu-opener-inner::after {
  background: #333;
  content: "";
  display: block;
  height: 0.5rem;
  width: 2.5rem;
}
.menu-opener-inner::before {
  -webkit-transform: translateY(-0.75rem);
  -ms-transform: translateY(-0.75rem);
  transform: translateY(-0.75rem);
}
.menu-opener-inner::after {
  -webkit-transform: translateY(0.25rem);
  -ms-transform: translateY(0.25rem);
  transform: translateY(0.25rem);
}
.menu-opener-inner.active {
  background: transparent;
}
.menu-opener-inner.active::before {
  -webkit-transform: translateY(0rem) rotate(-45deg);
  -ms-transform: translateY(0rem) rotate(-45deg);
  transform: translateY(0rem) rotate(-45deg);
}
.menu-opener-inner.active::after {
  -webkit-transform: translateY(-0.5rem) translateX(0rem) rotate(45deg);
  -ms-transform: translateY(-0.5rem) translateX(0rem) rotate(45deg);
  transform: translateY(-0.5rem) translateX(0rem) rotate(45deg);
}

.postTabCnt ul li.for_you_tab {
  display: none;
}
/* .pro_bucket_list  .indiana-scroll-container--hide-scrollbars{ overflow: inherit;} */
.header-menu-opener {
  float: left;
  width: 18px;
  height: auto;
  margin-top: 8px;
  margin-right: 20px;
  cursor: pointer;
}
.mb_menu_bar {
  float: left;
  width: 100%;
  height: 3px;
  background: #fff;
  margin-bottom: 3px;
}

.categoriesList {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.categoriesList a {
  border: 1px solid #666666;
  border-radius: 8px;
  background-color: #ffffff;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  padding: 4px 10px;
  margin-right: 8px;
  float: left;
  margin-bottom: 3px;
}
.categoriesList a:hover {
  border: 1px solid #00cccc;
  color: #00cccc;
  text-decoration: none;
}
.categoriesList a.actv_cls {
  border: 1px solid #00cccc;
  background: #00cccc;
  color: #00cccc;
  text-decoration: none;
  color: #fff;
}
.hhwt-search-category {
  display: none;
}
.defaultImg {
  width: 100%;
  height: 229px;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  bottom: 0;
  left: 0;
  background-position: center center;
  border-radius: 8px 8px 0 0;
  background-image: url("../images/foodnotfound.png");
}

.content-title .btn-more span.fa-angle-left {
  margin-left: 0px;
}

.pro_bucket_list .content-title .btn-more {
  margin-top: -6px;
}
.no_articles {
  color: #e20f0f;
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 18px;
  min-height: 400px;
  text-align: center;
  padding-top: 30px;
}
.maxText {
  float: left;
  width: 100%;
  text-align: left;
  color: #fe1a25;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  line-height: 17px;
}

.tripsList .content-title {
  display: inline-block;
  width: 100%;
}
.add_trip {
  box-sizing: border-box;
  height: 40px;
  width: auto;
  border: 1px solid #00cccc;
  border-radius: 8px;
  background-color: #ffffff;
  color: #00cccc;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  padding-top: 2px;
  float: right;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 2px;
}
.plus_txt {
  font-size: 24px;
  line-height: 28px;
  padding-right: 5px;
}
.add_trip span {
  vertical-align: middle;
}
.add_trip:hover {
  background: #f5f5f5;
  color: #00cccc;
}
.__react_component_tooltip {
  padding: 3px 8px;
  font-size: 12px !important;
  line-height: 17px;
  font-family: "Open Sans", sans-serif;
}

#signUpBtn.disabled,
#signUpBtn[disabled] {
  background: #999;
}

.nextTripCls.setDate {
  background-color: #00cccc;
  text-align: center;
  color: #ffffff;
  font-size: 17px;
  line-height: 23px;
  cursor: pointer;
}
.nextTripCls.setDate:hover {
  background-color: #24d3d3;
}

.delete_trip {
  color: #c33333;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 13px;
  margin-right: 24px;
  float: right;
}
.delete_trip:hover {
  color: #c33333;
  text-decoration: underline;
}

.trip_btns .col-md-6 {
  width: 48%;
}
.trip_btns .take_prf_btn {
  float: right;
}

.edit_great_txt {
  margin-top: 36px !important;
}
.hhwt-signup-content p.edit_success_txt {
  margin-top: 4px;
  margin-bottom: 24px;
}
.delete_trip_btns {
  margin-bottom: 8px !important;
  margin-top: 26px;
}
.delete_trip_btn {
  border: 1px solid #c33333 !important;
  color: #c33333 !important;
}

.hhwt-signup-content .form-group .form-control.hideDate {
  position: absolute;
  top: 30px;
  left: 0px;
  width: 94%;
  height: 45px;
  z-index: 10;
}
.hhwt-signup-content .form-group .no_pad_rgt .form-control.hideDate {
  left: inherit;
  right: 0px;
}
.hhwt-signup-content .form-group .form-control.error_bdr,
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-select-list
  select.error_bdr {
  border: 1px solid #c33333;
}
.error_msg {
  float: left;
  width: 100%;
  color: #c33333;
  font-family: "ProximaNova";
  font-size: 14px;
  line-height: 17px;
  padding-left: 0px;
  margin-top: 3px;
  text-align: left;
}

.hhwt-author-container .hhwt-author-posts h2.mrgin_btm {
  margin-bottom: 30px;
}
.hhwt-author-page .hhwt-author-details {
  margin-top: 40px;
}
.hhwt-author-page.profile_page .hhwt-author-details {
  margin-top: 0px;
}

.hhwt-child-cat-link .hhwt-nav-pills li a.cat_active,
.hhwt-child-cat-link .hhwt-nav-pills div a.cat_active {
  border-bottom: 3px solid #08cccb;
  color: #00cccc !important;
  background-color: #fff;
  font-weight: bold;
  border-radius: 0px;
}

input:focus {
  box-shadow: 0px !important;
  outline: none !important;
}

.overflwHidden {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.testCls {
  position: relative;
  z-index: 999999999999999;
  float: left;
  width: 30%;
  height: 60px;
  border: 1px solid #000;
}

.form_lbl {
  float: left;
  width: 100%;
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 3px;
  margin-top: -6px;
}

.prfImg {
  height: 80px;
  width: 80px;
  background-color: #d8d8d8;
  float: left;
  border-radius: 50%;
  margin-top: 15px;
  margin-bottom: 20px;
}

.changePic {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  height: 36px;
  width: 127px;
  border-radius: 8px;
  background-color: #00cccc;
  float: left;
  padding-top: 8px;
  cursor: pointer;
  margin-left: 16px;
  margin-top: 36px;
  position: relative;
}
.changePic:hover {
  background-color: #24d3d3;
}
.removePic {
  float: left;
  height: 36px;
  width: 80px;
  border: 1px solid #c33333;
  border-radius: 8px;
  color: #c33333;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  margin-top: 36px;
  padding-top: 7px;
  cursor: pointer;
  margin-left: 20px;
}
.removePic:hover {
  background: #c33333;
  color: #fff;
  text-decoration: none;
}
.changePic input {
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0px;
  height: 36px;
  left: 0px;
}

.prfBgImg {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  float: left;
  background-size: cover;
  background-position: center;
}
.avatar.prfBgImg {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  display: inline-block;
  float: inherit;
}
.prfBgImg.header_icon {
  width: 41px;
  height: 41px;
}

.datepicker.datepicker-dropdown {
  width: 300px !important;
  border-radius: 8px;
  font-family: "Roboto", sans-serif !important;
  padding: 10px;
  position: fixed !important;
  top: 20px !important;
}
.datepicker.datepicker-dropdown table {
  width: 100%;
}
.datepicker-dropdown.datepicker-orient-top::before {
  border-top: 7px solid #fff !important;
}
.datepicker .datepicker-switch {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover {
  background-color: rgba(0, 204, 204, 0.16) !important;
}
.datepicker td,
.datepicker th {
  font-size: 12px !important;
  width: 40px !important;
  height: 40px !important;
}
.datepicker th.next,
.datepicker th.prev {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.datepicker th {
  color: rgba(0, 0, 0, 0.38);
}
.datepicker table tr td.day {
  color: rgba(0, 0, 0, 0.87);
  border-radius: 50% !important;
}
.datepicker table tr td.disabled,
.datepicker table tr td.old {
  color: rgba(0, 0, 0, 0.16);
}

.datepicker table tr td.active.day,
.datepicker table tr td.selected.range-start,
.datepicker table tr td.active.selected.range-start.range-end {
  background-color: #00cccc !important;
  color: #fff !important;
  background-image: none !important;
  border-radius: 50% !important;
  font-weight: 500 !important;
}
.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background-color: rgba(0, 204, 204, 0.3);
  color: rgba(0, 0, 0, 0.87) !important;
}
.datepicker table tr td.range.day,
.datepicker table tr td.range.selected.day {
  background-color: rgba(0, 204, 204, 0.3);
  border-radius: 0px !important;
  background-image: none !important;
}

.datepicker table tr td.active.selected.range-start,
.datepicker table tr td.selected.range-start {
  border-radius: 50% 0 0 50% !important;
}
.datepicker table tr td.selected.range-end,
.datepicker table tr td.active.range.selected {
  background-color: #00cccc !important;
  color: #fff !important;
  background-image: none !important;
  border-radius: 0 50% 50% 0 !important;
}

.hhwt-signup-content .input-daterange .form-control {
  background: #fff;
}
._yeti_main_container {
  margin-bottom: 30px !important;
}
.article-content ._yeti_main_container {
  margin-bottom: -20px !important;
  margin-top: 15px !important;
}

._yeti_main_container iframe,
center iframe {
  border-radius: 0px !important;
}

.modal {
  z-index: 900 !important;
}

.destinationImg {
  width: 100%;
  height: 413px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.catImgLink {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.loaderBg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
}
.loaderBg .form-loading {
  display: inline-block;
  margin-top: 92%;
}

.form-loading {
  display: flex;
  justify-content: center;

  margin-top: 16px;
}

._yeti_main_container._yeti_footer_ {
  display: none !important;
}

.tripsList .content-title h4 a {
  vertical-align: top;
}
.edit_bucket_cls {
  border: 0px;
  width: auto;
}
.stickyRightAds.pst_ads {
  position: absolute;
  right: 3%;
  top: 780px;
}
.stickyRightAds.pst_fixed {
  position: fixed;
  right: 3%;
  top: 100px;
}
#article_content_id {
  position: relative;
}
.hhwt-edit-prof-form {
  padding-bottom: 15px;
  position: relative;
}
#hhwt-form-edit-profile.hhwt-form-registration {
  width: 80%;
}
textarea#edit_about {
  height: 100px;
}
.dialing_code img {
  max-width: 20px;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  vertical-align: initial;
}
.dialing_code .btn-default {
  height: 45px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: normal !important;
  border: 1px solid #666;
  width: 100%;
  text-align: left !important;
}
.dialing_code.open .btn-default,
.dialing_code .btn-default:hover {
  background: none !important;
}
.dialing_code .btn-default .caret {
  float: right;
  margin-top: 11px;
}
.dialing_code .dropdown-menu {
  width: 100%;
  min-width: 120px;
  border-radius: 8px;
  padding: 6px 12px;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  margin-top: -248px;
  box-shadow: none;
}

.dialing_code ul {
  width: 100%;
  overflow-y: auto;
  height: 200px;
}
.dialing_code .dropdown-menu img {
  margin-right: 10px;
  vertical-align: middle;
}
.dialing_code .dropdown-menu li {
  width: 100%;
  float: left;
  margin-bottom: 3px;
  cursor: pointer;
}
#search_code {
  font-size: 13px;
  padding: 6px 10px;
  height: auto;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.form-group.required .form_lbl:after {
  content: "*";
  color: red;
}
.form-group .err-msg {
  display: block;
  margin-top: 6px;
  color: #ff1f47;
}
.hhwt-signup-content .hhwt-edit-prof-form .alert {
  position: absolute;
  bottom: 65px;
  width: 80%;
  left: 10%;
  z-index: 1;
}

.bucket_name[contenteditable="true"] {
  border: 1px solid #cecece;
  font-size: 16px;
  padding: 10px 15px;
  height: 36px;
  display: inline-block;
  border-radius: 4px;
}
.bucket_update_btn {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  height: 36px;
  width: auto;
  border-radius: 4px;
  background-color: #00cccc;
  display: inline-block;
  padding: 8px 15px;
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  vertical-align: middle !important;
  margin-top: 3px;
}
.bucket_update_btn:hover {
  text-decoration: none;
  background: #24d3d3;
  color: #ffffff;
}

.tripsList .alert {
  display: none;
}
.tripsList .alert.alert-success,
.tripsList .alert.alert-danger {
  display: block;
}

.custom_tooltip {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  left: -999em;
  opacity: 0;
  padding: 8px 21px;
  position: fixed;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  top: -999em;
  visibility: hidden;
  z-index: 999;
}
.custom_tooltip {
  padding: 3px 8px;
  font-size: 12px !important;
  line-height: 17px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  background-color: #222;
  margin-top: -10px;
  position: absolute;
  white-space: nowrap;
}
.custom_tooltip.show {
  opacity: 1;
  visibility: visible;
}

.custom_tooltip:before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
}
.custom_tooltip:before,
.custom_tooltip:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
}
.custom_tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  bottom: -6px;
  left: 50%;
  margin-left: -8px;
}
.custom_tooltip:after {
  border-top-color: #222;
  border-top-style: solid;
  border-top-width: 6px;
}

.article-content ul br,
.article-content ol br,
.article-content table br {
  display: none;
}
.article-content table td {
  padding: 10px;
}
.article-content table {
  height: auto !important;
}
/* .hhwt-single-post .article-content div br{ display: none;} */

/* ============ new styles ========*/

@media only screen and (min-width: 1200px) {
  #menu-login-menu {
    padding-top: 0px !important;
  }
  .custom-container.carousel-inner {
    width: 1230px !important;
    padding: 0px 30px 30px;
  }
}

@media only screen and (max-width: 1240px) {
  .stickyRightAds.pst_ads,
  .stickyRightAds.pst_fixed {
    right: 10px;
  }
}

@media only screen and (max-width: 1160px) {
  .stickyRightAds {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .simple-small:hover {
    top: -10px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
  }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  .hhwt-menu-icons {
    padding: 2px 0px 3px 22px;
  }

  .hhwtmainmenu {
    font-size: 12px;
  }
  .hhwtmainmenu a {
    display: flex !important;
    padding: 15px 4px !important;
  }

  .hhwt-nav-menu-right .hhwtmainmenu {
    padding-top: 5px;
  }
  .hhwt-nav-menu-right .hhwtmainmenu-notification span {
    padding: 0px 5px;
  }
  .hhwt-nav-menu-right .hhwtmainmenu-notification span.fa-circle {
    right: 4px;
    top: 10px;
  }

  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-nav-menu
    .hhwt-nav-menu-li
    .search {
    max-width: 140px;
  }

  .hhwt-nav-menu-right {
    padding-top: 4px;
    margin-right: 0px;
  }
  .hhwt-nav-menu-right img {
    margin-left: 15px;
  }

  ul.hhwt-nav-menu-right .hhwtmainsubmenu > a {
    display: block !important;
  }
  ul.hhwt-nav-menu .hhwtmainsubmenu,
  ul.hhwt-nav-menu-right .hhwtmainsubmenu {
    margin: 0px;
  }

  ul.hhwt-nav-menu .dropdown-menu > li > a {
    display: block !important;
  }
}

@media (min-width: 768px) {
  #hhwt-mobile-mmenu.navbar-collapse.collapse {
    display: none !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }

  .menucontainer.dropdown-menu {
    border: none;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .hhwt-nav-menu-right {
    padding-top: 0px;
    position: relative;
    width: 100%;
  }
  .hhwt-nav-menu-right .hhwtmainmenu {
    float: none;
    padding-top: 10px;
  }
  .hhwt-nav-menu-right .hhwtmainmenu.hhwt-logged-nav-menu-bag {
    margin-top: 2px;
  }
  .hhwt-nav-menu-right .hhwtmainmenu.menu-item-has-children span img {
    display: none;
  }

  .hhwt-navbar-fluid .hhwt-navbar-container .mobile-mmenu {
    display: none !important;
  }

  .hhwt-nav-menu-right .hhwtmainmenu-notification span {
    padding: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .hhwt-signup-content .form-group .form-control.hideDate {
    opacity: 0;
  }

  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a .social_fb {
    padding: 9px 13px 8px 13px;
  }
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a .social {
    padding: 9px 10px 8px 10px;
  }

  .searchmenu input {
    max-width: 150px;
  }

  .hhwt-nav-menu-right li a {
    padding: 15px !important;
  }
  .hhwt-nav-menu-right li a .fa-shopping-cart {
    margin-right: 11px;
  }
  .hhwt-nav-menu-right li.user-name a {
    padding-bottom: 8px !important;
  }
  .hhwt-nav-menu-right li:not(:last-child) {
    border-bottom: 1px solid #e5e5e5;
  }
  .hhwt-nav-menu-right li.hhwt-menu-listing {
    border-bottom: none;
  }
  .hhwt-nav-menu-right .hhwtmainmenu-notification span {
    margin-right: 12px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .hhwt-nav-menu-right .caret {
    margin-top: 8px;
  }

  .hhwtmainmenu .dropdown-toggle span.caret {
    margin-top: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .hhwt-footer-fluid {
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
  }

  .hhwt-footer-fluid
    .hhwt-footer-container
    .hhwt-footer-categories
    .hhwt-footer-grid {
    margin-bottom: 20px;
  }
  .hhwt-footer-fluid
    .hhwt-footer-container
    .hhwt-footer-categories
    .hhwt-footer-grid
    .links
    li
    a {
    color: #405050;
  }
  .hhwt-footer-fluid
    .hhwt-footer-container
    .hhwt-footer-categories
    .hhwt-footer-grid
    .links
    li
    a:hover {
    text-decoration: none;
  }

  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-download {
    margin-top: 24px;
    float: left;
    width: 100%;
  }
  .hhwt-footer-fluid
    .hhwt-footer-container
    .hhwt-footer-download
    .hhwt-footer-download-btn {
    padding-right: 0px;
  }
  .hhwt-footer-download-btn.text-right {
    float: right;
  }
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-logo {
    text-align: left;
    padding: 0px;
    margin-top: 24px;
  }
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-copyright,
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-agree {
    text-align: left;
    padding: 0px;
  }
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-copyright p,
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-agree a {
    font-size: 12px;
    line-height: 17px;
  }

  .category .featured-fluid {
    height: 300px;
  }
  .category .hhwt-title-container p,
  .category .hhwt-title-container h3 {
    text-align: left;
  }
  .category .bannerHead {
    bottom: 50px;
    padding: 0px 24px !important;
  }
  .category .bannerHead h3 a {
    font-size: 36px !important;
    line-height: 36px !important;
  }
  .category .bannerHead h3 {
    margin-top: 10px;
  }
  .category .bannerHead .bann_cat a {
    background: rgba(255, 255, 255, 0.2);
    font-size: 12px;
    border-radius: 4px;
    padding: 5px 8px;
  }
  .hhwt-mob-menu ul.hhwt-nav-menu > li .hhwtmainsubmenu.active.menu_hk {
    margin-bottom: 8px;
  }
  .removePic {
    margin-left: 0px;
    float: right;
  }

  .article-content table {
    width: 100% !important;
  }
}
@media only screen and (max-width: 449px) {
  ul.hhwt-nav-menu > li ul.dropdown-menu.menucontainer {
    min-width: 300px;
  }

  ul.hhwt-nav-menu > li:nth-child(2) > ul.dropdown-menu.menucontainer input {
    max-width: 165px;
  }

  .hhwt-nav-menu-right li a {
    padding: 15px 7px;
  }
  .hhwt-nav-menu-right .hhwtmainmenu-notification span {
    padding: 0px;
  }
  .hhwt-nav-menu-right .hhwtmainmenu {
    padding: 0px;
  }
}
.simple-small {
  position: relative;
  font-family: "ProximaNova-Semibold";
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  top: 0px;
  transition: all ease 0.3s;
}

.simple-small header {
  padding: 16px 16px 16px 16px;
  min-height: 120px;
  background-color: #fff;
  display: inline-block;
  height: auto;
  border-radius: 0 0 8px 8px;
}
#latest-posts .simple-small header {
  height: 214px;
}
.simple-small h3 {
  font-size: 18px;
  margin-top: 10px;
  line-height: 21px;
  max-height: 132px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 88px;
  max-height: 88px;

  font-family: "ProximaNova-Bold";
  overflow: hidden;
}
.simple-small h3 a {
  color: #333;
  display: inline-block;
}
.simple-small h3 a:hover,
.simple-small h3 a:active,
.simple-small h3 a:focus,
.simple-small h3 a:visited {
  text-decoration: none;
  outline: none;
}
.simple-small .category {
  text-decoration: none;
}
.simple-small .category a {
  color: #00cccc;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.simple-small .category a:hover,
.simple-small .category a:active,
.simple-small .category a:focus,
.simple-small .category a:visited {
  text-decoration: none;
  outline: none;
}
.simple-small .save-bucket,
.save-bucket-login,
.home-save-bucket {
  float: right;
  color: #ff008a;
  font-size: 20px;
  cursor: pointer;
  max-height: 40px;
  text-align: center;
  width: 40px;
  margin-top: 16px;
  margin-right: 16px;
  background: #fff;
  padding-top: 8px;
  border-radius: 50%;
  height: 40px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  position: relative;
}

.save-bucket-login .fa-heart-o:hover::before,
.save-bucket-login:hover .fa-heart-o:before,
.home-save-bucket .fa-heart-o:hover::before,
.home-save-bucket:hover .fa-heart-o::before {
  content: "\f004";
}
.save-bucket-login:hover,
.home-save-bucket:hover {
  box-shadow: none;
}
/* .simple-small .save-bucket:hover, .save-bucket-login:hover, .home-save-bucket:hover {
  -webkit-animation-name: save-bucket;
  -webkit-animation-duration: 0s;
  animation-name: save-bucket;
  animation-duration: 0s;
  font-size: 26px;
  margin-top: -4px;
} */
.simple-small img {
  max-height: 263px;
  min-height: 263px;
}

.modern-big .save-bucket,
.modern-big .save-bucket-login,
.modern-big .home-save-bucket {
  float: right;
  color: #ff008a;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  margin-right: 0px;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  text-shadow: none;
  max-height: 40px;
  padding-top: 8px;
  margin-top: -4px;
}

/* .modern-big .save-bucket:hover, .modern-big .save-bucket-login:hover, .modern-big .home-save-bucket:hover {
  -webkit-animation-name: save-bucket;
  -webkit-animation-duration: 0s;
  animation-name: save-bucket;
  animation-duration: 0s;
  font-size: 26px;
  margin-top: -4px;
} */

.articlesThumbImg::before {
  background: #000 !important;
}

.loaderCnt {
  display: table-cell;
  float: left;
  width: 100%;
  text-align: center;
  vertical-align: middle;
  height: 600px;
  border-bottom: 1px solid #b1e1e2;
  position: relative;
}
.loaderCnt * {
  display: inline-block;
  vertical-align: middle;
}
.loaderCnt span {
  width: 0px;
  height: 100%;
}
.dispBlk {
  /* display: none; */
  min-height: 600px;
  position: relative;
}

.hhwt-navbar-fluid
  .hhwt-navbar-container
  .hhwt-navbar
  .hhwt-nav-menu
  .hhwt-nav-menu-li
  .search
  span
  .fa {
  cursor: pointer;
}

.noRecords {
  display: inline-block;
  margin-top: 70px;
  color: #e20f0f;
  font-size: 15px;
  width: 100%;
}

@-webkit-keyframes save-bucket {
  from {
    font-size: 20px;
    margin-top: 0px;
  }
  to {
    font-size: 26px;
    margin-top: -4px;
  }
}
/* Standard syntax */
@keyframes save-bucket {
  from {
    font-size: 20px;
    margin-top: 0px;
  }
  to {
    font-size: 26px;
    margin-top: -4px;
  }
}
.simple-medium {
  font-family: "ProximaNova-Semibold";
}
.simple-medium header {
  padding: 15px 15px 1px 15px;
  min-height: 100px;
  background-color: #fff;
}
.simple-medium header .category {
  color: #00d3d4;
  font-size: 14px;
}
.simple-medium header h3 {
  color: #495b5b;
  font-size: 20px;
}

.modern-big {
  font-family: "ProximaNova-Semibold";
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  transition: all ease 0.3s;
  position: relative;
  top: 0px;
}
.modern-big:hover {
  top: -10px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
}
.modern-big header {
  position: absolute;
  top: 0;
  width: 100%;
  /* text-shadow: 0px 0px 10px #000, 0px 0px 10px #000, 0 0 0px #fff, 0 0 0px #000000, 0 0 0px #000, 0px 0px 0px #fff, 0px 0px 0px #fff, 0 0 0px #fff; */
  padding: 24px 16px 0px 24px;
}
.modern-big header .category a {
  font-size: 18px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
  font-weight: bold;
  display: inline-block;
  padding: 5px 15px 8px 15px;
}
.modern-big header .category a:hover,
.modern-big header .category a:active,
.modern-big header .category a:focus,
.modern-big header .category a:visited {
  text-decoration: none;
  outline: none;
}
.modern-big header h3 {
  font-size: 27px;
  margin-top: 20px;
  padding-right: 10px;
  width: 87%;
  line-height: 32px;
  font-family: "ProximaNova-Bold";
  color: #fff;
  text-decoration: none;
}
.modern-big header a:hover {
  text-decoration: none;
}
.modern-big header h3 a {
  color: #fff;
  display: inline-block;
}
.modern-big header h3 a:hover,
.modern-big header h3 a:active,
.modern-big header h3 a:focus,
.modern-big header h3 a:visited {
  text-decoration: none;
  outline: none;
}
.modern-big .save-bucket {
  float: right;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding-right: 15px;
  padding-right: 30px;
  max-height: 20px;
  text-align: center;
  margin-right: 10px;
  width: 40px;
}
.modern-big .save-bucket:hover {
  -webkit-animation-name: save-bucket;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0s;
  /* Safari 4.0 - 8.0 */
  animation-name: save-bucket;
  animation-duration: 0s;
  font-size: 26px;
  margin-top: -4px;
}

.destination-categories .modern-medium {
  font-family: "ProximaNova-Bold";
  background-position: 50%;
  height: 200px;
  background-size: cover;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  margin-top: 15px;
  margin-bottom: 15px;
  transition: all ease 0.3s;
  top: 0px;
  position: relative;
}

.destination-categories .modern-medium:hover {
  top: -10px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
}
.destination-categories .modern-medium:hover .dest_overlay {
  height: 80%;
}
.destination-categories .modern-medium header {
  padding-top: 15px;
  /* text-shadow: 0px 0px 10px #000, 0px 0px 10px #000, 0 0 0px #fff, 0 0 0px #000000, 0 0 0px #000, 0px 0px 0px #fff, 0px 0px 0px #fff, 0 0 0px #fff; */
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 14px;
}
.destination-categories .modern-medium header .category {
  font-size: 13px;
}
.destination-categories .modern-medium header .category a {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
}
.destination-categories .modern-medium header .category a:hover,
.destination-categories .modern-medium header .category a:active,
.destination-categories .modern-medium header .category a:focus,
.destination-categories .modern-medium header .category a:visited {
  text-decoration: none;
  outline: none;
}
.destination-categories .modern-medium header h3 {
  font-size: 27px;
  margin-top: 5px;
  line-height: 34px;
}
.destination-categories .modern-medium header h3 a {
  color: #fff;
  line-height: 27px;
}
.destination-categories .modern-medium header h3 a:hover,
.destination-categories .modern-medium header h3 a:active,
.destination-categories .modern-medium header h3 a:focus,
.destination-categories .modern-medium header h3 a:visited {
  text-decoration: none;
  outline: none;
}

.modern-small header {
  font-family: "ProximaNova-Semibold";
  position: absolute;
  top: 0;
  padding: 15px;
}
.modern-small header .category > a {
  color: #fff;
  font-size: 13px;
}
.modern-small header .category > a:hover,
.modern-small header .category > a:active,
.modern-small header .category > a:focus,
.modern-small header .category > a:visited {
  text-decoration: none;
  outline: none;
}
.modern-small header h3 {
  font-size: 20px;
  margin-top: 14px;
}
.modern-small header h3 a {
  color: #fff;
}
.modern-small header h3 a:hover,
.modern-small header h3 a:active,
.modern-small header h3 a:focus,
.modern-small header h3 a:visited {
  text-decoration: none;
  outline: none;
}

.content-title {
  padding: 0px;
  margin-bottom: 23px;
}
.content-title h4 {
  font-family: "ProximaNova-Bold";
  color: #333333;
  padding: 0px;
  font-size: 33px;
  margin-top: 5px;
}
.content-title h1 {
  font-family: "ProximaNova-Bold";
  color: #333333;
  padding: 0px;
  font-size: 33px;
  margin-top: 5px;
}
.content-title .btn-more {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  float: right;
  color: #00cccc;
  border-radius: 20px;
  width: auto;
  line-height: 24px;
  outline: none;
  font-weight: bold;
  transition: all ease 0.3s;
  padding: 10px 0;
  border-radius: 8px;
}
.content-title .btn-more span {
  margin-left: 15px;
  font-weight: bold;
}
.content-title .btn-more:focus,
.content-title .btn-more:hover {
  outline: none;
  background: #00cccc;
  color: #fff;

  padding: 10px;
}

.hhwt-gads {
  padding: 0px;
  margin-bottom: 55px;
  text-align: center;
}
.hhwt-gads img {
  max-width: 723px;
  max-height: 185px;
}

.hhwt-single-post .article-content .hhwt-widget-deals,
.hhwt-widget-deals {
  display: inline-block;
  background-color: #f5f6f8;
  font-family: "ProximaNova-Bold";
  min-height: 280px;
  margin: 10px 0px;
  width: 350px;
  clear: both;
  overflow: hidden;
}
.hhwt-single-post .article-content .hhwt-widget-deals p,
.hhwt-widget-deals p {
  color: #f4a01c;
  font-family: "ProximaNova-Bold";
  font-size: 14px;
  margin: 15px 10px;
}
.hhwt-single-post .article-content .hhwt-widget-deals h3,
.hhwt-widget-deals h3 {
  color: #556666;
  font-size: 24px;
  margin-top: 0px;
  line-height: 30px;
  margin: 10px;
}
.hhwt-single-post .article-content .hhwt-widget-deals .btn-claim-deals,
.hhwt-widget-deals .btn-claim-deals {
  background: #f4a01c;
  padding: 10px 40px;
  border-color: #f4a01c;
  border: 1px solid #f4a01c;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  margin: 30px 40px 15px;
  font-family: "ProximaNova-Semibold";
}

.hhwt-single-post .article-content .hhwt-widget-city,
.hhwt-widget-city {
  display: inline-block;
  background-color: #f5f6f8;
  font-family: "ProximaNova-Semibold";
  max-height: 280px;
  width: 350px;
  margin: 10px 10px 10px 0px;
  clear: both;
  overflow: hidden;
}
.hhwt-single-post .article-content .hhwt-widget-city p,
.hhwt-widget-city p {
  margin-top: 5px;
  font-family: "ProximaNova-Semibold";
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-rating img,
.hhwt-widget-city .hhwt-widget-rating img {
  margin-top: 19px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-points h3,
.hhwt-widget-city .hhwt-widget-points h3 {
  color: #556666;
  font-size: 20px;
  margin-top: 30px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-points p,
.hhwt-widget-city .hhwt-widget-points p {
  color: #556666;
  font-size: 14px;
  display: flex;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-points p img,
.hhwt-widget-city .hhwt-widget-points p img {
  padding-left: 10px;
  width: 30%;
  height: 11px;
  margin-top: 3px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-location,
.hhwt-widget-city .hhwt-location {
  margin: 10px 0px;
  text-align: center;
}
.hhwt-single-post
  .article-content
  .hhwt-widget-city
  .hhwt-location
  .fa-map-marker,
.hhwt-widget-city .hhwt-location .fa-map-marker {
  color: #00cccb;
  font-size: 25px;
  margin-top: 5px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-text,
.hhwt-widget-city .hhwt-widget-text {
  margin: 10px 0px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-text p,
.hhwt-widget-city .hhwt-widget-text p {
  font-size: 14px;
  color: #6e7376;
  margin-bottom: 3px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-time,
.hhwt-widget-city .hhwt-time {
  text-align: center;
  margin: 10px 0px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-time .fa-clock-o,
.hhwt-widget-city .hhwt-time .fa-clock-o {
  color: #00cccb;
  font-size: 25px;
  margin-top: 5px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-time,
.hhwt-widget-city .hhwt-widget-time {
  margin: 10px 0px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-time p,
.hhwt-widget-city .hhwt-widget-time p {
  font-size: 14px;
  color: #6e7376;
  margin-bottom: 3px;
}
.hhwt-single-post .article-content .hhwt-widget-city .hhwt-widget-heart,
.hhwt-widget-city .hhwt-widget-heart {
  background: #f2007b;
  border-radius: 50%;
  width: 47px;
  height: 45px;
  margin-left: 19px;
}
.hhwt-single-post
  .article-content
  .hhwt-widget-city
  .hhwt-widget-heart
  .fa-heart-o,
.hhwt-widget-city .hhwt-widget-heart .fa-heart-o {
  color: #fff;
  font-size: 20px;
  margin-top: 13px;
  margin-left: -1px;
}
.hhwt-single-post
  .article-content
  .hhwt-widget-city
  .hhwt-widget-detail
  .btn-city-detail,
.hhwt-widget-city .hhwt-widget-detail .btn-city-detail {
  background: #00cccb;
  padding: 10px 30px;
  border-color: #00cccb;
  border: 1px solid #00cccb;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  margin: 5px 20px 18px;
}

.category .featured-fluid {
  background-size: cover;
  min-height: 650px;
  background-position: 50%;
  position: relative;
}

.bannerHead {
  position: absolute;
  bottom: 70px;
  width: 100%;
  left: auto;
}

.bannerHead .bann_cat a {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  padding: 5px 10px;
}
.bannerHead h3 {
  margin-top: 30px;
}
.bannerHead h3 a {
  color: #ffffff !important;
  font-size: 48px !important;
  line-height: 58px;
}
.bannerHead .bann_cat a:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 1199px) and (min-width: 1024px) {
  .hhwt-nav-menu-right .hhwtmainmenu {
    padding-top: 0;
  }
  .nav.hhwt-nav-menu-right > li.hhwtmainmenu > a .caret {
    margin-top: 22px;
  }
}

@media (max-width: 1119px) and (min-width: 768px) {
  .profile_page .hhwt-author-posts {
    width: 100%;
    padding-left: 0px !important;
  }
  .tripDates {
    font-size: 14px;
  }
  .tripDates {
    font-weight: 24px;
  }

  .hhwt-footer-fluid
    .hhwt-footer-container
    .hhwt-footer-categories
    .hhwt-footer-grid
    .titles,
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow h4,
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-download h4 {
    font-size: 16px;
  }

  .hhwt-footer-fluid
    .hhwt-footer-container
    .hhwt-footer-categories
    .hhwt-footer-grid
    ul
    li
    a {
    line-height: 19px;
    font-size: 13px;
  }

  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    padding-top: 3px;
    font-size: 17px;
  }

  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a .social_fb {
    padding: 0px;
  }
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-download a img {
    width: 100%;
  }

  .hhwt-nav-right-container {
    float: right;
    padding: 0px;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar {
    display: inherit;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar .navbar-collapse {
    float: left;
    width: 62%;
    margin-left: 40px;
    margin-top: 3px;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar .hhwt-nav-menu {
    display: inherit;
  }
  .navbar-nav > li > a {
    line-height: inherit;
    padding: 12px 7px !important;
  }
  .hhwt-menu-icons {
    padding: 1px 0px 3px 30px;
  }
  .nav > li.hhwtmainmenu > a .caret {
    margin-top: 10px;
    margin-left: 4px;
  }

  .hhwt-mob-search {
    float: left;
    width: auto;
    margin-top: 23px;
  }
  .hhwt-nav-menu-right {
    width: auto;
    display: inherit;
    position: inherit;
  }
  .hhwt-navbar-fluid .hhwt-navbar-container .navbar-brand {
    margin-top: 2px;
  }

  .articlesBigThumbImg {
    height: 340px !important;
  }
  .articlesThumbImg,
  .defaultImg {
    height: 150px !important;
  }

  #latest-posts .simple-small header {
    height: 195px !important;
  }
  #latest-posts .simple-small header .authrDet {
    margin-top: 18px !important;
  }

  .custom-container {
    padding: 0 15px !important;
  }
  .list-grid-posts .custom-container,
  .hhwt-related-wrapper .custom-container {
    padding-bottom: 10px !important;
  }
  .container {
    padding: 0 7px !important;
  }
  .sliderArws .carousel-control.right {
    right: 0px !important;
    top: 45.5% !important;
  }
  .sliderArws .carousel-control.left {
    left: 4px !important;
    top: 45.5% !important;
  }
  .destination-categories {
    margin-bottom: 20px !important;
  }
  .list-grid-posts .item,
  .hhwt-related-wrapper .item {
    margin-top: 15px !important;
  }
  #destination-carousel .carousel-inner > .item {
    margin-top: 0px !important;
  }
  .simple-small h3 {
    font-size: 14px;
    line-height: 17px;
    margin-top: 6px;
    margin-bottom: 3px;
  }
  .list-grid-posts .item .col-sm-3,
  .hhwt-related-wrapper .item .col-sm-3 {
    padding: 0 8px !important;
  }

  .hhwt-fluid .banner-content {
    margin-top: 330px !important;
  }
  .hhwt-fluid .banner-content .hhwt-btns {
    width: 40% !important;
  }
  .hhwt-fluid .banner-content .hhwt-btns {
    width: 40% !important;
  }
  .hhwt-fluid .banner-content .stickyMenu .hhwt-btns {
    width: 44% !important;
  }
  .hhwt-destination-container .modern-small img {
    max-height: 300px !important;
    min-height: 300px !important;
  }
  .hhwt-nested-posts .col-md-3 {
    padding: 0 8px !important;
  }

  .srchTitle {
    width: 88%;
  }

  .hhwt-nav-menu-right li:not(:last-child) {
    border-bottom: 0px;
    margin-top: 4px;
  }
  ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer {
    position: absolute;
    background: #fff;
    top: 53px;
    padding: 13px 0;
    /* min-width: max-content; */
  }
  ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer li {
    margin-top: 0px;
  }
  ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer li a {
    color: #00cccc;
    padding: 0;
  }

  .hhwt-author-container .hhwt-author-details {
    width: 0px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    left: 0px;
    padding-top: 16px !important;
    box-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .postTabCnt {
    width: 92%;
    float: right;
  }
  .postTabCnt ul li {
    margin: 0 13px;
  }

  .hhwt-author-container {
    position: relative;
  }
  .menu-opener {
    display: block;
    z-index: 101;
  }
  .hhwt-author-container .hhwt-author-details.active {
    width: 270px;
    height: auto;
    z-index: 100;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .hhwt-gads {
    padding: 0px 15px;
    margin-bottom: 30px;
  }
  .hhwt-gads img {
    max-width: 100%;
  }

  .container.content-title {
    padding: 0px 15px;
  }
  .content-title h4 {
    font-size: 24px;
  }
  .content-title h1 {
    font-size: 24px;
  }
  .content-title .btn-more {
    padding: 0px;
  }
  .content-title .btn-more:hover {
    background: none;
    color: #00cccc;
    padding: 0px;
  }

  .destination-categories .modern-medium {
    height: 168px;
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .destination-categories .modern-medium header h3 {
    font-size: 24px;
  }

  .modern-big img {
    max-height: 420px;
    min-height: 420px;
  }
  .modern-big .save-bucket {
    padding-right: 0px;
  }

  .hhwt-single-post .article-content .hhwt-widget-city,
  .hhwt-widget-city {
    margin: 10px auto;
    display: block;
  }

  .hhwt-single-post .article-content .hhwt-widget-deals,
  .hhwt-widget-deals {
    margin: 10px auto;
    display: block;
    text-align: center;
  }
  .hhwt-single-post .article-content .hhwt-widget-deals p,
  .hhwt-single-post .article-content .hhwt-widget-deals h3,
  .hhwt-widget-deals p,
  .hhwt-widget-deals h3 {
    text-align: left;
  }

  .category .featured-fluid {
    min-height: 220px;
    position: relative;
  }
  .simple-small h3 {
    font-size: 15px;
    line-height: 18px;
  }
  .destination-categories .modern-medium header .category a {
    font-size: 16px;
    line-height: 20px;
  }

  .hhwt-footer-grid ul {
    display: flex;
    flex-direction: column;
  }

}
@media only screen and (max-width: 449px) {
  .simple-small img {
    max-height: 165px;
    min-height: 165px;
  }

  .destination-categories .modern-medium {
    height: 165px;
  }
  .destination-categories .modern-medium header h3 {
    font-size: 24px;
  }

  .modern-big img {
    max-height: 323px;
    min-height: 323px;
  }
}
@media only screen and (max-width: 400px) {
  .content-title h4 {
    font-size: 18px;
    margin-top: 6px;
  }
  .content-title h1 {
    font-size: 18px;
    margin-top: 6px;
  }
  .content-title .btn-more {
    font-size: 13px;
    padding: 7px 11px;
  }
  .content-title .btn-more span {
    margin-left: 5px;
  }
}
body.page-template-homepage-template {
  background-color: #f5f6f8;
}

#featured-carousel .featured-fluid {
  margin-bottom: 0px;
  min-height: 560px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#featured-carousel .featured-fluid .featured-title {
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
  clear: both;
  position: absolute !important;
  bottom: 72px;
}
#featured-carousel .featured-fluid .featured-title a {
  font-family: "ProximaNova-Bold";
  color: #fff;
}

#featured-carousel .featured-fluid .featured-title a:hover,
#featured-carousel .featured-fluid .featured-title a:active,
#featured-carousel .featured-fluid .featured-title a:focus,
#featured-carousel .featured-fluid .featured-title a:visited {
  text-decoration: none;
}
#featured-carousel .featured-fluid .featured-title span {
  margin-bottom: 0px;
  font-size: 13px;
}
#featured-carousel .featured-fluid .featured-title h1 {
  margin-top: 13px;
}
#featured-carousel .featured-fluid .featured-title h1 a {
  font-size: 40px;
  /* text-shadow: 2px 5px 3px #000; */
}
#featured-carousel .featured-fluid .featured-title .col-sm-6 {
  padding: 0px;
}

.hhwt-fetured-details-selection {
  display: flex;
  position: absolute;
  padding-right: 0px;
  padding-left: 0px;
  right: 0px;
  width: 100%;
}

.hhwt-fetured-details-selection .hhwt-home-btn-go {
  font-size: 13px;
  border-radius: 50%;
  padding: 18px 14px;
  color: #fff;
  background-color: #00cccb;
  border: 8px solid #1fa8af;
  margin-right: 10px;
  font-family: "ProximaNova-Semibold";
  outline: none;
}
.hhwt-fetured-details-selection .hhwt-home-btn-go span {
  font-size: 20px;
  margin-bottom: 4px;
}
.hhwt-fetured-details-selection .hhwt-home-btn-go:focus,
.hhwt-fetured-details-selection .hhwt-home-btn-go:hover {
  outline: none;
}
.hhwt-fetured-details-selection .hhwt-home-btn-next {
  border-radius: 50%;
  font-size: 13px;
  padding: 18px 11px;
  color: #615c5c;
  background-color: #fff;
  border: 8px solid #e6e0e0;
  font-family: "ProximaNova-Semibold";
  outline: none;
}
.hhwt-fetured-details-selection .hhwt-home-btn-next span {
  font-size: 20px;
  margin-bottom: 4px;
}
.hhwt-fetured-details-selection .hhwt-home-btn-next:focus,
.hhwt-fetured-details-selection .hhwt-home-btn-next:hover {
  outline: none;
}

.hhwt-fetured-selection-container {
  position: absolute;
  top: 43%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.home-featured-container {
  padding: 0;
  margin: 0px auto 0px;
}
.home-featured-container img {
  width: 100%;
}
.home-featured-container .item {
  margin: 0px -15px;
}
.home-featured-container .item .col-sm-6 img {
  max-height: 385px;
  min-height: 385px;
}
.home-featured-container .item .col-md-3 img {
  max-height: 230px;
  min-height: 230px;
}

.destination-categories {
  padding: 0px;
  margin-bottom: 0px;
}
#destination-carousel .carousel-inner > .item {
  margin-top: 10px;
}

.destination-categories .item > div:first-child .col-sm-4,
.destination-categories .item > div:nth-child(4) .col-sm-4 {
  padding-left: 0px;
}

.destination-categories .item > div:last-child .col-sm-4,
.destination-categories .item > div:nth-child(3) .col-sm-4 {
  padding-right: 0px;
}

.list-grid-posts,
.hhwt-related-wrapper {
  padding: 0px;
}
.list-grid-posts .custom-container,
.hhwt-related-wrapper .custom-container {
  margin-bottom: 20px;
}
.list-grid-posts .item,
.hhwt-related-wrapper .item {
  margin: 0px -15px;
}
.list-grid-posts .item img,
.hhwt-related-wrapper .item img {
  width: 100%;
}
/* ivideo player safari issue */
@media screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 0) {
  .ivs-overlay-carouselhorz.ivs-overlay-carouselcontainer {
    max-width: 100% !important;
    width: 100% !important;
    /* display: none; */
  }
}

@media only screen and (max-width: 991px) {
  .home-featured-container .featured-small-thumb,
  .list-grid-posts .featured-small-thumb {
    margin-right: 0px;
    padding-right: 10px;
  }
}
.carousel-control.left {
  background: none;
  text-align: left;
  top: 44%;
  max-height: 40px;
  max-width: 35px;
  opacity: 1;
}

.carousel-control.right {
  background: none;
  text-align: right;
  top: 44%;
  max-height: 40px;
  max-width: 35px;
  opacity: 1;
  margin-right: 10px;
}

.carousel-control.left span {
  /* padding: 15px 20px;
  background-color: rgba(53, 52, 52, 0.64);
  border-radius: 0px 25px 25px 0px; */
  height: 40px;
  width: 40px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  font-size: 20px;
  line-height: 38px;
}

.carousel-control.right span {
  /* padding: 15px 20px;
  background-color: rgba(53, 52, 52, 0.64);
  border-radius: 25px 0px 0px 25px;
  font-size: 25px;
  margin-left: -4px; */
  height: 40px;
  width: 40px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  font-size: 20px;
  line-height: 38px;
}

.custom-container {
  margin: auto;
}

@media (min-width: 768px) {
  .custom-container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .custom-container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .custom-container {
    width: 1170px;
  }
}
@media (max-width: 767px) {
  .list-grid-posts {
    padding-left: 0px;
    padding-right: 0px;
  }
  .list-grid-posts .related-title {
    padding-left: 15px;
    padding-right: 15px;
  }

  .related-article .custom-container {
    padding-left: 15px;
  }
  .related-article h4 {
    margin: 0px;
  }

  .home-featured-container .carousel {
    padding: 0px 15px;
  }
  .adsIframe {
    height: 280px !important;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  #featured-carousel .featured-fluid {
    min-height: 300px;
    margin-bottom: 0px;
    padding: 0px;
    background-position: 45%;
  }
  #featured-carousel .featured-fluid .featured-title {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 0px;
    text-align: center;
  }
  #featured-carousel .featured-fluid .featured-title .col-sm-6 {
    padding: 0px 15px;
  }
  #featured-carousel .featured-fluid .featured-title h1 a {
    font-size: 27px;
  }

  .hhwt-fetured-selection-container {
    top: auto;
  }

  .hhwt-fetured-details-selection {
    bottom: 60px;
    transform: translate(-50%, -50%);
    right: auto;
  }

  .list-grid-posts .item,
  .hhwt-related-wrapper .item {
    margin: 0px;
  }
  .list-grid-posts .item .col-md-3:nth-child(2n + 1),
  .hhwt-related-wrapper .item .col-md-3:nth-child(2n + 1) {
    padding-right: 7.5px;
  }
  .list-grid-posts .item .col-md-3:nth-child(2n),
  .hhwt-related-wrapper .item .col-md-3:nth-child(2n) {
    padding-left: 7.5px;
  }

  .home-featured-container .item {
    margin: 0px;
  }

  .home-featured-container .carousel {
    padding: 0px;
  }

  .home-featured-container .item .col-xs-6:nth-child(2n + 1) {
    padding-right: 7.5px;
  }
  .home-featured-container .item .col-xs-6:nth-child(2n) {
    padding-left: 7.5px;
  }

  .home-featured-container {
    margin-bottom: 30px;
  }
  .list-grid-posts {
    margin-bottom: 15px;
  }

  .list-grid-posts .custom-container,
  .hhwt-related-wrapper .custom-container {
    margin-bottom: 20px;
  }

  #featured-carousel .featured-fluid {
    height: 300px;
    background-position: center center;
  }
  .hhwt-fetured-selection-container {
    top: 64% !important;
    width: 98%;
  }
  .carousel-control.right {
    margin-right: 0px;
  }
  .carousel-control.left span,
  .carousel-control.right span {
    width: 26px;
    height: 26px;
    font-size: 18px;
    line-height: 27px;
  }

  #featured-carousel .carousel-indicators {
    bottom: 18px;
  }
  .carousel-indicators li {
    width: 6px;
    height: 6px;
  }
  .carousel-indicators .active {
    width: 8px;
    height: 8px;
  }

  #featured-carousel .carousel-inner > .item {
    transition: transform 0.3s linear;
  }

  #featured-carousel .featured-fluid .featured-title {
    padding-bottom: 0px;
    bottom: 48px;
    text-align: left;
  }
  .custom-container.mb_home_page {
    width: 88%;
  }
  #featured-carousel .featured-fluid .featured-title .col-sm-6 {
    padding: 0px;
  }
  #featured-carousel .featured-fluid .featured-title {
    width: 72%;
  }
  #featured-carousel .featured-fluid .featured-title h1 a {
    font-size: 34px;
    line-height: 36px;
  }
  #featured-carousel .featured-fluid .featured-title a.category {
    background: rgba(255, 255, 255, 0.2);
    font-size: 12px;
    line-height: 18px;
    padding: 4px 15px;
  }

  .content-title h4 {
    font-size: 24px;
    width: auto;
  }
  .content-title h1 {
    font-size: 24px;
    width: auto;
  }
  .hhwt-related-wrapper.home_page {
    padding-top: 0px;
  }
  .content-title .btn-more {
    font-size: 14px;
    line-height: 20px;
  }
  .articlesThumbImg {
    height: 129px !important;
  }
  .pro_bucket_list .articlesThumbImg {
    height: 229px !important;
  }
  .profile_page .hhwt-author-container .simple-small h3 a {
    font-size: 16px !important;
    line-height: 19px;
    margin-top: 0px;
  }
  .profile_page .hhwt-author-container .simple-small h3 {
    margin-top: 0px;
    min-height: 55px;
    max-height: 55px;
  }
  .prf_star_rat {
    font-size: 12px;
    line-height: 12px;
    margin-top: 1px;
  }

  .profile_page
    .hhwt-author-container
    .hhwt-author-posts
    .hhwt-author-posts-grid {
    padding: 14px 0 0;
  }
  .profile_page .hhwt-author-container .simple-small header {
    min-height: 152px;
    height: 164px;
  }

  .prf_star_rating {
    background-image: url("../images/star_rating_grey_mb.png");
    width: 60px;
    height: 12px;
    background-position-y: 0px;
  }
  .prf_star_rating span {
    background-image: url("../images/star_rating_yellow_mb.png");
    top: 0px;
  }

  .food_tag {
    font-size: 12px;
    line-height: 14px;
  }
  .pro_bucket_list .readTxt {
    font-size: 10px;
    margin-top: 4px;
  }

  .sliderArws .carousel-control.right {
    right: 5px;
  }
  .sliderArws .carousel-control.left {
    left: 6px;
  }
  #latest-posts .simple-small header {
    height: auto;
  }
  #latest-posts .simple-small header .authrDet {
    margin-top: 10px;
  }
  .simple-small .category a {
    font-size: 10px;
    line-height: 14px;
  }
  .simple-small h3 {
    margin-top: 7px;
    font-size: 14px;
  }

  .authrDet {
    margin-top: 10px;
  }
  .authrDet a img {
    width: 16px !important;
    height: 16px !important;
  }
  .authrDet a {
    font-size: 10px;
    line-height: 14px;
  }

  .list-grid-posts .carousel-inner {
    overflow: inherit !important;
    padding-bottom: 15px;
  }
  .list-grid-posts .carousel-inner > .item {
    margin-top: 10px !important;
  }
  .hhwt-related-wrapper .content-title.mrgnbtm {
    margin-bottom: 10px !important;
  }
  .list-grid-posts .custom-container,
  .hhwt-related-wrapper .custom-container {
    margin-bottom: 0px !important;
  }
  .hhwt-footer-fluid {
    padding: 24px;
    text-align: left;
  }
  .hhwt-footer-fluid
    .hhwt-footer-container
    .hhwt-footer-categories
    .hhwt-footer-grid
    .titles {
    font-size: 16px;
    margin-bottom: 6px;
  }
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow h4,
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-download h4 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .hhwt-footer-fluid
    .hhwt-footer-container
    .hhwt-footer-categories
    .hhwt-footer-grid
    ul
    li {
    float: left;
    margin-right: 10px;
  }
  .hhwt-footer-follow .col-lg-4 {
    width: auto;
    margin-left: 0px;
  }
  .hhwt-footer-fluid .hhwt-footer-container .hhwt-footer-follow a .social_fb {
    padding: 0px;
    padding-top: 0px;
    width: 40px;
    height: 40px;
    text-align: center;
    padding-top: 0px;
    font-size: 20px;
    line-height: 20px;
  }

  .mb_rght_div .hhwt-user-menu .nav > li.hhwt-trg-login a {
    background: #ffff;
    border-radius: 8px;
    color: #00cccc;
    width: 72px;
    height: 36px;
    text-align: center;
    font-size: 14px !important;
    display: inline-block;
    padding-top: 7px !important;
  }
  .destination-categories {
    margin-bottom: 15px;
  }

  #destination-carousel .carousel-control.left,
  #destination-carousel .carousel-control.right {
    top: 48.4%;
  }
}

@media only screen and (max-width: 449px) {
  #featured-carousel .featured-fluid .featured-title h1 a {
    font-size: 22px;
    line-height: 26px;
  }
}
.hhwt-single-post .article-content {
  max-width: 800px;
  padding: 0px 0px 20px 0;
  word-wrap: break-word;
  font-size: 18px;
  line-height: 30px;
  font-family: "Open Sans", sans-serif;
  color: #333;
}
.hhwt-single-post .article-content p {
  font-family: "Open Sans", sans-serif;
  margin-top: 20px;
  font-size: 18px;
  color: #333;
  line-height: 30px;
}
.hhwt-single-post .article-content p a {
  color: #00cccb;
  font-weight: bold;
}
.hhwt-single-post .article-content p a:hover,
.hhwt-single-post .article-content p a:active,
.hhwt-single-post .article-content p a:focus,
.hhwt-single-post .article-content p a:visited {
  text-decoration: none;
  color: #00cccb;
  outline: none;
}
.hhwt-single-post .article-content p a img {
  max-width: 100%;
  height: auto;
}
/* .hhwt-single-post .article-content p em {
  font-style: normal;
  font-size: 15px;
} */
.hhwt-single-post .article-content i strong,
.hhwt-single-post .article-content em strong {
  font-style: unset;
}

.hhwt-single-post .article-content p em a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.hhwt-single-post .article-content h1 {
  font-size: 32px;
}
.hhwt-single-post .article-content h2 {
  font-size: 24px;
}
.hhwt-single-post .article-content h3 {
  font-size: 18.72px;
}
.hhwt-single-post .article-content h4 {
  font-size: 16px;
}
.hhwt-single-post .article-content h5 {
  font-size: 13.28px;
}
.hhwt-single-post .article-content h6 {
  font-size: 10.72px;
}
.hhwt-single-post .article-content h5 {
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  margin: 40px 0px 5px;
  font-weight: bold;
}
.hhwt-single-post .article-content figure {
  max-width: 728px;
}
.hhwt-single-post .article-content figure img {
  margin: 15px 0px 0px;
  max-width: 100%;
  height: auto;
}
.hhwt-single-post .article-content figure figcaption {
  display: none;
}
.hhwt-single-post .article-content a {
  color: #00cccb;
}
.hhwt-single-post .article-content a:hover,
.hhwt-single-post .article-content a:active,
.hhwt-single-post .article-content a:focus,
.hhwt-single-post .article-content a:visited {
  text-decoration: none;
  color: #00cccb;
}
.hhwt-single-post .article-content a img {
  max-width: 100%;
  height: auto;
}
.hhwt-single-post .article-content ol,
.hhwt-single-post .article-content ul {
  padding-left: 16px;
}
.hhwt-single-post .article-content ol li {
  list-style: decimal;
  padding: 5px 0px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 30px;
}

.hhwt-single-post .article-content ul li {
  list-style: disc;
  padding: 5px 0px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 30px;
}
.hhwt-single-post .article-content div em {
  font-style: normal;
  font-size: 15px;
  display: block;
  margin-top: 10px;
}
.hhwt-single-post .article-content div em a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.hhwt-single-post .article-content > .nc_socialPanel {
  display: none !important;
}
.hhwt-single-post img {
  max-width: 100%;
  height: auto;
}

.hhwt-fluid {
  padding-left: 0px;
  padding-right: 0px;
}
.hhwt-fluid .article-banner {
  position: relative;
  min-height: 650px;
  max-height: 650px;
  background: #000;
}
.hhwt-fluid .article-banner:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 420px;
  max-height: 420px;
  opacity: 0.7;
  background-repeat: no-repeat;
  background-size: cover;
}
.hhwt-fluid .article-banner .custom-container {
  z-index: 2;
  position: relative;
}
.hhwt-fluid .hhwt-credit-text .hhwt-credit-img {
  margin: auto;
}
.hhwt-fluid .banner-content {
  margin-top: 370px;
  padding: 0px;
}
.hhwt-fluid .banner-content p {
  font-size: 14px;
  float: left;
  width: 100%;
}
.hhwt-fluid .banner-content p.category_links a {
  color: #fff;
  outline: none;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  font-family: "Open Sans", sans-serif;
  padding: 3px 12px 6px 12px;
  font-size: 18px;
  line-height: 24px;
  margin-right: 10px;
  font-weight: bold;
  float: left;
  margin-bottom: 5px;
  white-space: nowrap;
}

.hhwt-fluid .banner-content p.category_links a:hover {
  text-decoration: none;
  background: rgba(0, 0, 0, 0.5);
}

.hhwt-fluid .banner-content h3 {
  font-size: 48px;
  font-family: "ProximaNova-Bold";
  color: #fff;
  line-height: 58px;
  margin-top: 25px;
}
.hhwt-fluid .banner-content .banner-avatar {
  display: flex;
  margin: 20px 0px 20px 0;
  width: 60%;
  float: left;
}
.hhwt-fluid .banner-content .hhwt-btns {
  float: right;
  width: 24%;
  margin-top: 16px;
}
.hhwt-fluid .banner-content .banner-avatar p {
  margin-top: 1px;
  padding-left: 10px;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-weight: bold;
}
.hhwt-fluid .banner-content .banner-avatar p a {
  color: #fff;
}

.hhwt-fluid .banner-content .banner-avatar img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.hhwt-fluid .banner-content .hhwt-save-button {
  border: none;
  padding: 7px 10px 5px;
  color: #fff;
  font-size: 16px;
  margin-right: 16px;
  line-height: 19px;
  font-family: "ProximaNova-Semibold";
  border-radius: 8px;
  color: #f1007b;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  float: left;
  transition: all ease 0.3s;
}
.hhwt-fluid .banner-content .hhwt-save-button i {
  padding-right: 2px;
  font-weight: bold;
}

.hhwt-fluid .banner-content .hhwt-save-button:hover .fa-heart-o::before {
  content: "\f004";
}
.hhwt-fluid .banner-content .fb-btn {
  background: #00cccc;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  border: #00cccc;
  margin: 0px;
  font-family: "ProximaNova-Bold";
  padding: 0;
  font-size: 16px;
  line-height: 20px;
  margin-right: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  padding-top: 8px;
  float: left;
  border: 1px solid #00cccc;
  transition: all ease 0.3s;
}
.hhwt-fluid .banner-content .tw-btn {
  background: #00cccc;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  border: #00cccc;
  margin: 0px;
  font-family: "ProximaNova-Bold";
  padding: 0;
  font-size: 16px;
  line-height: 19px;
  margin-right: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  display: inline-block;
  width: 32px;
  height: 32px;
  text-align: center;
  vertical-align: middle;
  padding-top: 8px;
  float: left;
  border: 1px solid #00cccc;
  transition: all ease 0.3s;
}
.hhwt-fluid .banner-content .mail_icon {
  background: #00cccc;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  margin: 0px;
  font-family: "ProximaNova-Bold";
  padding: 7px 0px 7px;
  font-size: 16px;
  line-height: 19px;
  margin-right: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  width: 32px;
  height: 32px;
  border: 1px solid #00cccc;
  float: left;
  transition: all ease 0.3s;
}

.hhwt-fluid .banner-content .link_icon {
  width: 32px;
  display: inline-block;
  height: 32px;
  background: #00cccc url("../images/ic_link.svg") center center no-repeat;
  border-radius: 50%;
  text-align: center;
  position: relative;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  float: left;
  border: 1px solid #00cccc;
  transition: all ease 0.3s;
  color: #fff;
}

.hhwt-fluid .banner-content .link_icon:hover {
  background-image: url("../images/ic_link_hover.svg");
}
.hhwt-fluid .banner-content .link_icon img {
  width: 100%;
}

.hhwt-fluid .banner-content .fb-btn:hover,
.hhwt-fluid .banner-content .tw-btn:hover,
.hhwt-fluid .banner-content .mail_icon:hover,
.hhwt-fluid .banner-content .link_icon:hover {
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  color: #00cccc;
}

.hhwt-related-container .content-title .btn-more {
  display: none;
}

.hhwt-related-wrapper {
  background-color: #fff;
  padding: 30px 0px 0px;
}

@media only screen and (max-width: 767px) {
  .hhwt-fluid .banner-content {
    margin-top: 60px;
    text-align: center;
    padding: 0px 15px 36px 15px;
    z-index: 1;
  }
  .stickyMenu {
    padding: 3px 10px 7px;
  }
  .hhwt-fluid .banner-content .banner-avatar p {
    font-size: 12px;
    line-height: 17px;
  }
  .hhwt-single-post .article-content p,
  .hhwt-single-post .article-content ul li,
  .hhwt-single-post .article-content ol li,
  .hhwt-single-post .article-content {
    font-size: 16px;
    line-height: 24px;
  }
  .hhwt-single-post .article-content h5 {
    font-size: 24px;
    line-height: 26px;
  }

  .hhwt-single-post .article-content p.article-tag a {
    font-size: 12px;
    line-height: 16px;
    border-radius: 4px;
    padding: 2px 10px 4px 10px;
  }
  .hhwt-single-post .article-content p.article-tag {
    font-size: 14px;
    line-height: 19px;
  }

  .hhwt-single-post .hhwt-related-wrapper {
    padding-bottom: 30px;
    padding-top: 0px;
  }
  .hhwt-single-post .hhwt-related-wrapper .carousel-inner > .item {
    margin-top: 10px;
    padding-bottom: 15px;
  }

  .hhwt-fluid .banner-content h3 {
    font-size: 27px;
    line-height: 36px;
  }
  .hhwt-fluid .banner-content .banner-avatar {
    display: inline-flex;
  }
  .hhwt-fluid .article-banner {
    min-height: 420px;
    max-height: 420px;
  }
  .hhwt-fluid .article-banner:after {
    min-height: 420px;
    max-height: 420px;
  }

  .hhwt-single-post .article-content {
    padding: 0px 15px;
    overflow-x: hidden;
  }
  .hhwt-single-post .article-content figure {
    max-width: 100%;
  }
  .stickyMenu .hhwt-btns {
    width: inherit !important;
    margin-top: 5px;
    float: right !important;
  }
  .hhwt-fluid .banner-content .fb-btn,
  .hhwt-fluid .banner-content .tw-btn {
    margin-right: 10px !important;
  }

  .hhwt-fluid .article-banner .custom-container {
    position: inherit !important;
  }
  .hhwt-fluid .banner-content {
    position: absolute;
    bottom: 0px;
    padding-bottom: 0px;
    text-align: left;
  }
  .hhwt-fluid .article-banner {
    position: initial;
  }

  .hhwt-fluid .banner-content p {
    display: inline-block;
    width: 100%;
  }
  .hhwt-fluid .banner-content p.category_links a {
    font-size: 12px;
    line-height: 17px;
    float: left;
    margin-bottom: 5px;
    border-radius: 4px;
    padding: 2px 10px 5px 10px;
  }
  .hhwt-fluid .banner-content h3 {
    margin-top: 0px;
  }
  .hhwt-fluid .banner-content .banner-avatar {
    width: 100%;
  }
  .hhwt-fluid .banner-content .banner-avatar img {
    width: 20px;
    height: 20px;
  }

  #hhwt-modal-save-to-bucket {
    padding: 15px !important;
  }
  .save-bucket-modal-dialog,
  .hhwt-signup-content {
    margin: 40px auto !important;
  }
  .save_buck_art_img {
    width: 72px;
    height: 72px;
  }
  .save-bucket-modal-content
    .save-modal-body
    .save-bucket-article
    .save_buck_art_title
    p
    a {
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 4px;
  }
  .save-bucket-modal-content
    .save-modal-body
    .save-bucket-article
    .save_buck_art_title
    h3 {
    margin-top: 4px !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
  .save-bucket-modal-content .save-modal-body .save-to-bucket {
    padding-top: 24px !important;
  }
  .save-bucket-modal-content .save-modal-body .save-to-bucket p {
    font-size: 16px !important;
    margin-bottom: 10px !important;
  }

  .mb_rght_div .hhwt-nav-menu-right li:not(:last-child) {
    border-bottom: 0px;
  }
  .hhwt-mob-menu .hhwtmainmenu.avatar {
    margin-top: 4px;
  }
  .hhwt-mob-menu .hhwt-nav-menu-right img {
    width: 22px;
    height: 22px;
    margin-left: 0px;
    margin-top: 0px;
  }
  .hhwt-mob-menu .hhwt-nav-menu-right .caret {
    margin-top: 0px;
  }
  ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer {
    position: absolute;
    background: #fff;
    padding-top: 0px;
  }
  ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer li a {
    color: #00cccc;
    padding: 8px 14px !important;
  }

  .stickyMenu .copiedLinkText {
    top: -42px;
    bottom: inherit;
    right: 0px;
    left: inherit;
    height: 35px !important;
  }
}
@media only screen and (max-width: 450px) {
  .hhwt-single-post .article-content .hhwt-widget-city,
  .hhwt-single-post .article-content .hhwt-widget-deals {
    width: 100%;
  }

  .hhwt-widget-city,
  .hhwt-widget-deals {
    width: 100%;
  }

  .hhwt-fluid .banner-content h3 {
    font-size: 24px;
    line-height: 28px;
  }
}
#comments ol,
#comments ul {
  padding-left: 0px;
}
#comments ol ul.children,
#comments ul ul.children {
  padding-left: 40px;
}
#comments li {
  list-style: none;
  padding: 0px;
}
#comments h4#commentator-post-count {
  color: #556666;
  font-size: 24px;
  font-family: "ProximaNova-Semibold";
}
#comments .commentator-comment-body {
  margin-left: 60px;
}
#comments img {
  border-radius: 50%;
}
#comments .commentator-avatar .user {
  background: transparent;
}

.single-post .saved-modal {
  background-color: transparent;
}

.hhwt-title-container {
  padding: 0px;
}
.hhwt-single-category .hhwt-title-container .hhwt-title-content,
.hhwt-single-category .hhwt-title-container .hhwt-search {
  padding: 30px 0px;
}
.hhwt-title-container p a {
  font-size: 14px;
  font-family: "ProximaNova-Semibold";
  color: #08cccb;
}
.hhwt-title-container p a:hover,
.hhwt-title-container p a:active,
.hhwt-title-container p a:focus,
.hhwt-title-container p a:visited {
  text-decoration: none;
  outline: none;
}
.hhwt-title-container h3 p {
  font-size: 46px;
  font-family: "ProximaNova-Bold";
  color: #556767;
  margin-top: 10px;
}
.hhwt-title-container h3 a:hover,
.hhwt-title-container h3 a:active,
.hhwt-title-container h3 a:focus,
.hhwt-title-container h3 a:visited {
  text-decoration: none;
  outline: none;
}
.hhwt-title-container .hhwt-search-category .search-box {
  float: right;
  max-width: 230px;
}
.hhwt-title-container .hhwt-search-category .search-box span {
  background: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid rgba(226, 214, 214, 0.58);
  border-right: 0;
}
.hhwt-title-container .hhwt-search-category .search-box span .fa-search {
  color: #cad3d4;
  cursor: pointer;
}
.hhwt-title-container .hhwt-search-category .search-box input {
  padding: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-left: none;
  border-color: rgba(226, 214, 214, 0.58);
  box-shadow: none;
  height: 40px;
}
.hhwt-title-container
  .hhwt-search-category
  .search-box
  input::-webkit-input-placeholder {
  color: #cad3d4;
}
.hhwt-title-container
  .hhwt-search-category
  .search-box
  input::-moz-placeholder {
  color: #cad3d4;
}

.hhwt-destination-fluid {
  padding: 25px 0px 25px;
  overflow-x: hidden;
}

.hhwt-grid-content {
  padding: 0px;
  cursor: pointer;
}
.hhwt-grid-content .col-md-3 .modern-small header {
  text-shadow: 0px 0px 10px #000, 0px 0px 10px #000, 0 0 0px #fff,
    0 0 0px #000000, 0 0 0px #000, 0px 0px 0px #fff, 0px 0px 0px #fff,
    0 0 0px #fff;
}
.hhwt-grid-content .col-md-3 {
  margin-bottom: 30px;
}

.hhwt-destination-container {
  padding: 0px;
}
.hhwt-destination-container .modern-small img {
  min-height: 413px;
  max-height: 413px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .hhwt-grid-content .col-md-3 .modern-small img {
    min-height: 265px;
    max-height: 265px;
  }
  #featured-carousel .featured-fluid .featured-title {
    overflow: inherit;
  }
  .hhwt-fetured-details-selection {
    right: 0px;
    left: inherit;
  }
  #featured-carousel .item .hhwt-home-btn-go {
    left: 20%;
    bottom: 76px;
    right: inherit !important;
    top: inherit !important;
  }
}
@media only screen and (max-width: 767px) {
  .hhwt-title-container {
    padding: 15px 0px 0px;
  }
  .hhwt-title-container h3 {
    text-align: center;
  }
  .hhwt-title-container h3 a {
    font-size: 32px;
  }
  .hhwt-title-container .hhwt-search-category .search-box {
    max-width: 100%;
    padding: 0px 15px;
  }
  .hhwt-title-container .hhwt-title-content,
  .hhwt-title-container .hhwt-search {
    padding: 0px 0px 15px;
  }
  .hhwt-title-container p {
    text-align: center;
    padding-bottom: 10px;
  }

  .hhwt-grid-content {
    margin: 0px;
  }
  .hhwt-grid-content .col-md-3:nth-child(2n + 1) {
    padding-right: 7.5px;
  }
  .hhwt-grid-content .col-md-3:nth-child(2n) {
    padding-left: 7.5px;
  }
}
.hhwt-nested-posts .col-md-3 {
  /* overflow: hidden; */
  margin-bottom: 30px;
}
.hhwt-nested-posts .col-md-3 img {
  width: 100%;
  max-height: 263px;
  min-height: 263px;
}

.hhwt-child-cat-link {
  padding: 0px;
}
.hhwt-child-cat-link .hhwt-nav-pills {
  font-family: "ProximaNova-Semibold";
}
/* .hhwt-child-cat-link .hhwt-nav-pills li.cat_active, .hhwt-child-cat-link .hhwt-nav-pills li:hover{
  border-bottom: 3px solid #08CCCB;
} */
.hhwt-child-cat-link .hhwt-nav-pills div a.cat_active {
  border-bottom: 3px solid #08cccb;
  color: #00cccc !important;
  background-color: #fff;
  font-weight: bold;
}
.hhwt-child-cat-link .hhwt-nav-pills div:hover > a {
  border-bottom: 3px solid #08cccb;
  text-decoration: none;
  color: #00cccc !important;
  background-color: #fff;
}
/*.hhwt-child-cat-link .hhwt-nav-pills li.cat_active a, .hhwt-child-cat-link .hhwt-nav-pills div.cat_active a {
  color: #00cccc !important;
  background-color: #fff;
  font-weight: bold;
}
.hhwt-child-cat-link .hhwt-nav-pills li.cat_active a:hover, .hhwt-child-cat-link .hhwt-nav-pills li.cat_active a:active, .hhwt-child-cat-link .hhwt-nav-pills li.cat_active a:focus, .hhwt-child-cat-link .hhwt-nav-pills li.cat_active a:visited {
  color: #08CCCB !important;
  background-color: #fff;
}

.hhwt-child-cat-link .hhwt-nav-pills div.cat_active a:hover, .hhwt-child-cat-link .hhwt-nav-pills div.cat_active a:active, .hhwt-child-cat-link .hhwt-nav-pills div.cat_active a:focus, .hhwt-child-cat-link .hhwt-nav-pills div.cat_active a:visited {
  color: #08CCCB !important;
  background-color: #fff;
}
.hhwt-child-cat-link .hhwt-nav-pills li.cat_active.remove-border, .hhwt-child-cat-link .hhwt-nav-pills div.cat_active.remove-border {
  border-bottom: transparent;
} */
.hhwt-child-cat-link .hhwt-nav-pills li a,
.hhwt-child-cat-link .hhwt-nav-pills div a {
  color: #666;
  font-size: 18px;
  background-color: #fff;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  margin-left: 0px;
  padding: 24px 21px 21px !important;
  display: inline-block;
  cursor: pointer;
}

.hhwt-child-cat-link .hhwt-nav-pills li,
.hhwt-child-cat-link .hhwt-nav-pills div {
  margin-right: 5px;
}
.hhwt-child-cat-link .owl-carousel .owl-nav button.disabled {
  display: none;
}

.hhwt-child-cat-link .owl-carousel.owl-drag .owl-item:last-child {
  display: none !important;
}
.hhwt-child-cat-link .owl-carousel.owl-drag .owl-item:nth-last-child(2) {
  margin-right: 0px !important;
}

.hhwt-child-cat-link .hhwt-nav-pills li a:hover,
.hhwt-child-cat-link .hhwt-nav-pills li a:active,
.hhwt-child-cat-link .hhwt-nav-pills li a:focus,
.hhwt-child-cat-link .hhwt-nav-pills li a:visited {
  background-color: #fff;
  outline: none;
}

.hhwt-child-cat-link .hhwt-nav-pills div a:hover,
.hhwt-child-cat-link .hhwt-nav-pills div a:active,
.hhwt-child-cat-link .hhwt-nav-pills div a:focus,
.hhwt-child-cat-link .hhwt-nav-pills div a:visited {
  background-color: #fff;
  outline: none;
}
.hhwt-child-cat-link .owl-nav {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
.hhwt-child-cat-link .owl-carousel {
  height: 72px;
  overflow-y: hidden;
  padding-right: 15px;
  padding-left: 10px;
}
.hhwt-child-cat-link .owl-theme .owl-nav [class*="owl-"] {
  margin: 0px;
}
.hhwt-child-cat-link .owl-theme .owl-nav {
  font-size: 32px;
  margin-top: 0px;
  font-family: "Open Sans", sans-serif;
}
.hhwt-child-cat-link .owl-theme .owl-nav [class*="owl-"]:hover {
  color: #333;
}

.hhwt-child-cat-link .owl-carousel .owl-nav button.owl-prev {
  float: left;
  background: linear-gradient(
    to right,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 72px;
  height: 72px;
  position: relative;
  z-index: 10;
  text-align: left;
  padding-left: 16px;
  margin-top: -2px;
}
.hhwt-child-cat-link .owl-carousel .owl-nav button.owl-next {
  float: right;
  background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 72px;
  height: 72px;
  position: relative;
  z-index: 10;
  text-align: right;
  padding-right: 16px;
  margin-top: -2px;
}

.hhwt-child-cat-link .owl-carousel .owl-stage-outer {
  z-index: 1;
}

.hhwt-child-cat-link .hhwt-nav-pills.category-slide {
  -webkit-animation-name: category-slide;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.4s;
  /* Safari 4.0 - 8.0 */
  animation-name: category-slide;
  animation-duration: 0.4s;
}

.plan_trip {
  text-align: center;
  width: 100%;
  float: left;
  position: fixed;
  bottom: 0px;
  left: 0px;
  color: #fff;
  padding: 10px 0px;
  background: #00cccb;
  z-index: 10;
  display: none;
}
.mobile_app_link,
.save_bucket_popup {
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: none;
  z-index: 10;
  width: 100%;
  text-align: center;
}
.save_bucket_popup {
  bottom: 16%;
}
.mobile_app_innr {
  position: relative;
  display: inline-block;
}

.beta_site_img {
  position: fixed;
  bottom: 0px;
  right: 15%;
  left: inherit;
  display: block;
  z-index: 10;
  width: 70%;
  text-align: right;
}
.close_beta {
  position: absolute;
  right: 7px;
  top: 6px;
  cursor: pointer;
}

@-webkit-keyframes category-slide {
  from {
    max-height: 32px;
  }
  to {
    max-height: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .plan_trip {
    display: block;
  }
  .stickyMenu {
    bottom: 20px !important;
  }
  .close_mobile_app,
  .save_bucket_open {
    position: absolute;
    right: 11px;
    top: 11px;
    cursor: pointer;
  }
  .close_mobile_app:hover,
  .save_bucket_open:hover,
  .close_beta:hover {
    opacity: 0.8;
  }

  .hhwt-nested-posts .col-md-3:nth-child(2n + 1) {
    padding-right: 7.5px;
  }
  .hhwt-nested-posts .col-md-3:nth-child(2n) {
    padding-left: 7.5px;
  }
  .hhwt-nested-posts .col-md-3 {
    margin-bottom: 15px;
  }

  .hhwt-child-cat-link {
    text-align: center;
  }
  /* .hhwt-child-cat-link .hhwt-nav-pills li {
    border-bottom: 2px solid transparent;
    margin: 10px auto;
    display: none;
    float: none;
    max-width: 130px;
  } */
  .hhwt-child-cat-link .hhwt-nav-pills li a {
    padding: 0px 10px 10px;
    color: #556767;
  }
  .hhwt-child-cat-link .hhwt-nav-pills li.active {
    display: inline-flex;
    margin: auto;
  }
  .hhwt-child-cat-link .hhwt-nav-pills li.active span {
    color: #08cccb;
    margin-top: 2px;
    padding-right: 8px;
    display: inline-block;
  }

  .hhwt-child-cat-link .hhwt-nav-pills li span {
    display: none;
  }

  .hhwt-child-cat-link .hhwt-nav-pills li a {
    font-size: 14px;
    line-height: 19px;
    padding: 11px 16px !important;
    margin-left: 0px;
  }
  .hhwt-child-cat-link .hhwt-nav-pills li.active span {
    display: none;
  }
  .catBreadCrumb {
    padding: 0px 15px;
    font-size: 12px;
    line-height: 17px;
  }
  .articles-all-page .hhwt-result-title {
    padding: 0px 15px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .articles-all-page .hhwt-result-title h3 {
    font-size: 24px;
    line-height: 29px;
  }
  .articles-all-page .hhwt-result-title span {
    font-size: 12px;
    line-height: 17px;
  }

  .hhwt-child-cat-link .owl-carousel {
    height: 56px;
    padding-left: 0px;
  }
  .hhwt-child-cat-link .hhwt-nav-pills li a,
  .hhwt-child-cat-link .hhwt-nav-pills div a {
    font-size: 14px;
    line-height: 19px;
    padding: 20px 10px 14px !important;
  }
  .hhwt-child-cat-link .owl-theme .owl-nav {
    font-size: 26px;
    margin-top: 0px;
  }
  .hhwt-child-cat-link .owl-carousel.owl-drag .owl-item {
    margin-right: 3px !important;
  }
  .hhwt-child-cat-link .owl-carousel .owl-nav button.owl-next {
    margin-right: 7px;
    height: 60px;
    width: 40px;
  }
  .hhwt-child-cat-link .owl-carousel .owl-nav button.owl-prev {
    margin-left: 7px;
    height: 60px;
    width: 40px;
  }
  .hhwt-child-cat-link .owl-theme .owl-nav [class*="owl-"] {
    position: relative;
    z-index: 10;
    padding: 2px !important;
    background: #fff;
  }
  .hhwt-child-cat-link .owl-theme .owl-nav [class*="owl-"]:focus,
  .hhwt-child-cat-link .owl-theme .owl-nav [class*="owl-"]:active,
  .hhwt-child-cat-link .owl-theme .owl-nav [class*="owl-"]:visited {
    outline: none;
    border: 0px;
  }

  .destination_menu .visible-xs {
    line-height: 22px;
  }
  .nav > li.hhwtmainmenu.destination_menu > a .caret {
    margin-top: 10px;
  }
  .nav > li.hhwtmainmenu > a .caret {
    margin-left: 4px;
    margin-top: 8px;
  }
}

@media only screen and (max-width: 449px) {
  .hhwt-nested-posts .col-md-3 img {
    max-height: 165px;
    min-height: 165px;
  }
}
.hhwt-search-page {
  padding: 25px 0px 0px;
  border-top: 1px solid #ddd;
  position: relative;
}
.hhwt-search-page .container {
  padding: 0px;
}
.hhwt-search-page .hhwt-result-title {
  margin-bottom: 50px;
  padding: 0px 0px;
  margin-top: 20px;
}
.hhwt-search-page .hhwt-result-title h3 {
  font-family: "ProximaNova-Bold";
  font-size: 33px;
  color: #333;
  line-height: 40px;
}
.hhwt-search-page .hhwt-search-results {
  padding: 0;
}

.hhwt-search-page .hhwt-result-title span {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

@media only screen and (max-width: 767px) {
  .hhwt-nested-posts {
    margin-left: 0px;
    margin-right: 0px;
  }
  .hhwt-nested-posts .col-md-3:nth-child(2n + 1) {
    padding-left: 15px;
    padding-right: 7.5px;
  }
  .hhwt-nested-posts .col-md-3:nth-child(2n) {
    padding-left: 7.5px;
  }
  .hhwt-search-page .hhwt-result-title {
    margin-top: 0px;
    margin-bottom: 25px;
    padding: 0 15px;
  }
  .hhwt-search-page .hhwt-result-title h3 {
    font-size: 24px;
    line-height: 29px;
  }
  .hhwt-search-page .hhwt-result-title span {
    font-size: 12px;
    line-height: 16px;
  }
}

.hhwt-author-container {
  padding: 0px;
}

.hhwt-author-container .hhwt-author-details .hhwt-author-avatar {
  padding-bottom: 0px;
  text-align: center;
  font-family: "ProximaNova-Bold";
}
.hhwt-author-container .hhwt-author-details .hhwt-author-avatar img {
  border-radius: 50%;
  width: 95px;
}
.hhwt-author-container .hhwt-author-details .hhwt-author-avatar h3 {
  font-size: 19px;
  color: #00cccb;
  margin-top: 10px;
}
.hhwt-author-container .hhwt-author-details .hhwt-author-avatar p a {
  font-size: 19px;
  color: #00d3d4;
  text-decoration: none;
}
.hhwt-author-container .hhwt-author-details .hhwt-author-post-count {
  text-align: center;
  padding-bottom: 20px;
}
.hhwt-author-container .hhwt-author-details .hhwt-author-post-count p {
  color: #a6b0b1;
  font-size: 19px;
  font-family: "ProximaNova-Semibold";
}
.hhwt-author-container .hhwt-author-details .hhwt-author-post-count h3 {
  color: #495b5b;
  font-size: 30px;
  font-family: "ProximaNova-Bold";
  margin-top: 10px;
}
.hhwt-author-container .hhwt-author-details .hhwt-author-bio {
  overflow: hidden;
  clear: both;
  margin-top: 20px;
}
.hhwt-author-container .hhwt-author-details .hhwt-author-bio p {
  color: #495b5b;
  font-size: 15px;
  margin-top: 10px;
  font-family: "ProximaNova";
}
.hhwt-author-container .hhwt-author-details .hhwt-author-bio h3 {
  font-family: "ProximaNova-Semibold";
  color: #a6b0b1;
  font-size: 19px;
  text-align: center;
}
.hhwt-author-container .hhwt-author-posts {
  padding: 30px;
  border-left: 1px solid #ccc;
}
.hhwt-author-container .hhwt-author-posts h2 {
  font-size: 36px;
  font-family: "ProximaNova-Bold";
  color: #556767;
}
.hhwt-author-container .hhwt-author-posts .hhwt-author-posts-grid {
  padding: 0px 0px 40px;
}
.hhwt-author-container
  .hhwt-author-posts
  .hhwt-author-posts-grid
  .hhwt-nested-posts
  .col-md-4:nth-child(3n) {
  padding-right: 0px;
}
.hhwt-author-container
  .hhwt-author-posts
  .hhwt-author-posts-grid
  .hhwt-nested-posts
  .col-md-4:nth-child(3n + 1) {
  padding-left: 0px;
}
.hhwt-author-container .hhwt-bucket-category p {
  font-size: 30px;
  color: #4c5657;
  font-family: "ProximaNova-Bold";
}
.hhwt-author-container .simple-small header {
  font-family: "ProximaNova-Bold";
  min-height: 140px;
  background-color: #fff;
}
.hhwt-author-container .simple-small p a {
  font-size: 13px;
  text-decoration: none;
  color: #00d3d4;
}
.hhwt-author-container .simple-small p .fa-heart {
  float: right;
  font-size: 20px;
  color: #f00079;
}
.hhwt-author-container .simple-small h3 {
  margin-top: 10px;
}
.hhwt-author-container .simple-small h3 a {
  color: #495b5b;
  font-size: 19px;
  text-decoration: none;

  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.hhwt-author-container .simple-small span {
  display: inline;
}
.hhwt-author-container .simple-small img {
  width: 100%;
}

.hhwt-author-page .hhwt-nested-posts {
  padding-bottom: 30px;
}
.hhwt-author-page .hhwt-nested-posts:last-child {
  padding-bottom: 0px;
}

@media only screen and (max-width: 767px) {
  .hhwt-author-container .hhwt-author-details {
    padding-bottom: 25px;
  }
  .hhwt-author-container .hhwt-author-details .hhwt-author-bio p {
    text-align: center;
  }
  .hhwt-author-container .hhwt-author-posts {
    padding-left: 15px;
    padding-right: 15px;
  }
  .hhwt-author-container
    .hhwt-author-posts
    .hhwt-author-posts-grid
    .hhwt-nested-posts
    .col-md-4:nth-child(2n) {
    padding-left: 7.5px;
  }
  .hhwt-author-container
    .hhwt-author-posts
    .hhwt-author-posts-grid
    .hhwt-nested-posts
    .col-md-4:nth-child(2n + 1) {
    padding-left: 0px;
    padding-right: 7.5px;
  }
  .hhwt-author-container .hhwt-author-posts h2 {
    font-size: 24px;
  }
  .hhwt-author-container .simple-small h3 a {
    font-size: 14px;
  }
}
.hhwt-single-tag .content-title {
  padding: 35px 0px;
  margin: 0px auto;
}
.hhwt-single-tag .content-title .btn-more {
  display: none;
}
.hhwt-single-tag .content-title h4 {
  text-transform: capitalize;
}
.hhwt-single-tag .hhwt-tagpost-container {
  padding: 0px;
}
.hhwt-single-tag .hhwt-fluid {
  background: #f5f6f8;
  padding: 25px 0px 0px;
  border-top: 1px solid #ddd;
}

@media only screen and (max-width: 767px) {
  .hhwt-single-tag .content-title {
    padding: 35px 15px;
  }
}
.save-bucket-modal-dialog {
  width: 400px;
  border-radius: 8px;
}

.save-bucket-modal-content {
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
.save-bucket-modal-content .save-bucket-modal-header {
  font-family: "ProximaNova-Bold";
  border-bottom: 0;
  padding: 16px 24px 0 24px;
}
.save-bucket-modal-content .save-bucket-modal-header h3 {
  text-align: left;
  color: #333;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.save-bucket-modal-content .save-bucket-modal-header .close {
  color: #333;
  font-size: 30px;
  opacity: 1;
  font-family: "proximanova";
}
.form-control:focus {
  box-shadow: none;
}
.save-bucket-modal-content .save-bucket-modal-header .save-prev {
  font-size: 15px;
  color: #00cccb;
  background: transparent;
  border: none;
  font-family: "ProximaNova-Bold";
  outline: none;
  position: absolute;
  top: 20px;
  display: none;
}
.save-bucket-modal-content .save-modal-body {
  padding: 0px;
}
.save-bucket-modal-content .save-modal-body .save-bucket-article {
  padding: 20px 24px 18px;
  font-family: "ProximaNova-Bold";
  display: none;
}
.save-bucket-modal-content .save-modal-body .save-bucket-article img {
  width: 50px;
  height: 50px;
}
.save-bucket-modal-content
  .save-modal-body
  .save-bucket-article
  .save_buck_art_title {
  padding-left: 18px;
  width: 70%;
  display: inline-block;
  vertical-align: middle;
}
.save-bucket-modal-content
  .save-modal-body
  .save-bucket-article
  .save_buck_art_title
  p
  a {
  color: #08cccb;
}
.save-bucket-modal-content
  .save-modal-body
  .save-bucket-article
  .save_buck_art_title
  p
  a:hover {
  text-decoration: none;
}
.save-bucket-modal-content
  .save-modal-body
  .save-bucket-article
  .save_buck_art_title
  h3 {
  color: #333;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  font-family: "ProximaNova-Bold";
}
.save-bucket-modal-content .save-modal-body .save-to-bucket {
  padding: 18px 24px 18px;
  font-family: "ProximaNova-Bold";
  text-align: center;
  /* border-top: 1px solid #D8D8D8; */
}
.save-bucket-modal-content .save-modal-body .save-to-bucket p {
  text-align: left;
  font-size: 16px;
  color: #333;
  margin-bottom: 12px;
}
.save-bucket-modal-content .save-modal-body .save-to-bucket .hhwt-save-btn {
  background: #f2007b;
  color: #fff;
  border-radius: 8px;
  margin: 16px 0px 6px;
  outline: none;
  display: inline-block;
  width: 100%;
  text-align: center;
  height: 48px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-save-btn:hover {
  background: #f2218c;
}
.save-bucket-modal-content .save-modal-body .save-to-bucket .hhwt-save-btn .fa {
  display: none;
}

.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-save-btn
  .fa-heart {
  font-size: 18px;
  padding: 0px 6px 0px 0px;
  vertical-align: middle;
}
.save-bucket-modal-content .save-modal-body .save-to-bucket .hhwt-select-list {
  display: inline-block;
  width: 100%;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-select-list
  select {
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #999;
  float: left;
  height: 48px;
  width: 233px;
  color: #333;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;

  cursor: pointer;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-select-list
  select:invalid {
  color: #999;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-select-list
  select
  option {
  color: #333;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-select-list
  .hhwt-create-new {
  outline: none;
  background: #fff;
  border-radius: 8px;
  color: #00cccb;
  float: right;
  border: 1px solid #00cccb;
  width: 104px;
  height: 48px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  padding: 0px;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-select-list
  .hhwt-create-new:hover,
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-text-input
  .hhwt-saved-btn:hover {
  color: #fff;
  background: #00cccb;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-select-list.without-bucket {
  display: block;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-select-list.without-bucket
  .hhwt-create-new {
  margin-left: 0px;
}
.save-bucket-modal-content .save-modal-body .save-to-bucket label {
  width: 100%;
  text-align: left;
  color: red;
  display: none;
  margin-top: 10px;
}
.save-bucket-modal-content .save-modal-body .save-to-bucket .hhwt-text-input {
  display: none;
  padding-top: 20px;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-text-input
  .hhwt-input-bucketname {
  float: left;
  width: 233px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #666;
  color: #333;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.hhwt-text-input .hhwt-input-bucketname:focus {
  border: 1px solid #00cccc !important;
}

.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-text-input
  .hhwt-input-bucketname::placeholder {
  color: #999;
  opacity: 0.8;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-text-input
  .hhwt-saved-btn {
  border: 1px solid #00cccb;
  border-radius: 8px;
  background-color: #ffffff;
  float: right;
  height: 48px;
  width: 104px;
  padding: 0px !important;
  margin: 0px !important;
  color: #00cccb;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align-last: center;
}
.save-bucket-modal-content
  .save-modal-body
  .save-to-bucket
  .hhwt-text-input
  .hhwt-saved-btn.btn-cancel-bucket {
  margin-left: 15px;
  display: none;
}

.saved-modal,
.hhwt-modal-notification {
  left: 48%;
  top: 48px;
  position: absolute;
}

.saved-modal {
  width: 360px;
  transform: translate(65%, 0%);
}

.hhwt-modal-notification {
  width: 400px;
  transform: translate(0%, 0%);
}

.saved-bucket-modal-dialog {
  width: 350px;
  border-radius: 0px;
}

.saved-bucket-modal-content {
  border-radius: 0px;
}
.saved-bucket-modal-content .dropdown-arrow {
  margin-top: 2px;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #f5f6f8 transparent;
  position: absolute;
  right: 13px;
  transition: 0.25s;
  border-bottom-color: #f5f6f8;
  top: -10px;
}
.saved-bucket-modal-content .save-bucket-modal-header {
  background: #f5f6f8;
  font-family: "ProximaNova-Semibold";
  border-bottom: 1px solid #f5f6f8;
  padding: 12px;
}
.saved-bucket-modal-content .save-bucket-modal-header .save-bucket-title {
  margin: 0px;
  color: #586566;
  font-size: 16px;
}
.saved-bucket-modal-content
  .save-bucket-modal-header
  .save-bucket-title
  .fa-check {
  background: #00cccb;
  border-radius: 50px;
  font-size: 16px;
  padding: 3px;
  color: #fff;
}
.saved-bucket-modal-content .save-bucket-modal-header .hhwt-view-all {
  text-align: right;
  margin-top: 3px;
  color: #00cccb;
  font-size: 13px;
  float: right;
}
.saved-bucket-modal-content .save-bucket-modal-header .hhwt-view-all a {
  color: #00cccb;
  outline: none;
}
.saved-bucket-modal-content .save-bucket-modal-header .hhwt-view-all a:hover {
  text-decoration: none;
}
.saved-bucket-modal-content
  .save-bucket-modal-header
  .hhwt-view-all
  .fa-angle-right {
  padding: 0px 5px;
}
.saved-bucket-modal-content .save-modal-body {
  font-family: "ProximaNova-Bold";
  padding: 12px;
}
.saved-bucket-modal-content .save-modal-body img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}
.saved-bucket-modal-content .save-modal-body .save-bucket-article {
  display: flex;
}
.saved-bucket-modal-content .save-modal-body div h3 {
  font-size: 13px;
  color: #586566;
  margin-top: 5px;
  margin-bottom: 5px;
}
.saved-bucket-modal-content .save-modal-body div p {
  font-family: "ProximaNova-Bold";
  color: #6e7376;
  font-size: 13px;
  margin: 0px;
}
.saved-bucket-modal-content .save-modal-body div p a {
  color: #00cccb;
  outline: none;
}
.saved-bucket-modal-content .save-modal-body div p a:hover {
  text-decoration: none;
}

.hhwt-signup-dialog .modal-content {
  overflow: auto;
  background-color: #fefefe;
  margin: auto !important;
  padding: 26px 26px 48px;
  border: 1px solid #888;
  width: 570px;
  color: #6d7272;
}
.hhwt-signup-dialog .modal-header {
  font-size: 16px;
  font-family: "ProximaNova-Semibold";
  border-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  min-height: auto;
  padding: 0px;
  position: relative;
  z-index: 1;
}
.hhwt-signup-dialog .modal-body {
  padding: 0px;
  display: inline-block;
  width: 100%;
  margin-top: -6px;
}

.hhwt-signup-content .hhwt-signup-header .hhwt-nav-pills li {
  background: none;
  text-align: center;
  margin: 0px;
}
.hhwt-signup-content .hhwt-signup-header .hhwt-nav-pills li a {
  background: none;
  font-size: 18px;
  color: #6d7272 !important;
  padding: 0px 0px 10px !important;
  outline: none;
}
.hhwt-signup-content .hhwt-signup-header .hhwt-nav-pills li a:hover {
  background: none;
  color: #6d7272;
}
.hhwt-signup-content .hhwt-signup-header .hhwt-nav-pills .active {
  background: none;
  text-align: center;
  border-bottom: none;
}
.hhwt-signup-content .hhwt-signup-header .hhwt-nav-pills .active a {
  border-bottom: 2px solid #08cccb;
  border-radius: 0px;
}
.hhwt-signup-content .hhwt-signup-header .hhwt-nav-pills .active a:hover {
  background-color: #fff !important;
  font-size: 18px;
}
.hhwt-signup-content .hhwt-signup-body #hhwt-signup .hhwt-checkbox {
  font-size: 14px;
  font-family: "ProximaNova";
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}
.hhwt-signup-content .hhwt-signup-body #hhwt-signup .hhwt-checkbox label {
  display: block !important;
}
.hhwt-signup-content
  .hhwt-signup-body
  #hhwt-login
  .hhwt-facebook
  .facebookbutton,
.hhwt-signup-content
  .hhwt-signup-body
  #hhwt-signup
  .hhwt-facebook
  .facebookbutton {
  color: #fff;
  background-color: #3e60aa;
  border-color: #3e60aa;
  font-size: 16px;
  font-family: "ProximaNova-Bold";
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 9px 10px;
  outline: none;
}
.hhwt-signup-content
  .hhwt-signup-body
  #hhwt-login
  .hhwt-facebook
  .facebookbutton
  span,
.hhwt-signup-content
  .hhwt-signup-body
  #hhwt-signup
  .hhwt-facebook
  .facebookbutton
  span {
  padding-right: 25px;
  font-size: 20px;
  margin-top: 0;
}
.hhwt-signup-content .hhwt-create-profile {
  display: none;
}
.hhwt-signup-content .hhwt-create-profile .hhwt-modal-header {
  padding: 0px 20px;
  border-bottom: 2px solid #e5e5e5;
}
.hhwt-signup-content .hhwt-create-profile .hhwt-register-form {
  padding: 50px 0px 0px 0px;
}
.hhwt-signup-content
  .hhwt-create-profile
  .hhwt-register-form
  .hhwt-profile-form {
  width: 85%;
  margin: auto;
}
.hhwt-signup-content .hhwt-create-profile .hhwt-register-form .hhwt-checkbox {
  font-size: 14px;
  font-family: "ProximaNova";
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;
}
.hhwt-signup-content
  .hhwt-create-profile
  .hhwt-register-form
  .hhwt-checkbox
  .checkbox-inline {
  font-size: 14px;
  font-family: "ProximaNova";
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
}
.hhwt-signup-content
  .hhwt-create-profile
  .hhwt-register-form
  .create-profile-btn {
  font-size: 16px;
  font-family: "ProximaNova-Bold";
  background-color: #989c9c;
  color: #fff;
  border: none;
  padding: 12px 10px;
  border-radius: 3px;
  outline: none;
  width: 100%;
}
.hhwt-signup-content .hhwt-signup-account,
.hhwt-signup-content .hhwt-signup-form,
.hhwt-signup-content .hhwt-forget-form,
.hhwt-signup-content .hhwt-signup-after-form,
.hhwt-signup-content .hhwt-trip-after-form,
.hhwt-signup-content .hhwt-past-trip-after-form,
#mc_embed_signup {
  display: none;
  padding: 0px;
}
/* .hhwt-signup-content .hhwt-login-form .hhwt-modal-header, .hhwt-signup-content .hhwt-signup-form .hhwt-modal-header, .hhwt-signup-content .hhwt-forget-form .hhwt-modal-header {
  padding: 0px 20px;
  border-bottom: 2px solid #e5e5e5;
} */
/* .hhwt-signup-content .hhwt-login-form .hhwt-modal-body, .hhwt-signup-content .hhwt-signup-form .hhwt-modal-body, .hhwt-signup-content .hhwt-forget-form .hhwt-modal-body {
  padding: 50px 40px 0px;
} */
.hhwt-signup-content .hhwt-login-form .hhwt-modal-body .registerform,
.hhwt-signup-content .hhwt-signup-form .hhwt-modal-body .registerform,
.hhwt-signup-content .hhwt-forget-form .hhwt-modal-body .registerform {
  width: 85%;
  margin: auto;
}
.hhwt-signup-content .hhwt-submit-btn {
  background-color: #00cccb;
  color: #fff;
  border: none;
  padding: 12px 10px;
  border-radius: 8px;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.hhwt-signup-content .hhwt-submit-btn:hover {
  background-color: #24d3d3;
}

.hhwt-signup-content .hhwt-signup-after-form .form-group {
  text-align: center;
}
.hhwt-signup-content .hhwt-signup-after-form .hhwt-submit-btn {
  width: 322px;
  display: inline-block;
}

.hhwt-signup-content .hhwt-login-form .hhwt-modal-body .hhwt-terms-condition,
.hhwt-signup-content .hhwt-signup-form .hhwt-modal-body .hhwt-terms-condition,
.hhwt-signup-content .hhwt-forget-form .hhwt-modal-body .hhwt-terms-condition {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  cursor: pointer;
}
.hhwt-signup-content
  .hhwt-login-form
  .hhwt-modal-body
  .hhwt-terms-condition#forget-password,
.hhwt-signup-content
  .hhwt-signup-form
  .hhwt-modal-body
  .hhwt-terms-condition#forget-password,
.hhwt-signup-content
  .hhwt-forget-form
  .hhwt-modal-body
  .hhwt-terms-condition#forget-password {
  color: #00cccb;
}
.hhwt-signup-content .hhwt-popup-title {
  font-family: "ProximaNova-Bold";
  text-align: center;
  padding-bottom: 13px;
}
.hhwt-signup-content .hhwt-popup-title #hhwt-back-forget {
  font-size: 15px;
  color: #00cccb;
  cursor: pointer;
  float: left;
}
.hhwt-signup-content .hhwt-popup-title .hhwt-back {
  font-size: 15px;
  color: #00cccb;
  cursor: pointer;
  float: left;
}
.hhwt-signup-content .hhwt-popup-title .hhwt-back-signup {
  font-size: 15px;
  color: #00cccb;
  cursor: pointer;
  float: left;
}
.hhwt-signup-content .hhwt-popup-title .signup-title {
  margin: 0px;
  font-size: 16px;
  text-align: center;
}
.hhwt-signup-content h3, .text-confirm {
  font-size: 32px;
  line-height: 38px;
  font-family: "ProximaNova-Bold";
  padding-bottom: 5px;
  padding-top: 0px;
  text-align: center;
  margin: 0px;
  color: #333;
}
.hhwt-signup-content p {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  line-height: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  margin-top: 8px;
}
.hhwt-signup-content .form-group {
  padding: 0px;
  margin-bottom: 10px;
  position: relative;
  float: left;
  width: 100%;
}
.hhwt-signup-content .form-group.pwd_field {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 15px;
  top: 50%;
  cursor: pointer;
}
.toggle-password.signin-icon {
  top: 16px;
}
.close_input {
  position: absolute;
  right: 15px;
  top: 14px;
  cursor: pointer;
}

.hhwt-signup-content .form-group label {
  font-size: 14px;
  font-family: "ProximaNova";
  font-weight: 700;
  padding-top: 8px;
  padding-bottom: 8px;
  display: none;
}
.hhwt-signup-content .form-group .form-control {
  height: 45px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  color: #333;
  outline: none;
  box-shadow: none;
  text-align: left;
  border-radius: 8px;
  border: 1px solid #666;
  border-radius: 8px;
  padding: 6px 16px;
  line-height: 20px;
}
.hhwt-signup-content .form-group input[type="text"]:focus,
.hhwt-signup-content .form-group input[type="email"]:focus,
.hhwt-signup-content .form-group input[type="password"]:focus {
  border: 1px solid #00cccc;
}

.hhwt-signup-content .form-group .btn {
  text-align: center;
  font-weight: 600;
  transition: all ease 0.3s;
}

/* .hhwt-signup-content .form-group .btn:hover{
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.16);
} */

.hhwt-signup-content .form-group .form-control::-webkit-input-placeholder {
  color: #999;
}
.hhwt-signup-content .form-group .form-control::-moz-placeholder {
  color: #999;
}
.hhwt-signup-content .form-group .hhwt-first-name {
  padding-left: 0px;
}
.hhwt-signup-content .form-group .hhwt-last-name {
  padding-right: 0px;
}
.hhwt-signup-content .form-group .form-control.error {
  border: 1px solid #a94442;
}
.hhwt-signup-content .form-group .error {
  color: #bb4442;
  font-family: "ProximaNova";
}
.hhwt-signup-content .hhwt-terms-condition {
  font-family: "Open Sans", sans-serif;
  line-height: 20px;
  font-size: 14px;
  padding-top: 7px;
  text-align: center;
  color: #8f8e94;
  margin-bottom: 24px;
}

.hhwt-signup-content .hhwt-terms-condition span {
  color: #00cccb;
  cursor: pointer;
}

.hhwt-signup-form .hhwt-next,
.hhwt-signup-form .hhwt-prev {
  font-size: 15px;
  color: #00cccb;
  background: transparent;
  border: none;
  font-family: "ProximaNova-Bold";
  outline: none;
}
.hhwt-signup-form .hhwt-next {
  margin-top: 15px;
  float: right;
}
.hhwt-signup-form .hhwt-next i {
  margin-left: 3px;
}
.hhwt-signup-form .hhwt-prev {
  margin-bottom: 15px;
  float: left;
}
.hhwt-signup-form .hhwt-prev i {
  margin-right: 3px;
}

.hhwt-signup-form .hhwt-form-registration #Step2 {
  display: none;
}
.hhwt-signup-form .hhwt-form-registration .form-group select {
  height: 45px;
  width: 100%;
  padding-left: 7px;
  border: 1px solid #ccc;
  font-family: "ProximaNova-Bold";
  color: #6f7280;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.modal img.modal-loading {
  padding: 15px;
  display: block;
  margin: auto;
}
.modal.hhwt-modal-loading .hhwt-saved-dialog,
.modal.hhwt-modal-error .hhwt-saved-dialog,
.modal.hhwt-modal-delete .hhwt-saved-dialog {
  width: 400px;
  margin: 30px auto;
}
.modal.hhwt-modal-loading h3,
.modal.hhwt-modal-loading h2,
.modal.hhwt-modal-error h3,
.modal.hhwt-modal-error h2,
.modal.hhwt-modal-delete h3,
.modal.hhwt-modal-delete h2 {
  font-size: 18px;
  text-align: center;
  font-family: "ProximaNova-Semibold";
}
.modal.hhwt-modal-loading h3,
.modal.hhwt-modal-error h3,
.modal.hhwt-modal-delete h3 {
  color: #586566;
}
.modal.hhwt-modal-loading h2,
.modal.hhwt-modal-error h2,
.modal.hhwt-modal-delete h2 {
  color: #00cccb;
}

@media only screen and (max-width: 991px) {
  .hhwt-modal-notification .dropdown-arrow,
  .hhwt-modal-notification-dialog .dropdown-arrow {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .hhwt-signup-content {
    width: 350px;
    width: auto;
  }

  .hhwt-nav-pills {
    padding-right: 0px;
  }

  .hhwt-signup-header.modal-header .close {
    margin-top: -12px;
  }

  .modal.hhwt-modal-loading .hhwt-saved-dialog,
  .modal.hhwt-modal-error .hhwt-saved-dialog,
  .modal.hhwt-modal-delete .hhwt-saved-dialog {
    width: 350px;
  }

  .saved-modal,
  .hhwt-modal-notification {
    left: auto;
    transform: translate(0%, 0%);
  }

  .saved-modal {
    right: 30px;
  }

  .hhwt-modal-notification {
    right: 0px;
    width: 100%;
  }

  .save-bucket-modal-content
    .save-modal-body
    .save-to-bucket
    .hhwt-text-input
    .hhwt-saved-btn {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 449px) {
  .modal.hhwt-modal-loading .hhwt-saved-dialog,
  .modal.hhwt-modal-error .hhwt-saved-dialog,
  .modal.hhwt-modal-delete .hhwt-saved-dialog {
    width: 100%;
    margin: 10px auto;
  }

  .save-bucket-modal-dialog,
  .hhwt-signup-content {
    width: 100%;
    margin: 10px auto;
  }
}
.modal.fade .hhwt-signup-form .modal-loading,
.modal.fade .hhwt-forget-form .modal-loading,
.modal.fade .hhwt-login-form .modal-loading {
  display: none;
}

.hhwt-modal-delete .hhwt-submit-btn.hhwt-delete-btn {
  background-color: #ff1f47;
  color: #fff;
  border: none;
  padding: 12px 10px;
  border-radius: 3px;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-family: "ProximaNova-Bold";

  margin-top: 16px;
}
.hhwt-modal-delete p {
  font-size: 15px;
  font-family: "ProximaNova";
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px;
}

.modal:not(#hhwt-modal-saved-list) {
  background: rgba(0, 0, 0, 0.4);
}

#hhwt-modal-notification.hhwt-modal-notification {
  background: rgba(0, 0, 0, 0);
  overflow: initial;
}

.modal-backdrop.fade.in {
  opacity: 0;
  position: relative;
}
.modal-backdrop {
  z-index: 500 !important;
}

.hhwt-form-login .alert,
.hhwt-signup-form .alert,
.hhwt-signup-account .alert,
.hhwt-modal-delete .alert,
.hhwt-forget-form .alert,
.hhwt-form-create-bucket .alert {
  display: none;
  text-align: center;
  font-family: "ProximaNova-SemiBold";
  font-size: 16px;
}
.hhwt-form-login .alert.alert-success,
.hhwt-form-login .alert.alert-danger,
.hhwt-signup-form .alert.alert-success,
.hhwt-signup-form .alert.alert-danger,
.hhwt-signup-account .alert.alert-success,
.hhwt-signup-account .alert.alert-danger,
.hhwt-modal-delete .alert.alert-success,
.hhwt-modal-delete .alert.alert-danger,
.hhwt-forget-form .alert.alert-success,
.hhwt-forget-form .alert.alert-danger,
.hhwt-form-create-bucket .alert.alert-success,
.hhwt-form-create-bucket .alert.alert-danger {
  display: block;
}

@media only screen and (max-width: 400px) {
  .saved-modal,
  .hhwt-modal-notification {
    right: 10px;
    width: 330px;
  }

  .saved-bucket-modal-dialog {
    width: 320px;
  }

  .save-bucket-modal-content
    .save-modal-body
    .save-to-bucket
    .hhwt-text-input
    .hhwt-input-bucketname {
    max-width: 65%;
  }
  .save-bucket-modal-content
    .save-modal-body
    .save-to-bucket
    .hhwt-text-input
    .hhwt-saved-btn.btn-cancel-bucket {
    margin-left: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .branch-banner-is-active .modal {
    top: 76px;
  }
  .branch-banner-is-active .modal.saved-modal {
    top: 124px;
  }
}
.planner-modal-body {
  font-family: "ProximaNova-Semibold";
  font-size: 14px;
  padding: 20px 15px;
}
.planner-modal-body h4 {
  color: #686f71;
  text-align: center;
  font-size: 18px;
  font-family: "ProximaNova-Bold";
}
.planner-modal-body p {
  background: #f5f6f8;
  padding: 10px;
  border-radius: 20px;
  color: #686f71;
  cursor: pointer;
  margin: 20px 0;
}
.planner-modal-body p.selected {
  color: #fff !important;
  background: #00cccb;
}
.planner-modal-body .hhwt-submit-btn {
  background-color: #00cccb;
  color: #fff;
  border: none;
  padding: 12px 10px;
  border-radius: 3px;
  outline: none;
  width: 100%;
  font-size: 16px;
  font-family: "ProximaNova-Bold";
  outline: none;
}

.save-to-bucket {
  display: none;
}

@media only screen and (max-width: 400px) {
  .hhwt-signup-content .hhwt-signup-header .hhwt-nav-pills li {
    margin-bottom: -2px;
  }
  .hhwt-signup-content .hhwt-signup-header .hhwt-nav-pills li a {
    padding: 7px 0px;
  }

  .hhwt-signup-content .hhwt-login-form .hhwt-modal-body,
  .hhwt-signup-content .hhwt-signup-form .hhwt-modal-body,
  .hhwt-signup-content .hhwt-forget-form .hhwt-modal-body {
    padding: 10px 15px 0px;
  }
}
@media only screen and (max-width: 350px) {
  .saved-modal {
    transform: translate(3%, 0%);
  }
}
.hhwt-modal-notification {
  background: none;
}

.hhwt-notification-modal-body {
  background: #fff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 0px;
}
.hhwt-notification-modal-body .notification-list {
  border-top: 1px solid #e2e2e2;
  display: flex;
  padding: 10px;
}
.hhwt-notification-modal-body .notification-list .notification-img {
  width: 80px;
  height: 80px;
}
.hhwt-notification-modal-body .no-notification {
  text-align: center;
}
.hhwt-notification-modal-body .no-notification .notification-icon {
  padding: 15px;
  height: auto;
  display: block;
  background-color: transparent;
  color: #566767;
}
.hhwt-notification-modal-body .no-notification p {
  padding-bottom: 10px;
}
.hhwt-notification-modal-body .notification-icon {
  font-size: 25px;
  font-weight: 700;
  padding: 28px;
  background-color: #f5f6f8;
  border-radius: 50%;
  color: #f2007b;
  height: 80px;
}
.hhwt-notification-modal-body p {
  font-family: ProximaNova-Regular;
  color: #566767;
  margin-bottom: 0;
  font-size: 16px;
}
.hhwt-notification-modal-body p.notification-time {
  font-size: 14px;
}
.hhwt-notification-modal-body .notification-dataelement .notification-icon {
  color: #f79638;
  padding: 23px 20px;
}
.hhwt-notification-modal-body .notification-content {
  padding-left: 10px;
}
.hhwt-notification-modal-body .notification-content a {
  color: #00cccb;
  font-family: ProximaNova-Bold;
  font-size: 13px;
  text-decoration: none;
}

.hhwt-modal-notification-dialog {
  width: 400px;
}
.hhwt-modal-notification-dialog .dropdown-arrow {
  margin-top: 2px;
  border-style: solid;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #f5f6f8 transparent;
  position: absolute;
  right: 13px;
  transition: 0.25s;
  top: -10px;
}
.hhwt-modal-notification-dialog .modal-header {
  background: #f5f6f8;
  font-family: "ProximaNova-Bold";
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.hhwt-modal-notification-dialog .modal-header .notification-title {
  color: #626c6d;
  font-size: 18px;
}
.hhwt-modal-notification-dialog .modal-header .hhwt-view-notify a {
  color: #00cccb;
  font-size: 13px;
  float: right;
  text-decoration: none;
}
.hhwt-modal-notification-dialog
  .modal-header
  .hhwt-view-notify
  a
  .fa-angle-right {
  padding: 0px 5px;
}
.hhwt-modal-notification-dialog .modal-body {
  padding: 0px;
}
.hhwt-modal-notification-dialog .hhwt-notify-list,
.hhwt-modal-notification-dialog .save-bucket-article {
  display: flex;
  border-bottom: 2px solid #eef0ef;
  padding: 10px;
  font-family: "ProximaNova-Semibold";
}
.hhwt-modal-notification-dialog .hhwt-notify-list img,
.hhwt-modal-notification-dialog .save-bucket-article img {
  width: 75px;
  height: 75px;
  margin-right: 15px;
}
.hhwt-modal-notification-dialog .hhwt-notify-list p,
.hhwt-modal-notification-dialog .save-bucket-article p {
  font-size: 13px;
  color: #585c5d;
  margin: 0px;
  margin-bottom: 3px;
}
.hhwt-modal-notification-dialog .hhwt-notify-list p a,
.hhwt-modal-notification-dialog .save-bucket-article p a {
  color: #08cccb;
  text-decoration: none;
  font-family: "ProximaNova-Bold";
}
.hhwt-modal-notification-dialog .hhwt-notify-list h3,
.hhwt-modal-notification-dialog .save-bucket-article h3 {
  font-size: 13px;
  font-family: "ProximaNova-Bold";
  color: #586566;
  margin-top: 5px;
  margin-bottom: 5px;
}
.hhwt-modal-notification-dialog .hhwt-notify-list h4,
.hhwt-modal-notification-dialog .save-bucket-article h4 {
  font-size: 13px;
  color: #b9bdbe;
  margin: 3px 0px 0px 0px;
}
.hhwt-modal-notification-dialog .hhwt-notify-list .hhwt-notification,
.hhwt-modal-notification-dialog .save-bucket-article .hhwt-notification {
  width: 75px;
  height: 75px;
  margin-right: 15px;
  background: #f5f6f8;
  border-radius: 50%;
  text-align: center;
  padding: 25px 25px;
  color: #ecaf47;
  font-size: 17px;
}
.hhwt-modal-notification-dialog
  .hhwt-notify-list
  .hhwt-notification.fa-thumbs-up,
.hhwt-modal-notification-dialog
  .save-bucket-article
  .hhwt-notification.fa-thumbs-up {
  color: #08cccb;
  font-size: 20px;
  padding: 25px 29px;
}
.hhwt-modal-notification-dialog .hhwt-notify-list .hhwt-notification.fa-heart,
.hhwt-modal-notification-dialog
  .save-bucket-article
  .hhwt-notification.fa-heart {
  color: #f70079;
  font-size: 17px;
  padding: 30px;
}
.hhwt-modal-notification-dialog .hhwt-notify-list.unread,
.hhwt-modal-notification-dialog .save-bucket-article.unread {
  background: #e6f2f2;
}
.hhwt-modal-notification-dialog .notification-modal-footer p {
  text-align: center;
  margin: 0px;
}
.hhwt-modal-notification-dialog .notification-modal-footer p a {
  font-size: 15px;
  color: #08cccb;
  font-family: "ProximaNova-Bold";
  text-decoration: none;
}

@media (min-width: 0px) and (max-width: 767px) {
  .hhwt-modal-notification-dialog .dropdown-arrow {
    right: 85px;
  }
}
@media (min-width: 0px) and (max-width: 400px) {
  .hhwt-modal-notification,
  .hhwt-modal-notification-dialog {
    width: 100%;
  }
  .hhwt-modal-notification .dropdown-arrow,
  .hhwt-modal-notification-dialog .dropdown-arrow {
    right: 90px;
  }
}
.modal.fade .hhwt-modal-notification-dialog {
  -webkit-transform: translate(0, -7%);
  -ms-transform: translate(0, -7%);
  -o-transform: translate(0, -7%);
  transform: translate(0, -7%);
}

.modal.fade.in .hhwt-modal-notification-dialog {
  -webkit-transform: translate(0, 0%);
  -ms-transform: translate(0, 0%);
  -o-transform: translate(0, 0%);
  transform: translate(0, 0%);
}

.hhwt-modal-city-guide .modal-dialog {
  min-width: 850px;
}
.hhwt-modal-city-guide .modal-dialog .modal-content {
  min-height: 350px;
}
.hhwt-modal-city-guide .modal-dialog .modal-content .modal-header {
  border-bottom: none;
}
.hhwt-modal-city-guide .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
}
.hhwt-modal-city-guide .modal-dialog .modal-content .modal-body {
  padding: 25px;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-img {
  padding: 0px;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-img
  img {
  width: 248px;
  height: 248px;
  display: block;
  margin: 0 auto;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text {
  padding-left: 0px;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  span {
  font-size: 12px;
  font-family: "ProximaNova-Bold";
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  h1 {
  font-size: 33px;
  margin: 20px 0 20px;
  font-family: "ProximaNova-Bold";
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  h3 {
  font-size: 15px;
  margin-bottom: 30px;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  form
  .user-email {
  min-width: 270px;
  border-radius: 25px;
  padding: 18px;
  margin-right: 15px;
  font-size: 15px;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  form
  .submit-subscribe {
  border-radius: 25px;
  padding: 9px;
  min-width: 150px;
  border: none;
  font-family: "ProximaNova-Semibold";
  font-size: 15px;
}

.hhwt-modal-city-guide.dark .modal-dialog .modal-content {
  background: #576767 !important;
}
.hhwt-modal-city-guide.dark .modal-dialog .modal-content .modal-header .close {
  color: #fff;
}
.hhwt-modal-city-guide.dark
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  span {
  color: #ea9c43;
}
.hhwt-modal-city-guide.dark
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  h1 {
  color: #fff;
}
.hhwt-modal-city-guide.dark
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  h3 {
  color: #fff;
}
.hhwt-modal-city-guide.dark
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  form
  .submit-subscribe {
  color: #fff;
  background: #f69c38;
}

.hhwt-modal-city-guide .modal-dialog .modal-content {
  background: #fff !important;
}
.hhwt-modal-city-guide .modal-dialog .modal-content .modal-header .close {
  color: #576767;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  .city-guide-content
  p {
  display: flex;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  .city-guide-content
  p
  span {
  color: #ea9c43;
  font-size: 15px;
  font-family: "ProximaNova-Bold";
  margin-top: 3px;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  .city-guide-content
  p
  img {
  height: 25px;
  width: 25px;
  padding: 0px;
  margin-right: 10px;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  h1 {
  color: #576767;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  h3 {
  color: #576767;
}
.hhwt-modal-city-guide
  .modal-dialog
  .modal-content
  .modal-body
  .city-guide-container
  .city-text
  form
  .submit-subscribe {
  color: #fff;
  background: #f69c38;
}

@media (max-width: 991px) {
  .hhwt-modal-city-guide .modal-dialog {
    min-width: 1px;
  }
  .hhwt-modal-city-guide .modal-dialog .modal-body {
    padding: 15px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-img {
    padding: 0px;
    margin-bottom: 25px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-text {
    padding: 0px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-text
    .city-guide-content
    p
    img {
    margin: 0px 10px 0px auto;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-text
    .city-guide-content
    p
    span {
    margin: 3px auto 0px 0px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-text
    h1 {
    text-align: center;
    font-size: 28px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-text
    h3 {
    text-align: center;
    font-size: 14px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-text
    form {
    text-align: center;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-text
    form
    .user-email {
    margin-bottom: 15px;
    margin-right: 0px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-body
    .city-guide-container
    .city-text
    form
    .submit-subscribe {
    text-align: center;
    margin: 0 auto;
    display: block;
  }
}
.hhwt-modal-city-guide:before {
  content: "";
  display: inline-block;
  height: 25%;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .hhwt-modal-city-guide .modal-dialog {
    margin: 55px 0 0;
  }
  .hhwt-modal-city-guide .modal-dialog .modal-content {
    border-radius: 0;
  }
  .hhwt-modal-city-guide .modal-dialog .modal-content .modal-body {
    padding: 0px 25px 25px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-content
    .modal-body
    .city-guide-container
    .city-text
    .city-guide-content
    p
    img {
    margin: 0px 10px 0px auto;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-content
    .modal-body
    .city-guide-container
    .city-text
    .city-guide-content
    p
    span {
    margin: 3px auto 0px 0px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-content
    .modal-body
    .city-guide-container
    .city-text
    h1 {
    font-size: 24px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-content
    .modal-body
    .city-guide-container
    .city-text
    h3 {
    margin-bottom: 10px;
  }
  .hhwt-modal-city-guide
    .modal-dialog
    .modal-content
    .modal-body
    .city-guide-container
    .city-text
    form
    .user-email {
    width: 100%;
  }

  .hhwt-modal-city-guide:before {
    display: none;
  }
}
.error404 .fluid404 {
  background: #f5f6f8;
  padding: 0px;
}
.error404 .fluid404 .logo404 {
  text-align: center;
  padding: 0px;
}
.error404 .fluid404 .container404 {
  padding: 35px 0px;
}
.error404 .fluid404 .content404 {
  text-align: center;
  padding-top: 60px;
}
.error404 .fluid404 .content404 h1 {
  font-size: 190px;
  font-family: "ProximaNova-Bold";
  color: #000;
}
.error404 .fluid404 .content404 p {
  font-size: 16px;
  color: #888;
  font-family: "ProximaNova-Semibold";
}
.error404 .fluid404 .hhwt-page-aside {
  background: #f5f6f8;
}

@media only screen and (max-width: 767px) {
  .error404 .fluid404 img {
    max-width: 100%;
  }
  .error404 .fluid404 .content404 {
    padding-top: 0px;
  }
  .error404 .fluid404 .content404 h1 {
    font-size: 150px;
  }
  .error404 .fluid404 .container404 {
    padding: 0px;
  }
}
body.modal-open {
  overflow: auto;
}

.page:not(.home) .content-title {
  margin-bottom: 0px;
  padding: 30px 0px;
}
.page:not(.home) .content-title h4 {
  margin-top: 0px;
  font-size: 36px;
  width: 100%;
}
.page:not(.home) .content-title .btn-more {
  display: none;
}

.hhwt-page-fluid,
.fluid404 {
  border-top: 1px solid #ddd;
}
.hhwt-page-fluid .hhwt-page-content p,
.hhwt-page-fluid .hhwt-page-aside p,
.fluid404 .hhwt-page-content p,
.fluid404 .hhwt-page-aside p {
  font-family: "ProximaNova";
  margin-bottom: 10px;
  font-size: 16px;
}
.hhwt-page-fluid .hhwt-page-content h4,
.hhwt-page-fluid .hhwt-page-aside h4,
.fluid404 .hhwt-page-content h4,
.fluid404 .hhwt-page-aside h4 {
  font-family: "ProximaNova-Semibold";
  margin-bottom: 10px;
  margin-top: 15px;
  color: #556767;
  font-size: 24px;
}
.hhwt-page-fluid .hhwt-page-content h3,
.hhwt-page-fluid .hhwt-page-aside h3,
.fluid404 .hhwt-page-content h3,
.fluid404 .hhwt-page-aside h3 {
  font-family: "ProximaNova-Semibold";
  margin-bottom: 10px;
  margin-top: 15px;
  color: #556767;
  font-size: 28px;
}
.hhwt-page-fluid .hhwt-page-content h5,
.hhwt-page-fluid .hhwt-page-aside h5,
.fluid404 .hhwt-page-content h5,
.fluid404 .hhwt-page-aside h5 {
  font-family: "ProximaNova-Semibold";
  margin-bottom: 10px;
  margin-top: 10px;
  color: #556767;
  font-size: 18px;
}
.hhwt-page-fluid .hhwt-page-content img,
.hhwt-page-fluid .hhwt-page-aside img,
.fluid404 .hhwt-page-content img,
.fluid404 .hhwt-page-aside img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}
.hhwt-page-fluid .hhwt-page-content ol,
.hhwt-page-fluid .hhwt-page-content ul,
.hhwt-page-fluid .hhwt-page-aside ol,
.hhwt-page-fluid .hhwt-page-aside ul,
.fluid404 .hhwt-page-content ol,
.fluid404 .hhwt-page-content ul,
.fluid404 .hhwt-page-aside ol,
.fluid404 .hhwt-page-aside ul {
  padding-left: 40px;
}
.hhwt-page-fluid .hhwt-page-content li,
.hhwt-page-fluid .hhwt-page-aside li,
.fluid404 .hhwt-page-content li,
.fluid404 .hhwt-page-aside li {
  list-style: decimal;
  padding: 5px 0px;
  font-family: "ProximaNova";
  font-size: 16px;
}
.hhwt-page-fluid .hhwt-page-content a,
.hhwt-page-fluid .hhwt-page-aside a,
.fluid404 .hhwt-page-content a,
.fluid404 .hhwt-page-aside a {
  color: #00cccb;
  outline: none;
}
.hhwt-page-fluid .hhwt-page-content a img,
.hhwt-page-fluid .hhwt-page-aside a img,
.fluid404 .hhwt-page-content a img,
.fluid404 .hhwt-page-aside a img {
  margin: 5px 20px 20px 0px;
}
.hhwt-page-fluid .hhwt-page-content a:hover,
.hhwt-page-fluid .hhwt-page-content a:visited,
.hhwt-page-fluid .hhwt-page-content a:focus,
.hhwt-page-fluid .hhwt-page-content a:active,
.hhwt-page-fluid .hhwt-page-aside a:hover,
.hhwt-page-fluid .hhwt-page-aside a:visited,
.hhwt-page-fluid .hhwt-page-aside a:focus,
.hhwt-page-fluid .hhwt-page-aside a:active,
.fluid404 .hhwt-page-content a:hover,
.fluid404 .hhwt-page-content a:visited,
.fluid404 .hhwt-page-content a:focus,
.fluid404 .hhwt-page-content a:active,
.fluid404 .hhwt-page-aside a:hover,
.fluid404 .hhwt-page-aside a:visited,
.fluid404 .hhwt-page-aside a:focus,
.fluid404 .hhwt-page-aside a:active {
  text-decoration: none;
}
.hhwt-page-fluid .hhwt-page-aside > div,
.fluid404 .hhwt-page-aside > div {
  margin-bottom: 40px;
  max-width: 100%;
}
.hhwt-page-fluid .hhwt-page-aside > div iframe,
.fluid404 .hhwt-page-aside > div iframe {
  max-width: 100%;
}
.hhwt-page-fluid .hhwt-page-aside h4,
.fluid404 .hhwt-page-aside h4 {
  font-family: "ProximaNova-Bold";
}
.hhwt-page-fluid .hhwt-page-aside ol,
.hhwt-page-fluid .hhwt-page-aside ul,
.fluid404 .hhwt-page-aside ol,
.fluid404 .hhwt-page-aside ul {
  padding-left: 0px;
}
.hhwt-page-fluid .hhwt-page-aside li,
.fluid404 .hhwt-page-aside li {
  list-style: none;
  padding: 5px 0px;
  border-bottom: 1px solid #e5e5e5;
}
.hhwt-page-fluid .hhwt-page-aside li:first-child,
.fluid404 .hhwt-page-aside li:first-child {
  border-top: 1px solid #e5e5e5;
}
.hhwt-page-fluid .hhwt-page-content,
.fluid404 .hhwt-page-content {
  background-color: #fff;
  margin-right: 30px;
  padding: 25px;
}
.hhwt-page-fluid .hhwt-page-content > :first-child,
.fluid404 .hhwt-page-content > :first-child {
  margin-top: 0px;
}
.hhwt-page-fluid .hhwt-page-aside,
.fluid404 .hhwt-page-aside {
  margin-right: -30px;
}

.hhwt-page-fluid .hhwt-page-aside {
  background: fff;
}

.container.hhwt-page-content-container {
  padding: 25px 0px;
}

.carousel-inner > .item {
  -webkit-transition: -webkit-transform 0.6s linear;
  -o-transition: -o-transform 0.6s linear;
  transition: transform 0.6s linear;
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .page:not(.home) .content-title {
    padding-left: 25px;
  }

  .container.hhwt-page-content-container {
    padding: 20px;
  }

  .hhwt-page-fluid,
  .fluid404 {
    padding: 0px;
  }
  .hhwt-page-fluid .hhwt-page-content,
  .fluid404 .hhwt-page-content {
    padding-top: 15px;
    margin-right: 0px;
  }
  .hhwt-page-fluid .hhwt-page-aside,
  .fluid404 .hhwt-page-aside {
    margin-top: 20px;
    margin-right: 0px;
  }

  body.modal-open {
    overflow: scroll;
  }
}

/*# sourceMappingURL=hhwt.css.map */

/* Iframe syle responsive*/

/* start of desktop styles */

@media screen and (max-width: 991px) {
  /* start of large tablet styles */
  #google-center-div #google_image_div .i-amphtml-layout-size-defined {
    width: 505px !important;
    height: 80px !important;
  }
  #google-center-div #google_image_div {
    position: inherit;
  }

  #google_center_div {
    text-align: left !important;
  }
  #google_image_div {
    width: 500px !important;
  }
}

@media screen and (max-width: 767px) {
  /* start of medium tablet styles */
  #google-center-div #google_image_div .i-amphtml-layout-size-defined {
    width: 505px !important;
    height: 80px !important;
  }
  #google-center-div #google_image_div {
    position: inherit;
  }

  #google_center_div {
    text-align: left !important;
  }
  #google_image_div {
    width: 500px !important;
  }

  .stickyMenu {
    bottom: 0px !important;
  }
  .plan_trip {
    display: none !important;
  }

  .hhwt-create-trip-form,
  .hhwt-past-trip-form {
    margin-bottom: 0px;
  }

  .hhwt-signup-content .hhwt-create-trip-form .hhwt-popup-title,
  .hhwt-signup-content .hhwt-past-trip-form .hhwt-popup-title {
    font-size: 24px;
    line-height: 29px;
  }
  .hhwt-form-newtrip .alert {
    padding: 0px;
  }
  .fieldLabel {
    font-size: 14px;
  }
  .hhwt-signup-content .form-group.cities_list a {
    font-size: 14px;
    padding: 4px 6px;
  }
  .hhwt-signup-content .hhwt-create-trip-form .form-group {
    margin-bottom: 12px;
  }
  .hhwt-signup-content .hhwt-create-trip-form .form-group.cities_list {
    margin-bottom: 20px;
  }
  #dates_range,
  #month_year_range {
    margin-bottom: 25px;
  }
  .hhwt-signup-content .form-group.dont_know_dates label {
    font-size: 14px;
    line-height: 17px;
  }
  .hhwt-signup-content .hhwt-submit-btn.next_btn {
    width: 76px;
    height: 40px;
    padding: 0px;
    font-size: 14px;
  }
  .hhwt-signup-content #month_year_range {
    margin-bottom: 30px;
  }
  .form-control {
    padding: 6px 11px;
    line-height: 16px;
  }
  .hhwt-signup-content #dates_range .no_pad_lft,
  .hhwt-signup-content #month_year_range .no_pad_lft {
    padding-right: 5px;
  }
  .hhwt-signup-content #dates_range .no_pad_rgt,
  .hhwt-signup-content #month_year_range .no_pad_rgt {
    padding-left: 5px;
  }

  .hhwt-trip-after-form .modal-header .close,
  .hhwt-past-trip-after-form .modal-header .close {
    margin-top: 0px;
  }

  .hhwt-trip-after-form .hhwt-modal-body .text-center img,
  .hhwt-past-trip-after-form .hhwt-modal-body .text-center img {
    max-width: 80px;
    margin-top: 5px;
  }
  .hhwt-signup-content .hhwt-trip-after-form h3,
  .hhwt-signup-content .hhwt-past-trip-after-form h3 {
    font-size: 27px;
    line-height: 32px;
  }
  .hhwt-signup-content .hhwt-trip-after-form p,
  .hhwt-signup-content .hhwt-past-trip-after-form p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }
  .hhwt-trip-after-form,
  .hhwt-past-trip-after-form {
    margin-bottom: 0px;
  }
  .hhwt-signup-content .hhwt-trip-after-form .form-group .btn,
  .hhwt-signup-content .hhwt-past-trip-after-form .form-group .btn {
    margin-bottom: 16px;
  }
  .hhwt-signup-content .hhwt-trip-after-form .form-group.trip_btns,
  .hhwt-signup-content .hhwt-past-trip-after-form .form-group.trip_btns {
    margin-bottom: 0px;
  }
  .hhwt-signup-content .hhwt-trip-after-form .form-group .take_prf_btn .btn,
  .hhwt-signup-content
    .hhwt-past-trip-after-form
    .form-group
    .take_prf_btn
    .btn {
    margin-bottom: 0px;
  }

  .no_pad_mb {
    padding: 0px;
  }

  .profile_page {
    margin-top: 0;
    padding: 0;
  }
  .profile_page .hhwt-author-details {
    width: 100%;
    box-shadow: none;
    padding: 16px 20px;
  }
  .profile_page .hhwt-author-avatar {
    margin-top: -38px;
  }
  .prfile_edit_sect {
    position: relative;
  }
  .hhwt-author-container .hhwt-author-details .hhwt-author-avatar img {
    width: 88px;
    height: 88px;
  }
  .pro_travel_img img {
    max-width: 24px;
  }
  .countsTtl {
    font-size: 12px;
  }
  .countsTtl span {
    font-size: 20px;
  }

  .profile_page .hhwt-author-posts {
    padding-left: 0px !important;
    margin-top: 16px;
  }
  .postTabCnt {
    border-radius: 0px;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16);
    height: auto;
  }
  .postTabCnt ul {
    padding-top: 11px;
  }
  .postTabCnt ul li {
    font-size: 12px;
    width: 33.33%;
    padding: 0px;
    margin: 0px;
  }
  .postTabCnt ul li a {
    background-position: top center !important;
    padding-left: 0px;
    padding-top: 28px;
  }

  .tripGrid {
    width: 100%;
  }
  .createTripCnt {
    padding: 0px 24px;
  }

  .tripsList .content-title h4 {
    font-size: 20px;
  }
  .tripsList .content-title {
    margin-bottom: 16px;
  }

  .tripImag {
    height: 176px;
  }
  .prf_set_icon {
    height: 32px;
    width: 32px;
    padding-top: 5px;
  }
  .prf_set_icon img {
    width: 64%;
  }

  .tripGrid {
    margin-bottom: 16px;
  }
  .tripName {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .tripName small {
    font-size: 12px;
    line-height: 17px;
  }
  .tripDates {
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 6px;
    line-height: 18px;
  }

  .nextTripCls {
    font-size: 13px;
    padding: 11px 16px;
    line-height: 18px;
  }
  .past_trip_grid .tripImag {
    height: 132px;
  }
  .past_trip_grid .tripName {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
  }
  .past_trip_grid .tripDates {
    font-size: 12px;
    line-height: 17px;
    margin-top: 3px;
  }

  .trip_tree_img {
    margin-top: 0px;
  }
  .trip_tree_img {
    width: 75px;
  }
  .next_trip_text {
    font-size: 20px;
    line-height: 24px;
    margin-top: 24px;
  }
  .get_trip_txt {
    font-size: 14px;
    line-height: 19px;
    margin-top: 8px;
  }
  .addTrip_Btn {
    padding: 10px 0 13px;
    width: 96px;
    font-size: 14px;
    line-height: 19px;
    height: 40px;
    margin-top: 24px;
  }

  .hhwt-navbar-fluid .hhwt-navbar-container .hhwt-navbar .hhwt-mob-menu {
    margin-bottom: 0px;
    padding-top: 0px;
  }
  .navbar {
    min-height: auto;
    padding-bottom: 2px;
  }
  .hhwt-navbar-fluid
    .hhwt-navbar-container
    .hhwt-navbar
    .hhwt-mob-menu
    .hhwt-mob-search {
    margin-top: 7px;
  }
  .hhwt-mob-menu .hhwtmainmenu.avatar {
    margin-top: 1px;
  }
  ul.hhwt-nav-menu-right > li ul.dropdown-menu.menucontainer {
    top: 41px;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 5px 5px;
    right: -17px;
  }

  .navbar-collapse.hhwt-mob-menu {
    position: fixed;
    top: 58px;
    left: -220px;
    width: 220px !important;
    padding: 16px 0px !important;
    z-index: 10;
    background: #fff;
    min-height: 600px;
    height: 100%;
    transition: all ease 0.4s;
    overflow: hidden;
  }
  .navbar-collapse.hhwt-mob-menu.show {
    left: -1px;
  }
  .mb_menu_nav .hhwtmainmenu {
    width: 100%;
    color: #333333 !important;
    font-size: 14px !important;
    line-height: 20px;
    padding: 0px;
    margin-bottom: 16px;
    padding: 0px 16px;
  }
  .mb_menu_nav .hhwt-nav-menu {
    display: inherit !important;
    text-align: left !important;
  }
  .mb_menu_nav .hhwtmainmenu > a {
    color: #333333 !important;
    display: inline-block !important;
    width: 100%;
  }
  .hhwt-mob-menu .hhwtmainmenu .hhwt_menu_icons {
    margin-right: 16px;
  }

  .hhwt-mob-menu .nav > li.hhwtmainmenu > a .caret {
    float: right;
  }
  .mb_menu_nav .hhwtmainmenu .hhwt_menu_icons img {
    margin-top: -2px;
  }

  .mb_menu_nav ul.hhwt-nav-menu > li ul.dropdown-menu.menucontainer {
    position: inherit;
    min-width: inherit;
    margin-left: -16px;
    margin-top: 16px;
    width: 220px !important;
    box-shadow: none;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-radius: 0px;
  }
  .mb_menu_nav ul.hhwt-nav-menu .hhwtmainsubmenu > a {
    padding: 8px 16px 8px 17px !important;
  }
  ul.hhwt-nav-menu > li .hhwtmainsubmenu.active,
  ul.hhwt-nav-menu-right > li .hhwtmainsubmenu.active {
    border: 0px;
    margin-top: 0px;
    padding: 8px 0;
  }
  .hhwt-mob-menu ul.hhwt-nav-menu > li .hhwtmainsubmenu.active.menu_hk {
    margin-bottom: 0;
    border-bottom: 0px;
    padding-bottom: 6px;
  }

  .category_scroll {
    height: 100%;
    align-items: center;
    width: 610px;
    justify-content: space-between;
  }
  .pro_bucket_list.all .indiana-scroll-container--hide-scrollbars {
    padding-left: 0px;
  }

  .hhwt-author-container .hhwt-author-details {
    padding-top: 25px;
  }

  .hhwt-navbar-fluid {
    height: 58px;
  }
  .header-menu-opener {
    margin-top: 12px;
  }
  .mb_rght_div .hhwt-user-menu {
    margin-top: 1px;
  }
  .hhwt-mob-menu .hhwtmainmenu.avatar {
    margin-top: 4px;
  }

  .menu_enable_bg {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    left: 0px;
    z-index: 0;
  }

  .add_trip {
    font-size: 14px;
    height: auto;
    padding: 4px 10px;
    margin-top: -6px;
    line-height: 18px;
  }
  .plus_txt {
    font-size: 20px;
    line-height: 24px;
  }

  .hhwt-author-container
    .hhwt-author-posts
    .hhwt-author-posts-grid.author_page
    .hhwt-nested-posts
    .col-md-4:nth-child(2n) {
    padding-right: 0px;
  }

  .hhwt-child-cat-link .hhwt-nav-pills div:hover > a {
    border-bottom: 3px solid #fff;
    text-decoration: none;
    color: #666 !important;
  }

  .authrDet {
    height: 22px;
  }

  .hhwt_lrg_srch {
    position: absolute;
  }
  .hhwt-navbar-fluid {
    position: relative;
  }
  .stickyMenu {
    text-align: center !important;
  }
  .stickyMenu .hhwt-btns {
    float: inherit !important;
    display: inline-block !important;
    margin-bottom: 0px;
  }

  .hhwt-destination-fluid.noPadTop {
    padding-top: 0px;
  }

  .article_head_btn {
    position: relative;
    z-index: 1;
    width: 50%;
    float: right;
  }
  .mb_style {
    width: 30px;
    height: 30px;
    font-size: 18px;
    padding-top: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    position: relative;
    background: #fff;
    border-radius: 50%;
    text-align: center;
    float: right;
  }

  .simple-small .save-bucket,
  .save-bucket-login,
  .home-save-bucket {
    width: 100%;
    text-align: right;
    height: 60px;
    margin-top: 0px;
    margin-right: 0px;
    padding-top: 0px;
    background: none;
    box-shadow: none;
    max-height: inherit;
  }

  .prfBgImg.header_icon {
    width: 22px;
    height: 22px;
    margin-top: 3px;
  }

  .hhwt-nav-menu-right .user-name.hhwtmainmenu {
    padding-top: 0px !important;
  }

  #hhwt-form-edit-profile.hhwt-form-registration {
    width: 100%;
  }
  .no_pad_rgt_mb {
    padding-right: 0px;
  }

  #hhwt_modal_edit_profile {
    background: none !important;
    position: inherit;
    float: left;
    width: 100%;
  }
  #hhwt_modal_edit_profile .modal-dialog {
    margin: 14px 0 0;
    transform: inherit;
    height: auto;
  }
  #hhwt_modal_edit_profile .hhwt-signup-dialog .modal-content {
    width: 100%;
    border: 0px;
    border-radius: 0px;
  }

  .modal-open .open_edit_prf {
    display: none;
  }
  .hhwt-signup-content .hhwt-edit-prof-form .alert {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 600px) {
  .hhwt-author-container
    .hhwt-author-posts
    .pro_bucket_list.all
    .hhwt-author-posts-grid
    .hhwt-nested-posts
    .col-md-4:nth-child(2n + 1) {
    padding-left: 0px;
    padding-right: 7.5px;
  }

  .hhwt-author-container
    .hhwt-author-posts
    .pro_bucket_list.all
    .hhwt-author-posts-grid
    .hhwt-nested-posts
    .col-md-4:nth-child(2n) {
    padding-left: 7.5px;
    padding-right: 0px;
  }
  .pro_bucket_list.all .artMrgnBtm {
    margin-bottom: 20px;
  }

  .pro_bucket_list.all .hhwt-nested-posts {
    margin-left: 0px;
  }

  .hhwt-child-cat-link .hhwt-nav-pills li a.cat_active,
  .hhwt-child-cat-link .hhwt-nav-pills div a.cat_active {
    padding: 20px 15px 14px !important;
  }

  .hhwt-edit-prof-form button.close img {
    width: 75%;
    margin-right: -5px;
  }
}

@media screen and (max-width: 579px) {
  #hhwt-modal-signup {
    padding-right: 0px !important;
  }
  .hhwt-signup-dialog .modal-content {
    width: 94%;
    padding: 16px;
  }
  .hhwt-signup-content h3 {
    font-size: 24px;
    line-height: 29px;
    color: #181818;
  }
  .hhwt-signup-content .hhwt-login-form .hhwt-modal-body,
  .hhwt-signup-content .hhwt-signup-form .hhwt-modal-body,
  .hhwt-signup-content .hhwt-forget-form .hhwt-modal-body {
    padding: 28px 0px 0px;
  }
  .hhwt-signup-content p {
    font-size: 14px;
    line-height: 19px;
    margin-top: 0px;
  }
  .hhwt-form-registration,
  .hhwt-form-login,
  .hhwt-forget-password {
    width: 100%;
  }
  .social-icon-box {
    width: 20px;
    height: 20px;
  }
  .hhwt-signup-content .form-group .btn.form-control {
    font-size: 14px;
    line-height: 19px;
  }
  .hhwt-form-login .divider-text {
    font-size: 10px;
    line-height: 14px;
    top: 6px;
  }
  .btn-social-fb .social-icon-box img {
    margin-left: 0px;
    width: 100%;
  }
  .btn-social-g .social-icon-box img {
    margin-top: -1px;
    width: 90%;
  }
  .social-icon-box img {
    width: 100%;
  }
  .hhwt-signup-content .hhwt-terms-condition {
    font-size: 12px !important;
    line-height: 17px !important;
  }
  .loginLink {
    font-size: 14px;
    line-height: 19px;
  }

  .hhwt_signup_logo img {
    max-width: 80px;
  }
  .hhwt-push-notify-form {
    width: 100%;
    margin-top: 25px;
  }

  .hhwt-signup-content .hhwt-push-notify-form p {
    font-size: 12px;
    line-height: 17px;
    margin-top: 10px;
    margin-bottom: 24px;
  }
  .hhwt-signup-content .hhwt-signup-after-form .hhwt-submit-btn {
    height: 40px;
    padding: 0px;
    width: 100%;
  }
  .allow_push_notify {
    margin-top: 0px;
    margin-left: 0px;
    width: 100%;
    font-size: 12px;
    line-height: 16px;
  }
  .allow_push_notify label::after {
    font-size: 12px;
    line-height: 15px;
    top: 0px;
  }
  .allow_push_notify.agree_terms_field label {
    padding-top: 2px;
  }

  .bucket_list_scroll {
    height: 100%;
    align-items: center;
    width: 600px;
    justify-content: space-between;
  }
  .pro_bucket_list .indiana-scroll-container--hide-scrollbars {
    padding-left: 12px;
  }
  .pro_bucket_list .hhwt-nested-posts {
    margin-left: -13px;
  }

  .simple-small header {
    height: 180px;
  }

  .profile_page .hhwt-author-container .simple-small header {
    height: 192px;
  }
}

@media screen and (max-width: 520px) {
  .pro_bucket_list .articlesThumbImg,
  .defaultImg {
    height: 135px !important;
  }
  .hhwt-nav-menu-right .hhwtmainmenu {
    padding-top: 0px;
  }
  .trip_btns .col-xs-12 {
    width: 100%;
  }

  .hhwt-signup-content .trip_btns .hhwt-submit-btn {
    font-size: 14px;
    line-height: 19px;
    padding: 10px;
  }
  .hhwt-signup-content .trip_btns a.hhwt-submit-btn {
    margin-bottom: 16px;
  }
  #edit_dates_range .no_pad_lft,
  #dates_range .no_pad_lft {
    padding-right: 5px;
  }
  #edit_dates_range .no_pad_rgt,
  #dates_range .no_pad_rgt {
    padding-left: 5px;
  }

  iframe[src^="//giphy.com/embed/"] {
    max-height: 330px !important;
  }
}

@media screen and (max-width: 479px) {
  /* start of phone styles */
  #google-center-div #google_image_div .i-amphtml-layout-size-defined {
    width: 505px !important;
    height: 80px !important;
  }
  #google-center-div #google_image_div {
    position: inherit;
  }

  #google_center_div {
    text-align: left !important;
  }
  #google_image_div {
    width: 500px !important;
  }

  .save-bucket-modal-content
    .save-modal-body
    .save-to-bucket
    .hhwt-text-input
    .hhwt-input-bucketname,
  .save-bucket-modal-content
    .save-modal-body
    .save-to-bucket
    .hhwt-select-list
    select {
    width: 62%;
  }
  .save-bucket-modal-content
    .save-modal-body
    .save-to-bucket
    .hhwt-select-list
    .hhwt-create-new,
  .save-bucket-modal-content
    .save-modal-body
    .save-to-bucket
    .hhwt-text-input
    .hhwt-saved-btn {
    width: 34%;
  }
}

@media screen and (max-width: 374px) {
  .changePic {
    float: right;
    margin-top: 15px;
  }
  .removePic {
    margin-top: 6px;
    width: 127px;
  }
}

/* .article-content p iframe,
.hhwt-page-content p iframe {
  margin: 0 auto;
}
*/

.article-content center iframe {
  margin: 0 auto !important;
}

@media (min-width: 900px) and (max-width: 1400px) {
  .article-content iframe {
    margin: 0 247px;
  }
  .typeform-widget iframe {
    margin: 0px;
  }
}

.page-loading {
  width: 100%;
  height: 100%;

  position: fixed;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.25);
}
