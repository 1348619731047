/**
 * /*
 * Theme Name: HHWT 
 * Theme URI: 
 * Description: A HHWT Blog Theme
 * Author: Shenll Team
 * Author URI: http://shenll.com
 * Version: 1.0.0
 * License: GNU General Public License version 3.0
 * License URI: http://www.gnu.org/licenses/gpl-3.0.html
 *
 * @format
 */

/* Global reset */
/* Based upon 'reset.css' in the Yahoo! User Interface Library: http://developer.yahoo.com/yui */

/**** header & Footer css start ***/
@font-face {
  font-family: 'ProximaNova-Regular';
  src: url('../fonts/proximanova/proximanova-regular-webfont.eot');
  src: url('../fonts/proximanova/proximanova-regular-webfont.woff')
      format('woff'),
    url('../fonts/proximanova/proximanova-regular-webfont.ttf')
      format('truetype'),
    url('../fonts/proximanova/proximanova-regular-webfont.svg#proximanovaregular')
      format('svg');
  -webkit-font-smoothing: antialiased;
  /* This needs to be set or some font faced ../fonts look bold on Mac in Chrome/Webkit based browsers. */
  -moz-osx-font-smoothing: grayscale;
  /* Fixes font bold issue in Firefox version 25+ on Mac */
}

*,
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
label,
fieldset,
input,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul,
li {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before,
q:after {
  content: '';
}
body {
  padding: 0px !important;
  background-color: #fff;
}
/* Global reset-RESET */
/* The below restores some sensible defaults */
strong,
b {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}
em {
  font-style: italic;
}
a img {
  border: none;
} /* Gets rid of IE's blue borders */
/**/

/** Article page css **/

.hhwt-halal-article img.size-full {
  width: 100%;
  height: auto;
}

.hhwt-fluid .hhwt-article-tip {
  padding-left: 0px;
  width: 48%;
  padding-right: 0px;
  float: right;
  margin-right: 29px;
  min-height: 295px;
  background-color: #f5f6f8;
}
.hhwt-fluid .hhwt-article-tip::first-child {
  margin-right: 15px;
}
.hhwt-fluid .hhwt-article-tip .hhwt-websites-tip {
  width: auto;
  background-color: #f5f6f8;
  padding: 0px;
}

.hhwt-fluid .hhwt-halal-article p {
  clear: both;
}
.hhwt-fluid .hhwt-article-tip .hhwt-view-details {
  background-color: #f5f6f8;
  width: auto;
}

.hhwt-fluid .hhwt-article-tip .hhwt-websites-tip .hhwt-special:focus {
  outline: none;
}
.hhwt-fluid .hhwt_view_article .hhwt_view_button button:focus {
  outline: none;
}

.yarpp-related-none {
  display: none !important;
}

/* floater widget */
#hhwt-modal-widget .hhwt-modal-widget {
  background-size: cover;
  height: 130px;
  width: 340px;
  padding: 10px;
  display: flex;
  position: absolute;
  box-shadow: 1px 1px 7px 1px #333;
}
#hhwt-modal-widget .modal-close,
.modal-close-mbl {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
  font-size: 10px;
  padding: 4px;
  font-weight: 600;
}
#hhwt-modal-widget .modal-close span {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: 400;
  cursor: pointer;
  color: #333;
}
#hhwt-modal-widget .modal-close span:hover {
  text-decoration: underline;
}
#hhwt-modal-widget .hhwt-modal-image {
  width: 30%;
  padding: 17px 10px 10px;
}
#hhwt-modal-widget .hhwt-modal-image img {
  width: 90px;
  height: 90px;
}
#hhwt-modal-widget .hhwt-modal-detail {
  width: 70%;
  padding-top: 10px;
}
#hhwt-modal-widget .hhwt-modal-detail p {
  font-family: 'ProximaNova-Semibold';
  font-size: 12px;
  color: #333;
  margin-bottom: 10px;
}
#hhwt-modal-widget .hhwt-modal-detail a {
  font-family: 'ProximaNova-Semibold';
  font-size: 11px;
  border: none;
  background: rgb(179, 231, 227);
  line-height: 14px;
  color: #333;
  padding: 7px 13px;
}
.hhwt-modal-detail button:hover,
.hhwt-modal-detail button:focus {
  outline: none;
}
#hhwt-modal-widget.modal {
  background-color: #fff;
  height: 150px;
  width: 360px;
  top: auto;
  right: 40px;
  left: auto;
  overflow: visible;
  bottom: 100px;
}
#hhwt-modal-widget.modal.right .modal-content {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
#hhwt-modal-widget.modal .modal-content {
  -webkit-transform: translate(125%, 0);
  -ms-transform: translate(125%, 0);
  -o-transform: translate(125%, 0);
  transform: translate(125%, 0);
}
.modal-close-mbl {
  display: none;
}

.vid_inner > iframe {
  height: 380px;
}

.no_pad {
  padding: 0px;
}

img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

.singlePost {
  background: #fff;
}
.singLogoCnt.disCover {
  bottom: 50%;
}
.singLogoCnt.disCover h1 {
  text-align: center;
}

.hhwt-fluid {
  position: relative;
}

.hhwt-facebook {
  display: none;
}

.footerResp {
  display: none;
}
.wonderplugin-engine {
  display: none;
}

#izooto-subscription-prompt .iz_prompt_containr {
  width: 375px !important;
  border-radius: 10px;
  padding-left: 10px !important;
  padding-right: 20px !important;
  right: 10px !important;
  bottom: 10px !important;
  padding-top: 27px !important;
  padding-bottom: 23px !important;
}
#izooto-subscription-prompt .iz_prompt_containr .izbranding {
  display: none !important;
}
#izooto-subscription-prompt .iz_text_container .iz_title {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  letter-spacing: 0px !important;
}
#izooto-subscription-prompt .iz_prompt_containr .iz_text_container {
  width: 76% !important;
}
#izooto-subscription-prompt .iz_text_container .iz_description {
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: 14px;
}
#izooto-subscription-prompt .iz_prompt_containr .iz_img_container {
  margin-top: 1px !important;
}
#izooto-subscription-prompt .izfadeInUp .iz_buttons_container {
  margin-left: 0px !important;
  margin-top: 20px !important;
  float: left;
}
#izooto-subscription-prompt .izfadeInUp .iz_allow_button {
  float: right !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  max-width: inherit !important;
  min-width: inherit !important;
  padding: 12px 28px !important;
}
#izooto-subscription-prompt .izfadeInUp .iz_block_button {
  font-size: 16px !important;
  font-weight: 600 !important;
  max-width: inherit !important;
  min-width: inherit !important;
  padding-right: 0px !important;
  float: right !important;
  margin-right: 20px !important;
}
#izooto-subscription-prompt .izfadeInUp .iz_allow_button:hover {
  background: #098481;
}
#izooto-subscription-prompt .izfadeInUp .iz_block_button:hover {
  color: #098481;
}
#izooto-subscription-prompt .iz_img_container img.iz_img {
  display: none;
}
#izooto-subscription-prompt .iz_prompt_containr .iz_img_container {
  background: url('../images/izooto_icon.png') no-repeat !important;
  width: 75px !important;
  height: 75px !important;
}

#hhwt-modal-ads .modal-dialog {
  width: 370px;
  margin-top: 14%;
}
#hhwt-modal-ads .close {
  position: absolute;
  font-size: 30px;
  right: 28px;
  top: 18px;
  z-index: 10;
  width: 90%;
  text-align: right;
  color: #ccc;
  display: none;
}
#hhwt-modal-ads .close:hover {
  color: #ccc;
}
#hhwt-modal-ads .modal-content {
  background: none;
  border: inherit;
  box-shadow: none;
}
/* .ivs-sdk-wrappercenter.ivs-sdk-wrappercore{height: 432px} */
.ivs-sdk-wrapper.ivs-floating-widget {
  height: 330px !important;
}
.ivs-sdk-wrappercore .ivs-content-player {
  position: static;
}
.ivs-overlay-carouselhorz.ivs-overlay-carouselcontainer {
  padding: 0 4em;
  max-width: 100% !important;
  width: 100% !important;
}
.ivs-sdk-wrappercore {
  max-height: 420px;
}
.ad_line {
  border-top: 1px solid #c5c2c2;
}

@media only screen and (max-width: 800px) {
  #izooto-subscription-prompt .iz_prompt_containr {
    width: 100% !important;
    border-radius: 0px;
    right: 0px !important;
    bottom: 0px !important;
    padding-bottom: 10px !important;
  }
}

@media only screen and (max-width: 767px) {
  #hhwt-modal-ads {
    display: none !important;
  }

  #hhwt-modal-widget.modal {
    display: block;
    top: 0px;
    height: 120px;
    z-index: 99;
    width: 100%;
    right: 0;
  }
  #hhwt-modal-widget .hhwt-modal-widget {
    height: 99px;
    top: 0;
    right: 0;
    width: 100%;
    border-bottom: 1px solid rgba(27, 26, 26, 0.17);
    box-shadow: none;
  }
  #hhwt-modal-widget .hhwt-modal-image {
    text-align: center;
    width: 90px;
    padding: 0px 15px 0px 30px;
  }
  #hhwt-modal-widget span:not(.modal-close-mbl) {
    display: none;
  }
  #hhwt-modal-widget .hhwt-modal-detail {
    padding: 0;
    width: 100%;
  }
  #hhwt-modal-widget.modal .modal-content {
    transform: none;
  }
  #hhwt-modal-widget .hhwt-modal-detail p {
    min-height: 57px;
    overflow: hidden;
  }
  .modal-close-mbl {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .hhwt-single-post iframe,
  .hhwt-single-post img {
    max-width: 100%;
  }
  .hhwt-menu-icons {
    padding: 2px 8px 3px 20px;
  }
}
@media only screen and (max-width: 400px) {
  #hhwt-modal-widget .hhwt-modal-detail {
    margin-left: 0px;
  }
  #hhwt-modal-widget .hhwt-modal-detail p {
    line-height: 19px;
  }
}

/*** Contact Form 7
/************************/
.wpcf7-form p label {
  font-size: 16px;
  color: #556767;
  font-family: 'ProximaNova-Semibold';
}
.wpcf7 input {
  width: 266px;
}
.wpcf7 select {
  width: 400px;
  margin: 5px 0;
  min-height: 45px;
  padding: 10px;
  border: 1px solid #e5e5e5;
}
div.wpcf7 {
  overflow: hidden;
  margin-top: 25px;
}
.wpcf7 input,
.wpcf7 textarea {
  color: #000;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0px;
  padding-left: 15px;
  margin-right: 10px;
  min-height: 45px;
  width: 400px;
  margin-top: 6px;
  margin-bottom: 6px;
}
.wpcf7 textarea {
  padding-top: 10px;
  height: 100px;
}
.wpcf7 input:focus,
.wpcf7 textarea:focus,
.wpcf7 select:focus {
  border-color: #66afe9;
  outline: none;
}
.wpcf7 .wpcf7-submit {
  width: 400px;
  height: 45px;
  font-size: 14px;
  color: #fff;
  background: #00cccb;
  border: none;
  font-weight: 600;
  text-transform: capitalize;
  padding: 11px 0 12px;
  letter-spacing: 1px;
  cursor: pointer;
  display: block;
}
.wpcf7 .wpcf7-submit:hover {
  background: #00cccb;
}
span.other-age {
  display: none;
}
div.wpcf7-mail-sent-ok {
  background-color: #398f14;
  color: #fff;
  margin: 0px 0px 24px;
  font-family: 'ProximaNova-Semibold';
  padding: 5px;
}
div.wpcf7-mail-sent-ok:before {
  content: '\2713';
  display: inline-block;
  padding: 0px 10px 0px 5px;
}
@media only screen and (max-width: 767px) {
  .wpcf7 input,
  .wpcf7 textarea,
  .wpcf7 select {
    width: 300px;
  }
  .wpcf7 .wpcf7-submit {
    width: 300px;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 400px) {
  .wpcf7 input,
  .wpcf7 textarea,
  .wpcf7 select {
    width: 100%;
  }
  .wpcf7 .wpcf7-submit {
    width: 100%;
  }
}

#comments ol,
#comments ul {
  padding-left: 0px;
}
#comments ol ul.children,
#comments ul ul.children {
  padding-left: 40px;
}
#comments li {
  list-style: none;
  padding: 0px;
}
#comments h4#commentator-post-count {
  color: #556666;
  font-size: 24px;
  font-family: 'ProximaNova-Semibold';
}
#comments .commentator-comment-body {
  margin-left: 60px;
}
#comments img {
  border-radius: 50%;
}
#comments .commentator-avatar .user {
  background: transparent;
}

#comments.commentator-area {
  padding: 50px 20px !important;
}

.modal-open {
  overflow-y: auto;
}

.hhwt-page-load-status {
  text-align: center;
}

.hhwt-page-load-status img {
  text-align: center;
  margin: 0px auto 20px;
}
.hhwt-page-load-status .infinite-scroll-last {
  color: #405050;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: 'ProximaNova-Semibold';
}

#go-top-button {
  position: fixed;
  z-index: 1000;
  color: #fff;
  background: rgba(122, 122, 122, 0.25);
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 30px;
  line-height: 45px;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
  overflow: auto;
  opacity: 1;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-backface-visibility: hidden;
  border-radius: 50%;
}
#go-top-button:hover {
  background: rgba(122, 122, 122, 0.5);
}

/* Destination newsletter popup type-1 */
body.bgshadow {
  background-color: rgba(0, 0, 0, 0.8);
}
.subscribe-popup .travel-guide-icon {
  background-repeat: no-repeat;
  padding: 2px 0px 3px 30px;
}

.subscribe-popup-cont {
  bottom: 0px;
  position: fixed;
  width: 44%;
  left: 29%;
  background-color: #ccc;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  background-size: 100% 200%;
  background-repeat: no-repeat;
  background-position: 0px -10px;
  background-blend-mode: multiply;
  z-index: 999;
}
.subscribe-popup-cont.active {
  background-size: cover;
  background-position: 0px 0px;
}

.subscribe-popup-cont > .subscribe-popup {
  margin: auto;
  float: none;
}
.subscribe-title {
  font-size: 26px;
  font-family: 'ProximaNova-Semibold';
  color: #fff;
}
.category-title {
  font-family: 'ProximaNova-Semibold';
  color: #fff;
  font-size: 15px;
  margin-bottom: 10px;
}

.subscribe-subtitle {
  font-family: 'ProximaNova-Semibold';
  color: #fff;
  font-size: 15px;
}

.expandbtncont {
  background-color: #3ecccb;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: auto;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.subscribe-popup-cont.active .expandedcont .closebtncont {
  color: #fff;
  background-color: #5c74a9;
  padding: 10px 12px;
  border-radius: 50%;
  font-weight: normal;
  font-size: 14px;
}
.expandbtncont .fa-chevron-up {
  font-size: 15px;
  margin-top: 15px;
}
.closebtncont {
  float: right;
  background-color: #ddd;
  text-align: center;
  vertical-align: middle;
  padding: 3px 5px;
  border-radius: 10px;
  cursor: pointer;
}
.subscribe-popup-cont.active {
  top: 15%;
  bottom: auto;
}
.subscribe-popup-cont.active .subscribe-title {
  margin-bottom: 20px;
}
.subscribe-popup-cont.active .subscribe-subtitle {
  margin-bottom: 15px;
  font-family: 'ProximaNova-Semibold';
  font-size: 14px;
}

.subscribe-popup-cont.active .expandbtncont {
  display: none;
}
.subscribe-popup-cont.active .subscribe-popup .closebtncont {
  display: none;
}

.subscribe-popup-cont.active .subscribe-popup {
  padding-top: 150px;
  clear: both;
}
.subscribe-popup-cont .expandedcont,
.subscribe-popup-cont .subscribe-subtitle,
.subscribe-popup-cont .formcontainer {
  display: none;
}
.subscribe-popup-cont.active .expandedcont,
.subscribe-popup-cont.active .subscribe-subtitle,
.subscribe-popup-cont.active .formcontainer {
  display: block;
}
.subscribebutton,
.subscribebutton:hover,
.subscribebutton:active,
.subscribebutton:focus {
  background-color: #0cc;
  padding: 5px 12px;
  color: #fff;
  box-shadow: none;
  outline: none;
  height: 45px;
  font-size: 15px;
  font-family: 'ProximaNova-Semibold';
}
.subscribedbutton,
.subscribedbutton:hover,
.subscribedbutton:active,
.subscribedbutton:focus,
.subscribedbutton:active:focus {
  background-color: #fff;
  padding: 5px 12px;
  color: #0cc;
  box-shadow: none;
  outline: none;
  height: 45px;
  font-size: 15px;
  font-family: 'ProximaNova-Semibold';
  border-radius: 25px;
}

.formcontainer {
  margin-top: 15px;
}
.formcontainer .form-control {
  border-radius: 25px;
  height: 45px;
}
.subscribebutton,
.subscribebutton {
  border-radius: 25px;
}
#hhwt-newsletter-subscribe .error,
#hhwt-modal-city-guide-light .error,
#hhwt-modal-city-guide-dark .error {
  color: red;
}

.marketplace-menu-icon {
  background-image: url('../images/menu-icons/book_grey.png');
}
.nav > li.hhwt-nav-menu-message,
.nav > li.shoppingCart,
.nav li.submenuDispNone {
  display: none;
}

.chatBottCnt {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 1000;
}
.viewChatBox {
  background: #00cccb;
  width: 400px;
  padding: 6px 15px 8px 15px;
  color: #fff;
  float: right;
  border-radius: 7px;
  font-size: 20px;
  line-height: 29px;
  position: relative;
}
.viewChatBox:hover,
.viewChatBox:focus {
  color: #fff;
}
.chatBottIframe {
  display: none;
  width: 400px;
  background: #fff;
}
.chatIcon {
  margin-right: 22px;
  line-height: 0px;
  margin-top: 6px;
}
.chatLogo {
  float: right;
}

.hhwt-travel-guide {
  padding: 0px;
  right: 0;
  position: fixed;
  left: 56%;
  width: 100%;
  bottom: 0px;
  z-index: 999;
  transform: translate(0%, 12%);
  -webkit-transform: translate(0%, 12%);
  -ms-transform: translate(0%, 12%);
  -o-transform: translate(0%, 12%);
}
.hhwt-widget-guide {
  padding: 0px;
  background-color: #f4a01c;
  width: 330px;
  height: 124px;
  margin-top: 26px;
  padding: 24px;
  border-radius: 4px;
}
.hhwt-widget-guide p {
  margin: 0px;
  color: #fff;
  font-family: 'ProximaNova-Bold';
  font-size: 13px;
  line-height: 26px;
  display: flex;
  padding-bottom: 5px;
}
.hhwt-travel-guide .hhwt-menu-icons {
  height: 22px !important;
  min-width: 22px;
  background-repeat: no-repeat;
  display: block;
  background-position: center;
  padding: 2px 10px 0px 0px;
}
.hhwt-widget-guide h3 {
  margin: 0px;
  color: #fff;
  font-family: 'ProximaNova-Bold';
  font-size: 17px;
  line-height: 21px;
  max-width: 265px;
}
.hhwt-widget-guide {
  cursor: pointer;
}
.hhwt-widget-image {
  width: 182px;
  height: 170px;
  padding: 0px;
  right: 40px;
  cursor: pointer;
}
.hhwt-widget-image img {
  position: absolute;
  border-radius: 50%;
  width: 182px;
}
.hhwt-widget {
  padding: 0px;
}
#hhwt-modal-city-guide-dark .modal-dialog .modal-content .modal-body {
  background: #576767 !important;
}

.stickyMenu {
  position: fixed;
  top: -200px;
  left: 0px;
  width: 100%;
  background: #fff;
  padding: 12px 10px 0px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.stickyMenu .hhwt-btns {
  display: block !important;
  float: right;
  opacity: 1 !important;
  margin-top: 5px !important;
  margin-bottom: 0px;
}
.stickyLogo {
  float: left;
  margin-top: 11px;
}
.mail_icon {
  display: inline-block;
  width: 100%;
  position: relative;
}
.copiedLinkText {
  position: absolute;
  bottom: -35px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #333333;
  border-radius: 8px;
  color: #fff;
  padding: 7px 10px;
  font-size: 12px;
  width: 90px;
  line-height: 19px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  left: -50px;
  text-align: center;
  display: none;
}
.stickyMenu .copiedLinkText {
  bottom: -58px;
}
.articlesThumbImg {
  width: 100%;
  height: 229px;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  bottom: 0;
  left: 0;
  background-position: center center;
  border-radius: 8px 8px 0 0;
}

.articlesBigThumbImg {
  width: 100%;
  height: 440px;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  bottom: 0;
  left: 0;
  background-position: center center;
  border-radius: 8px;
}

.hhwt-alert-subscribe {
  display: none;
  text-align: center;
}

.btn-nl-alert {
  background: #0cc;
  color: #fff;
  border-radius: 25px;
  padding: 9px;
  min-width: 150px;
  border: none;
  font-family: 'ProximaNova-Semibold';
  font-size: 15px;
}

.website_ads,
.mobile_ads {
  margin-top: 20px;
}
/* ===== Save articles ====== */
.hhwt-savearticles-dialog {
  margin: 110px auto;
}
.hhwt-savearticles-content {
  width: 63%;
  margin: auto;
}
.hhwt-savearticles-header {
  border: 0px;
}
.saveArtcleIcon {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.hhwt-savearticles-body {
  border-radius: 5px;
  padding: 0px 30px 15px;
  text-align: center;
}
.saveTxt {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #586867;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-top: 20px;
}
.selectTxt {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: #86908f;
  margin-top: 21px;
}
.saveArtBtn {
  display: inline-block;
  background: #f2007b;
  padding: 8px 19px;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  border-radius: 21px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.saveArtBtn:hover {
  background: #00cccb;
  text-decoration: none;
  color: #fff;
}
/* ===== Save articles ======== */
#closeCrossIcon {
  position: absolute;
  top: 26px;
  left: 5px;
  color: #fff;
  font-weight: bold;
  opacity: 1;
  z-index: 10;
}
.chatText {
  display: none;
  text-align: center;
  position: absolute;
  top: -3px;
  right: 10px;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
}

iframe#branch-banner-iframe {
  opacity: 0;
}
body.branch-banner-is-active {
  margin-top: 0px !important;
}

.container.article-content .video-js {
  background: #000;
}

.bann_overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.whiteBg {
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
}

.article-content p iframe,
.hhwt-page-content p iframe {
  display: block;
  border-radius: 8px;
  margin: 0 auto;
}
.hhwt-single-post .article-content p em,
.hhwt-page-content p em {
  margin-bottom: 10px;
  margin-top: 10px;
}
.hhwt-single-post .article-content img,
.hhwt-page-content img {
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 8px;
}

.destCls {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 72%;
}
.tagTitle {
  text-transform: capitalize;
}

.no_pad {
  padding: 0px;
}
.blue_bg {
  width: 22%;
  background: #00cccb;
  font-size: 16px;
  font-weight: bold;
  font-family: 'ProximaNova';
  letter-spacing: 1px;
  padding: 12px 0;
  border: 0px;
}
.blue_bg:hover {
  background: #00cccb;
  border: 0px;
}
.val_error_msg {
  color: #f00;
  font-size: 14px;
  font-weight: bold;
  margin-top: 3px;
}
.form-group.required .control-label:after {
  content: '*';
  color: red;
  font-size: 15px;
  margin-left: 3px;
}
.successMsg {
  font-size: 15px;
  color: #009fa2;
  font-weight: bold;
}

.hktb_img_logo {
  width: 100%;
}
.hktb_img_cnt {
  float: right;
  width: 300px;
  height: 48px;
  margin-top: 10px;
}

.singLogoCnt {
  position: absolute;
  bottom: 30%;
  width: 100%;
  left: 0px;
}
.no_pad {
  padding: 0px;
}
.singLogoCnt h1 {
  font-size: 48px;
  color: #fff;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  font-family: 'ProximaNova-Bold';
}
.singLogoCnt span {
  color: #fff;
  font-size: 32px;
  font-family: 'ProximaNova-Regular';
}
.videoSect {
  margin-top: 3px;
  border: 0px;
  overflow-x: hidden;
}
.videoSect .vid_inner {
  position: relative;
}
.vidPostTitle {
  position: absolute;
  font-family: 'ProximaNova-Bold';
  font-size: 32px;
  text-align: center;
  line-height: 36px;
  width: 100%;
  top: 26%;
  color: #fff;
  padding: 0 2%;
}
.videoSect .vid_inner:first-child {
  padding: 0px 2px 0px 0px;
}
.videoSect .vid_inner:last-child {
  padding: 0px 0px 0px 2px;
}
.videoSect .vid_inner video {
  vertical-align: top;
  height: 100%;
}
/* .sngpCnt{ background: #F5F6F8; } */
.sngpHead .content-title h4 {
  width: auto;
}
.sngpHead .content-title .btn-more {
  display: block;
}

#Step1 {
  display: block;
}
#Step2 {
  display: none;
}
.artMrgnBtm {
  margin-bottom: 30px;
}

.hhwt-create-new {
  background: rgb(245, 246, 248) none repeat scroll 0% 0%;
  color: rgb(181, 191, 192);
}

.save-bucket-article {
  display: inline-block !important;
}
.save-to-bucket {
  display: block !important;
}

.copyTxtArea {
  opacity: 0;
  height: 0px;
  width: 0px;
}

#featured-carousel .item .hhwt-home-btn-go {
  font-size: 13px;
  border-radius: 50%;
  padding: 21px 14px;
  color: #fff;
  background-color: #00cccb;
  border: 8px solid #1fa8af;
  margin-right: 10px;
  font-family: 'ProximaNova-Semibold';
  outline: none;
  position: absolute;
  right: 190px;
  top: 42%;
  display: none;
}
#featured-carousel .item.active .hhwt-home-btn-go {
  display: block;
}

#featured-carousel .featured-fluid .featured-title {
  position: relative;
}

.whiteBg.pageCnt {
  padding: 23px 0px;
}
.hhwt-title-content {
  padding: 0px;
}

.hidden-xs .hhwt-nav-pills .fa-chevron-down {
  display: none;
}

.hhwt-page-fluid .hhwt-page-content {
  display: inline-block;
}

#branch-banner-iframe {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  width: 1px;
  min-width: 100%;
  left: 0;
  right: 0;
  border: 0;
  height: 70px;
  z-index: 99999;
  -webkit-transition: all 0.25s ease;
  transition: all 00.25s ease;
  position: relative;
  overflow: hidden;
  top: 0px;
  display: none !important;
}

#branch-banner {
  width: 100%;
  z-index: 99999;
  font-family: Helvetica Neue, Sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-transition: all 0.25s ease;
  transition: all 00.25s ease;
  position: absolute;
  font-family: 'Open Sans', sans-serif;
}

#branch-banner .content {
  width: 100%;
  overflow: hidden;
  height: 70px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding: 16px 20px 16px 12px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
}
#branch-banner * {
  margin-right: 4px;
  position: relative;
  line-height: 1.2em;
}

#branch-banner .content .right {
  float: right;
  height: auto;
  margin-bottom: 0;
  padding-top: 0;
  z-index: 1;
  margin-right: 0px;
}

#branch-banner .content .left {
  height: auto;
  padding: 0;
  width: 80%;
  float: left;
  margin-right: 0px;
}

#branch-banner .content {
  color: #333;
}

#branch-banner-close {
  font-weight: 400;
  cursor: pointer;
  float: left;
  z-index: 2;
  padding: 0 5px 0 5px;
  margin-right: 0;
  color: #000;
  font-size: 24px;
  top: 0;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  margin-top: 5px;
}

#branch-banner .icon {
  float: left;
  padding-bottom: 0px;
  margin-right: 10px;
  margin-left: 5px;
}
#branch-banner .vertically-align-middle {
  top: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  float: left;
  margin-top: 18px;
  width: 69%;
  margin-right: 0px;
}
#branch-banner .content .left .details .title {
  font-size: 14px;
  line-height: 19px;
}
#branch-banner .details > * {
  display: block;
}
#branch-banner .title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
#branch-banner .reviews {
  font-size: 13px;
  margin: 1px 0 3px 0;
  color: #777;
}

#branch-banner .content .left .details .description {
  font-size: 11px;
  font-weight: normal;
  line-height: 15px;
}
#branch-banner .description {
  font-size: 12px;
  font-weight: normal;
  color: #333;
  max-height: 30px;
  overflow: hidden;
}
#branch-banner .reviews .star {
  display: inline-block;
  position: relative;
  margin-right: 0;
}
#branch-banner .reviews .star span {
  display: inline-block;
  margin-right: 0;
  color: #555;
  position: absolute;
  top: 0;
  left: 0;
}
#branch-banner .reviews .review-count {
  font-size: 10px;
}

#branch-banner .icon img {
  width: 40px;
  height: 40px;
  margin-right: 0;
}
#branch-banner .right > div {
  float: right;
  margin-right: 0px;
}
#branch-banner .button {
  background: #00cccc;
  color: #fff;
  cursor: pointer;
  margin-top: 0px;
  font-size: 14px;
  display: inline-block;
  margin-left: 0px;
  font-weight: 400;
  text-decoration: none;
  border-radius: 6px;
  padding: 8px;
  transition: all 0.2s ease;
  font-weight: bold;
  margin-right: 0px;
  line-height: 20px;
  margin-top: 1px;
}
#branch-banner .button:hover {
  background: #fff;
  color: #00cccc;
  border: 1px solid #d8d8d8;
}
#branch-mobile-action {
  white-space: nowrap;
}

.plan_trip {
  text-align: center;
  width: 100%;
  float: left;
  position: fixed;
  bottom: 0px;
  left: 0px;
  color: #fff;
  padding: 10px 0px;
  background: #00cccb;
  z-index: 10;
  display: none;
}
.mobile_app_link,
.save_bucket_popup {
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: none;
  z-index: 10;
  width: 100%;
  text-align: center;
  display: none;
}
.save_bucket_popup {
  bottom: 16%;
}
.mobile_app_innr {
  position: relative;
  display: inline-block;
}

.beta_site_img {
  position: fixed;
  bottom: 0px;
  right: 15%;
  left: inherit;
  display: block;
  z-index: 10;
  width: 70%;
  text-align: right;
}
.close_beta {
  position: absolute;
  right: 7px;
  top: 6px;
  cursor: pointer;
}

.mbdv_hide {
  display: none;
}

@media (min-width: 1800px) {
  #featured-carousel .item .hhwt-home-btn-go {
    right: 27%;
  }
}

@media (min-width: 1600px) and (max-width: 1799px) {
  #featured-carousel .item .hhwt-home-btn-go {
    right: 23%;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  #featured-carousel .item .hhwt-home-btn-go {
    right: 19%;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  #featured-carousel .item .hhwt-home-btn-go {
    right: 15%;
  }
}

@media only screen and (max-width: 1024px) {
  .vidPostTitle {
    font-size: 28px;
    line-height: 32px;
    top: 16%;
  }
}

@media only screen and (max-width: 900px) {
  .vidPostTitle {
    font-size: 22px;
    line-height: 26px;
    top: 16%;
  }
}

@media only screen and (max-width: 767px) {
  .videoSect .vid_inner {
    padding: 0px !important;
    margin-bottom: 5px;
  }
  .singLogoCnt {
    text-align: center;
    bottom: 10%;
  }
  .singLogoCnt h1 {
    font-size: 28px;
  }
  .singLogoCnt span {
    font-size: 24px;
  }
  .vidPostTitle {
    font-size: 32px;
    line-height: 36px;
    top: 26%;
  }

  .plan_trip {
    display: block;
  }
  /* .stickyMenu{ bottom: 40px !important; } */
  .close_mobile_app,
  .save_bucket_open {
    position: absolute;
    right: 11px;
    top: 11px;
    cursor: pointer;
  }
  .close_mobile_app:hover,
  .save_bucket_open:hover,
  .close_beta:hover {
    opacity: 0.8;
  }

  .hhwt_lrg_srch .search.input-group {
    margin-top: 0px !important;
    height: auto !important;
    border: 0px !important;
  }
  .hhwt_lrg_srch {
    height: 58px;
    padding-top: 7px;
  }
  .hhwt_lrg_srch .input-group-addon.trg-submit {
    padding: 0px 10px 0px 22px !important;
    border-radius: 0px;
  }
  .hhwt_lrg_srch .input-close {
    margin-top: 7px;
    margin-right: 26px;
  }
  .hhwt_lrg_srch .form-control {
    padding: 23px 10px;
    font-size: 16px;
  }
  .srch_results {
    box-shadow: 0 0px 3px 0px rgba(0, 0, 0, 0.2);
    margin-top: 2px;
  }
  .srchRow {
    padding: 0px 24px;
    margin-top: 10px;
  }
  .srchTitle {
    margin-left: 15px;
    width: 78%;
    font-size: 14px;
    line-height: 19px;
  }
  .srch_all_results {
    padding: 15px 24px 8px;
  }
  .srch_all_results a {
    padding: 0px;
  }
  #go-top-button {
    bottom: 5px;
    right: 5px;
  }
}
@media only screen and (max-width: 480px) {
  .vidPostTitle {
    font-size: 24px;
    line-height: 28px;
    top: 16%;
  }
  .footerResp {
    display: block;
  }
}
@media only screen and (max-width: 400px) {
  .singLogoCnt h1 {
    font-size: 22px;
  }
  .singLogoCnt span {
    font-size: 18px;
  }
  .vidPostTitle {
    font-size: 20px;
    line-height: 24px;
    top: 16%;
  }
}

@media (max-width: 767px) {
  .ivs-sdk-wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
  .ivs-sdk-wrappercenter.ivs-sdk-wrappercore {
    height: 275px;
  }
  .ivs-sdk-wrappersection.ivs-sdk-wrapperbottom {
    width: 300px !important;
  }
  .subscribe-popup-cont {
    bottom: 0px;
    position: fixed;
    width: 100%;
    background-color: #ccc;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 0px;
    left: 0%;
  }

  .viewChatBox {
    width: 44px;
    height: 44px;
    padding: 6px 6px 9px 10px;
    border-radius: 50%;
    float: right;
  }
  .viewChatBox:focus {
    outline: none;
    border: 0px;
  }
  .chatBottIframe {
    width: 350px;
  }

  #subscribedmsg {
    clear: both;
    padding-top: 25px;
  }
  .subscribe-title {
    font-size: 26px;
  }
  .subscribe-popup-cont.active {
    text-align: center;
    min-height: 500px;
  }
  .subscribe-popup-cont.active .subscribemobileview {
    width: 100%;
  }
  .category-title {
    margin-bottom: 10px;
  }

  .hhwt-btns {
    display: none;
  }
  .stickyMenu {
    bottom: 0px;
    top: inherit !important;
    border-top: 0px solid #d9d6d8;
    border-bottom: 0px;
    text-align: left;
    padding: 3px 5px;
    z-index: 10;
  }
  .stickyLogo {
    display: none;
  }
  .stickyMenu .hhwt-btns {
    display: inline-block !important;
    float: inherit;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .single-post #footer {
    padding-bottom: 58px;
  }
  .copiedLinkText {
    top: -28px;
    height: 25px;
    width: 90px;
    left: -43px;
  }
  .viewChatBox .chatIcon {
    display: block;
    float: right;
    margin-right: 0px;
    margin-top: 13px;
  }
  .viewChatBox > span {
    display: none;
  }
  .chatBottCnt {
    bottom: 4px;
  }
  .chatIcon img {
    width: 84%;
  }
  .chatText {
    display: block;
  }
  .stickyMenu .hhwt-btns button,
  .stickyMenu .mail_icon {
    margin-right: 10px !important;
    margin-left: 0px !important;
  }
}
/* Safari 10.1+ */

/* @media not all and (min-resolution:.001dpcm) { @media { */

/* .safari_only {  */

/* .ivs-sdk-wrappercenter.ivs-sdk-wrappercore{height: 275px} */
/* .ivs-sdk-wrappersection.ivs-sdk-wrapperbottom{ width: 300px !important;}  */

/* } */
/* }} */
.ivs-overlay-carouselhorz.ivs-overlay-carouselcontainer {
  padding: 0 4em;
  max-width: 100% !important;
  width: 100% !important;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .hhwt-widget-image img {
    max-width: 124px;
    height: 140px;
  }
  .hhwt-widget {
    width: 100%;
    padding: 0px;
  }
  .hhwt-widget-guide {
    width: calc(100% - 77px);
    margin-top: 20px;
    height: 100px;
    padding: 10px 15px;
  }
  .hhwt-widget-image {
    width: 124px;
    height: 124px;
  }
  .hhwt-widget-image img {
    position: relative;
    left: 0px;
  }
  .hhwt-travel-guide {
    left: 0%;
  }
  .hhwt-widget-guide h3 {
    line-height: 21px;
    padding-right: 20px;
    max-width: 100%;
  }
  .hhwt-widget-image {
    right: 7%;
    margin-right: -50px;
  }
}
@media only screen and (max-width: 390px) {
  .hhwt-widget-guide h3 {
    font-size: 15px;
    line-height: 16px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hhwt-widget-image img {
    max-width: 145px;
    height: 180px;
  }
  .hhwt-widget-guide {
    width: 70%;
  }
  .hhwt-widget-image {
    width: 30%;
  }
}

@media only screen and (max-width: 580px) {
  a.articlesThumbImg {
    height: 157px;
  }
  a.articlesBigThumbImg {
    height: 314px;
  }

  .hktb_img_cnt {
    width: 200px;
    height: auto;
    margin-top: 13px;
    margin-right: 15px;
  }
}

@media only screen and (max-width: 320px) {
  .website_ads {
    margin-left: -15px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
  .hhwt-savearticles-content {
    width: 84%;
  }
  .hhwt-savearticles-dialog {
    margin: 40px auto;
  }
}

@media only screen and (max-width: 479px) {
  .hhwt-menu-icons {
    display: none;
  }
  .vid_inner > iframe {
    height: 220px;
  }
}

.hhwt-post {
  height: 440px;
  display: flex;
  flex-direction: column;
}
.hhwt-post .articlesThumbImg {
  flex-shrink: 0;
  overflow: hidden;
}
.hhwt-post .article-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.hhwt-post .article-content a {
  color: unset;
}
.hhwt-post .article-content a:hover {
  text-decoration: none;
}
.hhwt-post .article-content .article_title {
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;

  color: #333333;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin: 0;
}
.hhwt-post .article-content .authrDet {
  margin-top: auto;
}
.hhwt-post .article-content .authrDet .article-author {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
}
.hhwt-post .article-content .authrDet .article-author:hover {
  color: #0cc;
}
.hhwt-post .article-content .category {
  color: #0cc;
}
